import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Resources, EventMinimal } from '../models/box-models';
import { Blueprint, Mount, Recruit } from '../models/item-models';
import { MapActionRedirect } from '../models/redirect-models';
import { User } from '../models/single/user';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private _broadcastSource = new Subject<string>();
  _broadcastSource$ = this._broadcastSource.asObservable();
  private _routeMessageSource = new Subject<string>();
  _routeMessageSource$ = this._routeMessageSource.asObservable();

  constructor() { }

  sendBroadcast(broadcast: string) {
    this._broadcastSource.next(broadcast);
  }

  //sendRouteMessage(route: string) {
  //  this._routeMessageSource.next(route);
  //}

  //==== VILLAGE ====
  selectedVillageId: number = -1;
  setSelectedVillageId(id: number) {
      this.selectedVillageId = id;
  }
  getSelectedVillageId() {
    return this.selectedVillageId;
  }
  clearSelectedVillageId() {
    this.selectedVillageId = -1;
  }

  //==== TICK ====
  tick: number = -1;
  setTick(tick: number) {
    this.tick = tick;
  }
  getTick() {
    return this.tick;
  }

   //==== STATIC DATA VERSION ====
  staticDataVersion: string = "";
  setStaticDataVersion(version: string) {
    this.staticDataVersion = version;
  }
  getStaticDataVersion() {
    return this.staticDataVersion;
  }
  clearStaticDataVersion() {
    this.staticDataVersion = "";
  }

  //==== RESOURCES ====
  resourceData: Resources| null = null;
  setResourceData(resourceData: Resources) {
    this.resourceData = resourceData;
  }
  getResourceData() {
    return this.resourceData;
  }
  clearResourceData() {
    this.resourceData = null;
  }

  //==== EVENTS ====
  eventData: EventMinimal[] | null = null;
  setEventData(eventData: EventMinimal[]) {
    this.eventData = eventData;
  }
  getEventData() {
    return this.eventData;
  }
  clearEventData() {
    this.eventData = [];
  }

  //======== REDIRECT DATA ========

  mapActionRedirect: MapActionRedirect | null = null;
  setMapActionRedirect(mapActionRedirect: MapActionRedirect) {
    this.mapActionRedirect = mapActionRedirect;
  }
  getMapActionRedirect() {
    return this.mapActionRedirect;
  }
  clearMapActionRedirect() {
    this.mapActionRedirect = null;
  }


  //======== STATIC DATA ========
  blueprints: Blueprint[] = [];
  setBlueprints(bps: Blueprint[]) {
    this.blueprints = bps;
  }
  getBlueprints() {
    return this.blueprints;
  }
  clearBlueprints() {
    this.blueprints = [];
  }

  recruits: Recruit[] = [];
  setRecruits(rec: Recruit[]) {
    this.recruits = rec;
  }
  getRecruits() {
    return this.recruits;
  }
  clearRecruits() {
    this.recruits = [];
  }

  mounts: Mount[] = [];
  setMounts(mounts: Mount[]) {
    this.mounts = mounts;
  }
  getMounts() {
    return this.mounts;
  }
  clearMounts() {
    this.mounts = [];
  }
}
