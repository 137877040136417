import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SettingsComponent } from './components/settings/settings.component';
import { RegComponent } from './components/reg/reg.component';
import { MainComponent } from './components/main/main.component';
import { HomeComponent } from './components/home/home.component';
import { StartComponent } from './components/game/start/start.component';
import { MapComponent } from './components/game/map/map.component';
import { SoonComponent } from './components/game/soon/soon.component';
import { VillageComponent } from './components/game/village/village.component';
import { ScienceComponent } from './components/game/science/science.component';
import { TechComponent } from './components/game/tech/tech.component';
import { VillageAdministrationComponent } from './components/game/village-administration/village-administration.component';
import { InventoryComponent } from './components/game/inventory/inventory.component';
import { DesignComponent } from './components/game/design/design.component';
import { BarracksComponent } from './components/game/barracks/barracks.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent
  },
  {
    path: 'main',
    component: MainComponent
  },
  {
    path: 'reg',
    component: RegComponent
  },
  {
    path: 'settings',
    component: SettingsComponent
  },
  {
    path: 'game/start',
    component: StartComponent
  },
  {
    path: 'game/map',
    component: MapComponent
  },
  {
    path: 'game/inventory',
    component: InventoryComponent
  },
  {
    path: 'game/village',
    component: VillageComponent
  },
  {
    path: 'game/villageAdministration',
    component: VillageAdministrationComponent
  },
  {
    path: 'game/science',
    component: ScienceComponent
  },
  {
    path: 'game/tech',
    component: TechComponent
  },
  {
    path: 'game/barracks',
    component: BarracksComponent
  },
  {
    path: 'game/design',
    component: DesignComponent
  },
  {
    path: 'game/soon',
    component: SoonComponent
  },
  {
    path: 'home',
    component: HomeComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
