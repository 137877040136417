import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Startup_Result } from '../../models/single/startup_Result';
import { Startup_JoinRequest } from '../../models/single/startup_JoinRequest';
import { Startup_Select } from '../../models/single/startup_Select';
import { VillageMinimal, EventMinimal, MessageCount, CurrentTick, Resources, Message, Report } from '../../models/box-models';
import { BuildingType, BuildingTypeLevel, MapFieldType, ScienceType, ScienceTypeLevel, Nation, TechType, VillageLevelType, ItemType } from '../../models/staticData-models';

@Injectable({
  providedIn: 'root'
})
export class GameBasicService {

  baseApiUrl: string = environment.API_URL;
  constructor(private http: HttpClient) { }

  //get tick

  //====== START-Component ======
  public getPlayer(): Observable<Startup_Result> {
    return this.http.get<Startup_Result>(this.baseApiUrl + 'Startup/getPlayerDetails');
  }

  public getSelectData(): Observable<Startup_Select> {
    return this.http.get<Startup_Select>(this.baseApiUrl + 'Startup/getSelectData');
  }

  public joinServer(startup_JoinRequest: Startup_JoinRequest): Observable<string> {
    return this.http.post(this.baseApiUrl + 'Startup/joinServer', startup_JoinRequest, { responseType: 'text' });
  }

  //====== BOX-Controller ======
  //get TickData
  public getCurrentTick(): Observable<CurrentTick> {
    return this.http.get<CurrentTick>(this.baseApiUrl + 'Basic/getCurrentTick');
  }

  //get Villages
  public getVillagesMinimal(): Observable<VillageMinimal[]> {
    return this.http.get<VillageMinimal[]>(this.baseApiUrl + 'Basic/getVillages');
  }

  //get EventData
  public getEventsNewestId(villageId: number): Observable<number> {
    return this.http.get<number>(this.baseApiUrl + 'Basic/getEventNewestId?villageId=' + villageId);
  }
  public getEvents(villageId: number): Observable<EventMinimal[]> {
    return this.http.get<EventMinimal[]>(this.baseApiUrl + 'Basic/getEvents?villageId=' + villageId);
  }

  //get Resources
  public getResources(villageId: number, runCalc: boolean): Observable<Resources> {
    return this.http.get<Resources>(this.baseApiUrl + 'Basic/getResources?villageId=' + villageId + "&runCalc=" + runCalc);
  }

  //get MenuFlags
  public getMenuFlags(villageId: number): Observable<number> {
    return this.http.get<number>(this.baseApiUrl + 'Basic/getMenuFlags?villageId=' + villageId);
  }

  //get Messages
  public getMessageCount(): Observable<MessageCount> {
    return this.http.get<MessageCount>(this.baseApiUrl + 'Basic/getMessageCount');
  }
  public getMessages(): Observable<Message[]> {
    return this.http.get<Message[]>(this.baseApiUrl + 'Message/getMessages');
  }

  public getMessage(messageId: number): Observable<Message> {
    return this.http.get<Message>(this.baseApiUrl + 'Message/getMessage?messageId=' + messageId);
  }

  public getReports(): Observable<Report[]> {
    return this.http.get<Report[]>(this.baseApiUrl + 'Message/getReports');
  }

  public getReport(reportId: number): Observable<Report> {
    return this.http.get<Report>(this.baseApiUrl + 'Message/getReport?reportId=' + reportId);
  }

  public sendMessage(message: Message): Observable<Message[]> {
    return this.http.post<Message[]>(this.baseApiUrl + 'Message/SendMessage', message);
  }

  public deleteMessage(messageId: number): Observable<string> {
    return this.http.get(this.baseApiUrl + 'Message/deleteMessage?messageId=' + messageId, { responseType: 'text' });
  }
  public deleteReport(reportId: number): Observable<string> {
    return this.http.get(this.baseApiUrl + 'Message/deleteReport?reportId=' + reportId, { responseType: 'text' });
  }

  //=======================================================
  //===================== STATIC DATA =====================
  //=======================================================

  public getStaticDataVersion(): Observable<string> {
    return this.http.get(this.baseApiUrl + 'StaticData/getStaticDataVersion',
      { responseType: 'text' });//because no JSON object is returned... just a string
  }
  public getBuildings(): Observable<BuildingType[]> {
    return this.http.get<BuildingType[]>(this.baseApiUrl + 'StaticData/getBuildingStaticData');
  }
  public getBuildingLevels(): Observable<BuildingTypeLevel[]> {
    return this.http.get<BuildingTypeLevel[]>(this.baseApiUrl + 'StaticData/getBuildingLevelsStaticData');
  }
  public getMapFields(): Observable<MapFieldType[]> {
    return this.http.get<MapFieldType[]>(this.baseApiUrl + 'StaticData/getMapStaticData');
  }
  public getNations(): Observable<Nation[]> {
    return this.http.get<Nation[]>(this.baseApiUrl + 'StaticData/getNationStaticData');
  }
  public getSciences(): Observable<ScienceType[]> {
    return this.http.get<ScienceType[]>(this.baseApiUrl + 'StaticData/getScienceStaticData');
  }
  public getScienceLevels(): Observable<ScienceTypeLevel[]> {
    return this.http.get<ScienceTypeLevel[]>(this.baseApiUrl + 'StaticData/getScienceLevelsStaticData');
  }
  public getTechs(): Observable<TechType[]> {
    return this.http.get<TechType[]>(this.baseApiUrl + 'StaticData/getTechsStaticData');
  }
  public getVillageLevels(): Observable<VillageLevelType[]> {
    return this.http.get<VillageLevelType[]>(this.baseApiUrl + 'StaticData/getVillageLevelStaticData');
  }
  public getItems(): Observable<ItemType[]> {
    return this.http.get<ItemType[]>(this.baseApiUrl + 'StaticData/getItemsStaticData');
  }

  //=======================================================
  //=======================================================
  //=======================================================
}
