import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { VillageAdministrationData, VillageResult } from '../../models/building-models';

@Injectable({
  providedIn: 'root'
})
export class BuildingService {

  baseApiUrl: string = environment.API_URL;
  constructor(private http: HttpClient) { }

  public getVillageData(villageId: number): Observable<VillageResult> {
    return this.http.get<VillageResult>(this.baseApiUrl + 'Village/getVillageBuildingData?villageId=' + villageId);
  }

  public constructBuilding(villageId: number, slotId: number, buildingLevelId: number): Observable<string> {
    var request = { villageId: villageId, slotId: slotId, buildingLevelId: buildingLevelId }
    return this.http.post(this.baseApiUrl + 'Village/constructBuilding', request, { responseType: 'text' });
  }

  public claimMapField(mapFieldId: number, villageId: number): Observable<string> {
    var request = { mapFieldId: mapFieldId, villageId: villageId}
    return this.http.post(this.baseApiUrl + 'Village/claimMapField', request, { responseType: 'text' });
  }

  public getAdministrationData(villageId: number): Observable<VillageAdministrationData> {
    return this.http.get<VillageAdministrationData>(this.baseApiUrl + 'Village/getVillageAdministrationData?villageId=' + villageId);
  }

  public increaseVillageLevel(villageId: number, levelId: number | null): Observable<string> {
    return this.http.post<string>(this.baseApiUrl + 'Village/increaseVillageLevel?villageId=' + villageId + "&levelId=" + levelId, { responseType: 'text' });
  }
}
