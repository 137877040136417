import { Component, OnInit } from '@angular/core';
import { SpinnerService } from '../../../services/spinner.service';
import { ToastService } from '../../../services/toast.service';
import { DataService } from '../../../services/data.service';
import { TranslationService } from '../../../services/translation.service';
import { Router } from '@angular/router';
import { TechsRank, VillageTechResponce } from '../../../models/science-models';
import { TechType } from '../../../models/staticData-models';
import { ScienceService } from '../../../services/data/science.service';
import { Resources } from '../../../models/box-models';

@Component({
  selector: 'app-tech',
  templateUrl: './tech.component.html',
  styleUrls: ['./tech.component.css']
})
export class TechComponent implements OnInit {
  techTypes: TechType[] = [];
  villageTechResponce: VillageTechResponce | null = null;
  techStructure: TechsRank[] = [];
  woodIcon = "&#129683;";
  stoneIcon = "&#9935;";
  ironIcon = "&#128296;";
  foodIcon = "&#127859;";
  villageId: number | null = null;
  resData: Resources | null = null;

  constructor(private toastService: ToastService, private scienceService: ScienceService, private spinnerService: SpinnerService,
    private translationService: TranslationService, private dataService: DataService, private router: Router) {

  }

  ngOnInit() {
    this.dataService._broadcastSource$.subscribe(broadcast => {
      if (broadcast == "CHANGED-VILLAGE") {
        this.getTechData(false)
      }
    });
    this.getStaticData(0);
  }

  returnToStart(reason: string) {
    this.router.navigate(['game/start']);
  }

  buildArray() {
    //find depth
    var depth = 0;
    for (var i = 0; i < this.techTypes.length; i++)
      if (this.techTypes[i].rank > depth)
        depth = this.techTypes[i].rank;

    //build empty array
    this.techStructure = [];
    for (i = 0; i < depth; i++) {
      var active = false;
      if (this.villageTechResponce != null && this.villageTechResponce.workshopLevel != null && i < this.villageTechResponce.workshopLevel)
        active = true;
      this.techStructure.push({ rank: i + 1, ownedTechs: 0, isFull: false, techs: [], isActive: active, isOpen: active });
    }

    for (i = 0; i < this.techTypes.length; i++) {
      var ownedByPlayer = false;
      //find in player techs
      if (this.villageTechResponce) {
        var villageTech = this.villageTechResponce.villageTechs.find(vt => vt.techTypeId === this.techTypes[i].id);
        if (villageTech)
          ownedByPlayer = true;
      }

      this.techStructure[this.techTypes[i].rank - 1].techs.push({ owned: ownedByPlayer, tech: this.techTypes[i] });
      if (ownedByPlayer) {
        this.techStructure[this.techTypes[i].rank - 1].ownedTechs!++;
        if (this.villageTechResponce != null && this.villageTechResponce.techsPerRank != null && this.techStructure[this.techTypes[i].rank - 1].ownedTechs! >= this.villageTechResponce.techsPerRank) {
          this.techStructure[this.techTypes[i].rank - 1].isFull = true;
          this.techStructure[this.techTypes[i].rank - 1].isOpen = false;
        }
      }
    }

    this.resData = this.dataService.getResourceData();
  }

  getTranslation(name: string, type: string): string {
    var result = "";
    if (type == 'name') {
      result = this.translationService.getTechName(name);
    }
    else if (type == 'description') {
      result = this.translationService.getTechDescription(name);
    }
    else if (type == 'effectName') {
      result = this.translationService.getEffectName(name);
    }
    return result;
  }

  openRank(techRank: TechsRank) {
    techRank.isOpen = !techRank.isOpen;
    if (techRank.isOpen)
      this.resData = this.dataService.getResourceData();
  }


  //====== GET DATA ======
  getStaticData(counter: number) {
    var version = this.dataService.getStaticDataVersion();
    if (!version || version.length == 0) {
      if (counter < 10) {
        counter++;
        setTimeout(() => {
          this.getStaticData(counter);
        }, 200);
      }
      else
        this.returnToStart("no staticData");
    }

    //clear data
    this.techTypes = [];

    var tmpString = localStorage.getItem(version + '-techs');
    if (tmpString != null)
      this.techTypes = JSON.parse(tmpString);

    if (version || version.length != 0)
      this.getTechData(true);
  }



  getTechData(onInit: boolean) {
    this.villageId = this.dataService.getSelectedVillageId();
    if (!this.villageId || this.villageId == -1) {
      if (onInit)
        return;
      else
        this.returnToStart("no villageId");
    }
    this.villageTechResponce = null;
    this.spinnerService.addToLoading();
    this.scienceService.getTechData(this.villageId!).subscribe((result: VillageTechResponce) => {

      this.villageTechResponce = result;
      this.buildArray();

      this.spinnerService.removeFromLoading();
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.spinnerService.removeFromLoading();
    });
  }

  buyTech(techId: number) {
    if (!this.villageId)
      return;

    this.spinnerService.addToLoading();

    this.scienceService.buyTech(this.villageId, techId).subscribe((result: string) => {
      this.toastService.showSuccessToast($localize`COMMON_SUCCESS`, $localize`BUY_TECH_SUCCESS`);

      this.dataService.sendBroadcast('LOAD-RESOURCES');
      this.getTechData(false);

      this.spinnerService.removeFromLoading();
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.spinnerService.removeFromLoading();
    });
  }
}
