<p i18n>REGISTRATION</p>
<br />

<!--TODO resend activation mail-->
<!--TODO Language on registration-->

<ul class="nav nav-tabs" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <button class="nav-link active" id="register-tab" data-bs-toggle="tab" data-bs-target="#register-tab-pane" type="button" role="tab" aria-controls="register-tab-pane" aria-selected="true" i18n>REGISTER</button>
  </li>
  <li class="nav-item" role="presentation">
    <button class="nav-link" id="activate-tab" data-bs-toggle="tab" data-bs-target="#activate-tab-pane" type="button" role="tab" aria-controls="activate-tab-pane" aria-selected="false" i18n>ACTIVATE</button>
  </li>
</ul>
<div class="tab-content" id="myTabContent">
  <div class="tab-pane fade show active " id="register-tab-pane" role="tabpanel" aria-labelledby="register-tab" tabindex="0">
    <div class="row">
      <div class="col-lg-6">
        <p style="margin: 8px 0 4px 0" i18n>NAME</p>
        <input [(ngModel)]="user.username" placeholder="Name" />
        <p style="margin: 8px 0 4px 0" i18n>EMAIL</p>
        <input [(ngModel)]="user.email" placeholder="Email" />
        <p style="margin: 8px 0 4px 0" i18n>PASSWORD</p>
        <input [(ngModel)]="user.password" placeholder="Password" type="password" />
        <p style="margin: 8px 0 4px 0" i18n>PASSWORDCONFIRM</p>
        <input [(ngModel)]="passwordConfirm" placeholder="Password Confirm" type="password" />
      </div>
      <div class="col-lg-6">
        {{regMessage}}
      </div>
    </div>
    <div class="row" style="padding: 12px 0">
      <div class="col-sm-12">
        <button class="btn btn-primary" (click)="register(user)" i18n>REGISTER</button>
      </div>
    </div>
  </div>
  <div class="tab-pane fade" id="activate-tab-pane" role="tabpanel" aria-labelledby="activate-tab" tabindex="1">
    <div>
      <p style="margin: 8px 0 4px 0" i18n>EMAIL</p>
      <input [(ngModel)]="user.email" placeholder="Email" />
    </div>
    <div class="row" style="padding: 12px 0">
      <div class="col-sm-12">
        <button class="btn btn-primary" (click)="activationMail(user)" i18n>ACTIVATION</button>
      </div>
    </div>
  </div>
</div>

