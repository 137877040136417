import { Component, OnInit } from '@angular/core';
import { SpinnerService } from '../../../services/spinner.service';
import { ToastService } from '../../../services/toast.service';
import { DataService } from '../../../services/data.service';
import { GameBasicService } from '../../../services/data/game-basic.service';
import { Message, MessageCount, Report, ReportDetailsCombatTurn, ReportDetailsCombatUnit, ReportFrontEnd } from '../../../models/box-models';
import { environment } from '../../../../environments/environment';
import { IconService } from '../../../services/icon.service';
import { TranslationService } from '../../../services/translation.service';

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.css']
})
export class MessageBoxComponent implements OnInit {
  messagesIcon: string = "&#128488;";
  messagesNewIcon: string = "&#128172;";
  reportsIcon: string = "&#128195;";
  reportsNewIcon: string = "&#9993;";
  deleteIcon: string = "&#10060;";
  replyIcon: string = "&#129186;";
  messagesCount: number = 0;
  messagesCountNew: number = 0;
  reportCount: number = 0;
  reportCountNew: number = 0;


  tickCount: number = 0;
  currentTick: number = 0;
  tickLength: number = environment.tickLength;
  reloadTicks: number = environment.reloadTicks;
  reloadDelay: number = environment.reloadDelay;
  delayTime: number = 0;

  messagesIn: Message[] = [];
  messagesOut: Message[] = [];
  messageSelected: Message | null = null;
  reports: Report[] = [];
  reportSelected: Report | null = null;
  messageNew: Message | null = null;
  messagesModalIsOpen: boolean = false;
  reportsModalIsOpen: boolean = false;
  selectedTab: number = 0;

  battleField: number[][] = [];
  selectedCombatTurn: ReportDetailsCombatTurn | null = null;
  selectedCombatUnit: number | null = null;

  constructor(private gameBasicService: GameBasicService,
    private dataService: DataService,
    private toastService: ToastService,
    private spinnerService: SpinnerService,
    private iconService: IconService,
    private translationService: TranslationService) {
  }

  ngOnInit() {
    this.getNewMessage(null, null, false);
    if (this.reloadDelay > 0) {
      var maxRandom = this.tickLength * 1000 / this.reloadDelay;
      this.delayTime = Math.floor(Math.random() * maxRandom) + 1;
    }

    this.getMessageCount();

    this.dataService._broadcastSource$.subscribe(broadcast => {
      if (broadcast == "TICK") {
        this.currentTick = this.dataService.getTick();
        this.tickCount++;
        if (this.tickCount > this.reloadTicks)
          if (this.delayTime > 0) {
            setTimeout(() => {
              this.getMessageCount();
            }, this.delayTime);
          }
          else
            this.getMessageCount();
      }
      else if (broadcast.startsWith("MESSAGE_TO_")) {
        var receiver = broadcast.replace("MESSAGE_TO_", "");
        this.getNewMessage(null, receiver, true);
      }
    })
  }

  getTranslation(type: string, input: string) {
    if (type == "") {
      switch (input) {
        case "REPORT": {
          if (this.reportCount == 1)
            return $localize`REPORT`;
          else
            return $localize`REPORTS`;
          break;
        }
        case "MESSAGE": {
          if (this.messagesCount == 1)
            return $localize`MESSAGE`;
          else
            return $localize`MESSAGES`;
          break;
        }
        case "REPORT_TITLE_ACTIONDATE": {
          return $localize`REPORT_TITLE_ACTIONDATE`;
          break;
        }
      }


    }
    return "";
  }

  selectReport(report: Report) {
    this.selectedCombatTurn = null;
    if (this.reportSelected == report) {
      this.messageSelected = null;
      return;
    }
    this.selectedTab = 0;
    if (report.outcomeData != null && report.outcomeData != "")
      this.reportSelected = report;
    else
      this.getReport(report)
  }

  selectMessage(messageIn: Message) {
    if (this.messageSelected == messageIn) {
      this.messageSelected = null;
      return;
    }
    this.selectedTab = 0;
    if (messageIn.body != null && messageIn.body != "")
      this.messageSelected = messageIn;
    else
      this.getMessage(messageIn)
  }

  getNewMessage(replyToMessage: Message | null, receiverName: string | null, setFocus: boolean) {

    this.messageNew = { id: -1, body: "", isOutgoing: true, isUnread: true, receiverGroup: 0, replyToId: null, subject: "", reseiverName: "", senderName: "", sendDate: new Date() };
    if (receiverName != null)
      this.messageNew.reseiverName = receiverName;
    if (replyToMessage != null) {
      this.messageNew.replyToId = replyToMessage.id;
      this.messageNew.reseiverName = replyToMessage.senderName;
      this.messageNew.subject = "RE: " + replyToMessage.subject;
    }

    if (setFocus) {
      this.openModal("message");
      this.selectedTab = 1;
    }
  }

  openModal(input: string) {
    if (input == "message") {
      this.getMessages();
      this.messagesModalIsOpen = true;
    }
    else if (input == "report") {
      this.getReports();
      this.reportsModalIsOpen = true;
    }
  }

  closeModal() {
    this.messagesModalIsOpen = false;
    this.reportsModalIsOpen = false;
  }

  setTab(tab: number) {
    this.selectedTab = tab;
  }

  getIconByName(iconName: string) {
    return this.iconService.getIconByName(iconName);
  }

  getIconById(id: number | null) {
    if (id == null)
      return "";
    return this.iconService.getIconById(id);
  }


  createReportFrontEnd(report: Report): ReportFrontEnd {
    var result: ReportFrontEnd = { infoLines: [], outcome: null, detailsCombat: null };

    //INFO LINES
    result.infoLines = [];
    result.infoLines.push({ name: $localize`REPORT_TITLE_LOCATION`, value: report.x + "/" + report.y });
    if (report.villageName)
      result.infoLines.push({ name: $localize`REPORT_TITLE_VILLAGE`, value: report.villageName });
    else
      result.infoLines.push({ name: $localize`REPORT_TITLE_VILLAGE`, value: "-" });

    if (report.isIncoming && report.otherPlayerName)
      result.infoLines.push({ name: $localize`REPORT_SEND_BY`, value: report.otherPlayerName });
    else if (report.otherPlayerName)
      result.infoLines.push({ name: $localize`REPORT_SEND_TO`, value: report.otherPlayerName });

    //OUTCOME
    try {
      result.outcome = JSON.parse(report.outcomeData);// ReportOutcome
    } catch (error) { }

    //Swtch report-type for different details
    switch (report.reportType) {
      case 1: { //other

        break;
      }
      case 2: { //combat
        try {
          result.detailsCombat = JSON.parse(report.detailsData);

        } catch (error) { }

        break;
      }
    }

    return result;
  }
  //#region TURN CONTROLLS

  buildBattleField() {
    this.battleField = [];
    var rowCount = 11;
    var colCount = 24;

    for (var i = 0; i < rowCount; i++) {
      var row: number[] = [];
      for (var j = 0; j < colCount; j++)
        row.push(-1);
      this.battleField.push(row);
    }

  }

  goToTurn(number: number | null) {
    if (number == null)
      number = 0;
    if (this.reportSelected == null || this.reportSelected.reportFrontEnd == null || this.reportSelected.reportFrontEnd.detailsCombat == null || this.reportSelected.reportFrontEnd.detailsCombat.T.length < (number + 1))
      return;
    this.selectedCombatTurn = this.reportSelected.reportFrontEnd.detailsCombat.T[number];
    this.buildBattleField();

    //set unit amounts
    for (var i = 0; i < this.reportSelected.reportFrontEnd.detailsCombat.A.length; i++) {
      if (this.selectedCombatTurn.NA.length >= i) {
        this.reportSelected.reportFrontEnd.detailsCombat.A[i].turnAmount = this.selectedCombatTurn.NA[i];
        if (number == 0) {
          this.reportSelected.reportFrontEnd.detailsCombat.A[i].startAmount = this.selectedCombatTurn.NA[i];
          this.reportSelected.reportFrontEnd.detailsCombat.A[i].toolTip = this.buildToolTip(this.reportSelected.reportFrontEnd.detailsCombat.A[i]);
        }

      }
      if (this.selectedCombatTurn.PA.length >= i && this.selectedCombatTurn.NA.length >= i && this.selectedCombatTurn.NA[i] != 0)
        this.setUnitIdToMap(this.selectedCombatTurn.PA[i], this.reportSelected.reportFrontEnd.detailsCombat.A[i].Id);
      else if (this.selectedCombatTurn.PA.length >= i && this.selectedCombatTurn.NA.length >= i && this.selectedCombatTurn.NA[i] == 0) {
        //TODO save deathposition
      }
    }

    for (var i = 0; i < this.reportSelected.reportFrontEnd.detailsCombat.D.length; i++) {
      if (this.selectedCombatTurn.ND.length >= i) {
        this.reportSelected.reportFrontEnd.detailsCombat.D[i].turnAmount = this.selectedCombatTurn.ND[i];
        if (number == 0) {
          this.reportSelected.reportFrontEnd.detailsCombat.D[i].startAmount = this.selectedCombatTurn.ND[i];
          this.reportSelected.reportFrontEnd.detailsCombat.D[i].toolTip = this.buildToolTip(this.reportSelected.reportFrontEnd.detailsCombat.D[i]);
        }
      }
      if (this.selectedCombatTurn.PD.length >= i && this.selectedCombatTurn.ND.length >= i && this.selectedCombatTurn.ND[i] != 0)
        this.setUnitIdToMap(this.selectedCombatTurn.PD[i], this.reportSelected.reportFrontEnd.detailsCombat.D[i].Id);
    }
  }

  setUnitIdToMap(fieldNumber: number | null, unitId: number) {
    if (fieldNumber == null || fieldNumber == -1)
      return;

    var row = (fieldNumber - (fieldNumber % 1000)) / 1000;
    var col = fieldNumber - (row * 1000);

    this.battleField[row - 1][col - 1] = unitId;
  }

  getIconByUnitId(id: number | null) {
    if (id == null || id == -1)
      return "";
    //TODO if unit turnamount == 0 highlight last position
    var iconId: number | null = null;
    if (id < 15) {      //0-14 > attacker
      var unit = this.reportSelected!.reportFrontEnd!.detailsCombat!.A.find(u => u.Id == id);
      if (unit != null && unit.Icon != null) {
        iconId = unit.Icon;
        if (unit.turnAmount == 0)
          return "";
      }
    }
    else {      //15-29 > defender
      var unit = this.reportSelected!.reportFrontEnd!.detailsCombat!.D.find(u => u.Id == id);
      if (unit != null && unit.Icon != null) {
        iconId = unit.Icon;
        if (unit.turnAmount == 0)
          return "";
      }
    }
    if (iconId != null)
      return this.iconService.getIconById(iconId);

    return "";
  }

  selectUnitIn(unitId: number | null) {
    if (unitId == null || unitId == -1 || this.selectedCombatUnit == unitId) {
      this.selectedCombatUnit = null;
      return;
    }
    this.selectedCombatUnit = unitId;
  }

  getBattleFieldActions(unitId: number, direction: string): boolean {
    if (unitId == -1)
      return false;

    var row: number | null = null;
    var col: number | null = null;
    for (var i = 0; i < this.battleField.length; i++) {
      for (var j = 0; j < this.battleField[i].length; j++) {
        if (this.battleField[i][j] == unitId) {
          row = i;
          col = j;
          j = this.battleField[i].length - 1;
          i = this.battleField.length - 1;
        }
      }
    }

    if (row == null || col == null)
      return false;

    var rangeStart = 0;
    var rangeEnd = 14;
    if (unitId < 15) {
      rangeStart = 15;
      rangeEnd = 29;
    }
    //FIND FIELD by unit on it

    if (direction == "right" && this.battleField[row].length > (col + 1)) {
      //check field to the right for hostile
      var neighboringUnitId = this.battleField[row][col + 1];
      if (neighboringUnitId > -1 && neighboringUnitId >= rangeStart && neighboringUnitId <= rangeEnd) {
        return true;
      }
    }
    else if (direction == "bottom" && this.battleField.length > (row + 1)) {
      // check bottom field for hostile
      var neighboringUnitId = this.battleField[row + 1][col];
      if (neighboringUnitId > -1 && neighboringUnitId >= rangeStart && neighboringUnitId <= rangeEnd) {
        return true;
      }
    }
    else if (direction == "ranged") {
      if (this.reportSelected == null || this.reportSelected.reportFrontEnd == null || this.reportSelected.reportFrontEnd.detailsCombat == null || this.selectedCombatTurn == null)
        return false;
      //TODO check for ranged target in turn-data
      if (unitId >= 0 && unitId <= 14) {
        //attacker
        for (var a = 0; a < this.reportSelected!.reportFrontEnd!.detailsCombat!.A.length; a++) {
          if (this.reportSelected!.reportFrontEnd!.detailsCombat!.A[a].Id == unitId && this.selectedCombatTurn.PTA.length > a) {
            var target = this.selectedCombatTurn.PTA[a];
            if (target != null && target > 0)
              return true;
          }
        }
      }
      if (unitId >= 15 && unitId <= 29) {
        //defender
        for (var d = 0; d < this.reportSelected!.reportFrontEnd!.detailsCombat!.D.length; d++) {
          if (this.reportSelected!.reportFrontEnd!.detailsCombat!.D[d].Id == unitId && this.selectedCombatTurn.PTD.length > d) {
            var target = this.selectedCombatTurn.PTD[d];
            if (target != null && target > 0)
              return true;
          }
        }
      }
    }

    return false;
  }

  buildToolTip(unit: ReportDetailsCombatUnit): string {
    var result = "";
    result = unit.Name;
    if (unit.Items.length > 0) {
      var splits = unit.Items.split("#");
      for (var i = 1; i < splits.length; i++) {
        //TODO translate items 
        result += "\n" + splits[i];
      }
    }
    return result;
  }

  //#endregion

  //========== A P I _ C A L L S ==========
  getMessageCount() {
    //this.spinnerService.addToLoading();
    this.gameBasicService.getMessageCount().subscribe((result: MessageCount) => {
      if (result) {
        this.messagesCount = result.messageCount;
        this.messagesCountNew = result.messageUnreadCount;
        this.reportCount = result.reportCount;
        this.reportCountNew = result.reportUnreadCount;
      }
      this.spinnerService.removeFromLoading();
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      //this.spinnerService.removeFromLoading();
    });
  }
  //#region messages
  getMessages() {
    this.spinnerService.addToLoading();
    this.messageSelected = null;
    this.gameBasicService.getMessages().subscribe((result: Message[]) => {
      if (result) {
        this.messagesIn = [];
        this.messagesOut = [];
        for (var i = 0; i < result.length; i++) {
          if (result[i].isOutgoing)
            this.messagesOut.push(result[i]);
          else
            this.messagesIn.push(result[i]);
        }
      }
      this.spinnerService.removeFromLoading();
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.spinnerService.removeFromLoading();
    });
  }

  getMessage(message: Message) {
    this.spinnerService.addToLoading();
    this.gameBasicService.getMessage(message.id).subscribe((result: Message) => {
      if (result) {
        message.body = result.body;
        message.senderName = result.senderName;
        if (message.isUnread) {
          message.isUnread = false;
          this.messagesCountNew--;
        }
        this.messageSelected = message;
      }
      this.spinnerService.removeFromLoading();
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.spinnerService.removeFromLoading();
    });
  }

  deleteMessage(message: Message) {
    this.spinnerService.addToLoading();
    this.gameBasicService.deleteMessage(message.id).subscribe((result: string) => {
      if (result == "SUCCESS") {
        var index = this.messagesOut.indexOf(message);
        if (index > -1) {
          this.messagesOut.splice(index, 1);
        }
        else {
          index = this.messagesIn.indexOf(message);
          if (index > -1) {
            this.messagesIn.splice(index, 1);
          }
        }
        this.messageSelected = null;
        this.messagesCount--;
      }
      this.spinnerService.removeFromLoading();
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.spinnerService.removeFromLoading();
    });
  }

  sendMessage() {
    if (this.messageNew == null)
      return;
    this.spinnerService.addToLoading();
    this.gameBasicService.sendMessage(this.messageNew).subscribe((result: Message[]) => {
      for (var i = 0; i < result.length; i++)
        this.messagesOut.push(result[i]);
      this.getNewMessage(null, null, false);
      this.spinnerService.removeFromLoading();
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.spinnerService.removeFromLoading();
    });
  }
  //#endregion messages

  //#region reports
  getReports() {
    this.spinnerService.addToLoading();
    this.messageSelected = null;
    this.gameBasicService.getReports().subscribe((result: Report[]) => {
      if (result)
        this.reports = result;
      else
        this.reports = [];
      for (var i = 0; i < this.reports.length; i++) {
        //build subject
        switch (this.reports[i].reportType) {
          case 1: {//Other
            this.reports[i].icon = this.iconService.getIconByName("flagWhite");
            this.reports[i].subject = $localize`REPORT_OTHER`;
            break;
          }
          case 2: {//combat
            this.reports[i].icon = this.iconService.getIconByName("twoSwords");
            this.reports[i].subject = $localize`REPORT_ATTACK`;
            break;
          }
        }
      }
      this.spinnerService.removeFromLoading();
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.spinnerService.removeFromLoading();
    });
  }

  getReport(report: Report) {
    this.spinnerService.addToLoading();
    this.gameBasicService.getReport(report.id).subscribe((result: Report) => {
      if (result) {

        report.outcomeData = result.outcomeData;
        report.detailsData = result.detailsData;
        report.reportFrontEnd = this.createReportFrontEnd(report);

        this.reportSelected = report;
        if (report.reportType == 2)//combat
          this.goToTurn(0);

        if (report.isUnread) {
          report.isUnread = false;
          this.reportCountNew--;
        }
      }
      this.spinnerService.removeFromLoading();
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.spinnerService.removeFromLoading();
    });
  }

  deleteReport(report: Report) {
    this.spinnerService.addToLoading();
    this.gameBasicService.deleteReport(report.id).subscribe((result: string) => {
      if (result == "SUCCESS") {
        var index = this.reports.indexOf(report);
        if (index > -1) {
          this.reports.splice(index, 1);
        }
        this.reportSelected = null;
        this.reportCount--;
      }
      this.spinnerService.removeFromLoading();
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.spinnerService.removeFromLoading();
    });
  }
  //#endregion reports
}
