import { Component, OnInit } from '@angular/core';
import { SpinnerService } from '../../../services/spinner.service';
import { ToastService } from '../../../services/toast.service';
import { DataService } from '../../../services/data.service';
import { TranslationService } from '../../../services/translation.service';
import { Router } from '@angular/router';
import { ItemService } from '../../../services/data/item.service';
import { Blueprint, Design, DesignMinimal, DesignStaticData, Mount, Recruit } from '../../../models/item-models';
import { IdName, IdNameDesc, IdNameValueDesc } from '../../../models/pair-models';
import { IconService } from '../../../services/icon.service';

@Component({
  selector: 'app-design',
  templateUrl: './design.component.html',
  styleUrls: ['./design.component.css']
})
export class DesignComponent implements OnInit {

  maxDesigns: number = 30;
  nextIdToUse: number = -1;
  blueprints: Blueprint[] = [];
  blueprintsB: Blueprint[] = [];
  recruits: Recruit[] = [];
  mounts: Mount[] = [];
  designs: DesignMinimal[] = [];
  selectedDesign: DesignMinimal | null = null;
  changedMade: boolean = false;
  handTwoBlockedA: boolean = false;
  handTwoBlockedB: boolean = false;
  selectedTab: string = "A";
  dummy: IdName = { id: -1, name: "-" };

  weight: number = 0;
  weightMax: number = 0;

  nationFilter: boolean = false;
  ownedFilter: boolean = false;

  icons: IdNameValueDesc[] = [];

  selectHelm: IdName[] = [];
  selectedHelmId: number = -1;
  selectHelmLevels: IdName[] = [];
  selectChest: IdName[] = [];
  selectedChestId: number = -1;
  selectChestLevels: IdName[] = [];
  selectBoots: IdName[] = [];
  selectedBootsId: number = -1;
  selectBootsLevels: IdName[] = [];
  selectEquip: IdName[] = [];
  selectedEquipId: number = -1;
  selectEquipLevels: IdName[] = [];

  selectHandOneA: IdName[] = [];
  selectedHandOneAId: number = -1;
  selectHandOneALevels: IdName[] = [];
  selectHandTwoA: IdName[] = [];
  selectedHandTwoAId: number = -1;
  selectHandTwoALevels: IdName[] = [];
  selectBackA: IdName[] = [];
  selectedBackAId: number = -1;
  selectBackALevels: IdName[] = [];
  selectBeltA: IdName[] = [];
  selectedBeltAId: number = -1;
  selectBeltALevels: IdName[] = [];

  selectHandOneB: IdName[] = [];
  selectedHandOneBId: number = -1;
  selectHandOneBLevels: IdName[] = [];
  selectHandTwoB: IdName[] = [];
  selectedHandTwoBId: number = -1;
  selectHandTwoBLevels: IdName[] = [];
  selectBackB: IdName[] = [];
  selectedBackBId: number = -1;
  selectBackBLevels: IdName[] = [];
  selectBeltB: IdName[] = [];
  selectedBeltBId: number = -1;
  selectBeltBLevels: IdName[] = [];

  constructor(private toastService: ToastService, private itemService: ItemService, private spinnerService: SpinnerService,
    private translationService: TranslationService, private dataService: DataService, private router: Router, private iconService: IconService) {    
    
  }

  ngOnInit() {
    this.getStaticData();
    this.icons = this.iconService.getIconObjectIdRange(1, 20);
  }

  getIcon(iconId: number | null) {
    if (iconId == null)
      return "";
    return this.iconService.getIconById(iconId);
  }

  addDesign() {
    if (!this.validate())
      return;
    //add minimalDasign
    var design: Design = {
      id: this.nextIdToUse, name: $localize`NEW_DESIGN`, flagPosition: -1, recruitTypeId: this.recruits[0].id, mountTypeId: this.mounts[0].id, itemKeywords: [],
      costWood: 0, costIron: 0, costStone: 0, costFood: 0, helmId: null, chestId: null, bootsId: null, equipmentId: null, icon: -1,
      hand1AId: null, hand2AId: null, backAId: null, beltAId: null, hand1BId: null, hand2BId: null, backBId: null, beltBId: null
    };
    var minimalToAdd: DesignMinimal = { id: design.id, name: design.name, deletability: true, design: design, icon: null };
    this.designs.push(minimalToAdd);
    this.nextIdToUse--;
    if (!this.changedMade) this.bindDesign(minimalToAdd);
  }

  selectDesign(d: DesignMinimal) {
    if (!this.validate())
      return;
    if (this.selectDesign != null && d.id == this.selectedDesign?.id)
      return; //design allready selected

    //TODO if this.changedMade == true ask if discard
    if (this.changedMade == true)
      return;

    if (d.design == null)
      this.getDesign(d);
    else
      this.bindDesign(d);
  }

  nameChanged() {
    if (this.selectedDesign == null || this.selectedDesign.design == null)
      return;
    this.selectedDesign.design.name = this.selectedDesign.name;
    this.changedMade = true;
  }

  iconChanged() {
    if (this.selectedDesign == null || this.selectedDesign.design == null || this.selectedDesign.design.icon == null)
      return;
    this.selectedDesign.icon = this.selectedDesign.design.icon;
    this.changedMade = true;
  }

  changeTab(tab: string) {
    if (tab == "B" && this.selectedTab == "A" && this.selectedDesign != null && this.selectedDesign.design != null) {
      this.blueprintsB = [];
      //BACK
      if (this.selectedDesign.design.backAId != null) {
        var blueprint = this.findBlueprintByLevel(this.selectedDesign.design.backAId);
        if (blueprint != null) {
          var level = blueprint.levels.find(l => l.id == this.selectedDesign!.design!.backAId)
          if (level != null) {
            var existingBp = this.blueprintsB.find(bp => bp.id == blueprint!.id)
            if (existingBp != null) {
              var existingLevel = existingBp.levels.find(l => l.id == level!.id)
              if (existingLevel == null)
                existingBp.levels.push(level)
            }
            else {
              var copy: Blueprint = this.copyBlueprint(blueprint);
              copy.levels.push(level);
              this.blueprintsB.push(copy);
            }
          }
        }
      }
      //BELT
      if (this.selectedDesign.design.beltAId != null) {
        var blueprint = this.findBlueprintByLevel(this.selectedDesign.design.beltAId);
        if (blueprint != null) {
          var level = blueprint.levels.find(l => l.id == this.selectedDesign!.design!.beltAId)
          if (level != null) {
            var existingBp = this.blueprintsB.find(bp => bp.id == blueprint!.id)
            if (existingBp != null) {
              var existingLevel = existingBp.levels.find(l => l.id == level!.id)
              if (existingLevel == null)
                existingBp.levels.push(level)
            }
            else {
              var copy: Blueprint = this.copyBlueprint(blueprint);
              copy.levels.push(level);
              this.blueprintsB.push(copy);
            }
          }
        }
      }
      //HAND1
      if (this.selectedDesign.design.hand1AId != null) {
        var blueprint = this.findBlueprintByLevel(this.selectedDesign.design.hand1AId);
        if (blueprint != null) {
          var level = blueprint.levels.find(l => l.id == this.selectedDesign!.design!.hand1AId)
          if (level != null) {
            var existingBp = this.blueprintsB.find(bp => bp.id == blueprint!.id)
            if (existingBp != null) {
              var existingLevel = existingBp.levels.find(l => l.id == level!.id)
              if (existingLevel == null)
                existingBp.levels.push(level)
            }
            else {
              var copy: Blueprint = this.copyBlueprint(blueprint);
              copy.levels.push(level);
              this.blueprintsB.push(copy);
            }
          }
        }
      }
      //HAND2
      if (this.selectedDesign.design.hand2AId != null) {
        var blueprint = this.findBlueprintByLevel(this.selectedDesign.design.hand2AId);
        if (blueprint != null) {
          var level = blueprint.levels.find(l => l.id == this.selectedDesign!.design!.hand2AId)
          if (level != null) {
            var existingBp = this.blueprintsB.find(bp => bp.id == blueprint!.id)
            if (existingBp != null) {
              var existingLevel = existingBp.levels.find(l => l.id == level!.id)
              if (existingLevel == null)
                existingBp.levels.push(level)
            }
            else {
              var copy: Blueprint = this.copyBlueprint(blueprint);
              copy.levels.push(level);
              this.blueprintsB.push(copy);
            }
          }
        }
      }

      //fill selects
      this.selectHandOneB = [this.dummy];
      this.selectHandTwoB = [this.dummy];
      this.selectBackB = [this.dummy];
      this.selectBeltB = [this.dummy];

      for (var i = 0; i < this.blueprintsB.length; i++) {
        if ((this.blueprintsB[i].gearPositionFLagActive & 8) > 0 || (this.blueprintsB[i].gearPositionFLagPassive != null && (this.blueprintsB[i].gearPositionFLagPassive! & 8) > 0)) {
          this.selectHandOneB.push({ id: this.blueprintsB[i].id, name: this.blueprintsB[i].name + " (1)" });
          this.selectHandTwoB.push({ id: this.blueprintsB[i].id, name: this.blueprintsB[i].name });
        }
        if ((this.blueprintsB[i].gearPositionFLagActive & 16) > 0 || (this.blueprintsB[i].gearPositionFLagPassive != null && (this.blueprintsB[i].gearPositionFLagPassive! & 16) > 0))
          this.selectHandOneB.push({ id: this.blueprintsB[i].id, name: this.blueprintsB[i].name + " (2)" });
        if ((this.blueprintsB[i].gearPositionFLagActive & 32) > 0 || (this.blueprintsB[i].gearPositionFLagPassive != null && (this.blueprintsB[i].gearPositionFLagPassive! & 32) > 0))
          this.selectBackB.push({ id: this.blueprintsB[i].id, name: this.blueprintsB[i].name });
        if ((this.blueprintsB[i].gearPositionFLagActive & 64) > 0 || (this.blueprintsB[i].gearPositionFLagPassive != null && (this.blueprintsB[i].gearPositionFLagPassive! & 64) > 0))
          this.selectBeltB.push({ id: this.blueprintsB[i].id, name: this.blueprintsB[i].name });
      }

      //CHECK IF SELECTED ARE STILL AVAILABE
      if (this.selectedHandOneBId != null && this.selectedHandOneBId != -1) {
        var foundBp = this.selectHandOneB.find(bp => bp.id == this.selectedHandOneBId)
        if (!foundBp)
          this.selectedHandOneBId = -1;
        if (this.selectedDesign != null && this.selectedDesign.design != null) {
          if (!foundBp)
            this.selectedDesign.design.hand1BId = null;
          else {
            var foundLevel = this.selectHandOneBLevels.find(l => this.selectedDesign != null && this.selectedDesign.design != null && l.id == this.selectedDesign.design.hand1BId);
            if (!foundLevel)
              this.selectedDesign.design.hand1BId = null;
          }
        }
      }
      if (this.selectedHandTwoBId != null && this.selectedHandTwoBId != -1) {
        var foundBp = this.selectHandTwoB.find(bp => bp.id == this.selectedHandTwoBId)
        if (!foundBp)
          this.selectedHandTwoBId = -1;
        if (this.selectedDesign != null && this.selectedDesign.design != null) {
          if (!foundBp)
            this.selectedDesign.design.hand2BId = null;
          else {
            var foundLevel = this.selectHandTwoBLevels.find(l => this.selectedDesign != null && this.selectedDesign.design != null && l.id == this.selectedDesign.design.hand2BId);
            if (!foundLevel)
              this.selectedDesign.design.hand2BId = null;
          }
        }
      }
      if (this.selectedBeltBId != null && this.selectedBeltBId != -1) {
        var foundBp = this.selectBeltB.find(bp => bp.id == this.selectedBeltBId)
        if (!foundBp)
          this.selectedBeltBId = -1;
        if (this.selectedDesign != null && this.selectedDesign.design != null) {
          if (!foundBp)
            this.selectedDesign.design.beltBId = null;
          else {
            var foundLevel = this.selectBeltBLevels.find(l => this.selectedDesign != null && this.selectedDesign.design != null && l.id == this.selectedDesign.design.beltBId);
            if (!foundLevel)
              this.selectedDesign.design.beltBId = null;
          }
        }
      }
      if (this.selectedBackBId != null && this.selectedBackBId != -1) {
        var foundBp = this.selectBackB.find(bp => bp.id == this.selectedBackBId)
        if (!foundBp)
          this.selectedBackBId = -1;
        if (this.selectedDesign != null && this.selectedDesign.design != null) {
          if (!foundBp)
            this.selectedDesign.design.backBId = null;
          else {
            var foundLevel = this.selectBackBLevels.find(l => this.selectedDesign != null && this.selectedDesign.design != null && l.id == this.selectedDesign.design.backBId);
            if (!foundLevel)
              this.selectedDesign.design.backBId = null;
          }
        }
      }

    }

    this.selectedTab = tab;
  }

  designChanged(changedValue: string) {
    this.changedMade = true;
    switch (changedValue) {
      case "RECRUIT": {
        this.runCalc("-");
        break;
      }
      case "MOUNT": {
        this.runCalc("-");
        break;
      }
      //================================================
      case "HELM": {
        this.selectHelmLevels = [];
        if (this.selectedHelmId != -1) {
          var blueprint = this.blueprints.find(bp => bp.id == this.selectedHelmId);
          if (blueprint != null)
            for (var i = 0; i < blueprint.levels.length; i++) {
              if (!this.ownedFilter || this.checkLevelOwnership(blueprint.levels[i].id))
                this.selectHelmLevels.push({ id: blueprint.levels[i].id, name: $localize`COMMON_LEVEL` + " " + blueprint.levels[i].level });
            }
        }
        if (this.selectedDesign!.design!.helmId != null) {
          this.selectedDesign!.design!.helmId = null
          this.runCalc("A");
        }
        break;
      }
      case "HELM-LVL": {
        this.runCalc("A");
        break;
      }
      case "EQUIP": {
        this.selectEquipLevels = [];
        if (this.selectedEquipId != -1) {
          var blueprint = this.blueprints.find(bp => bp.id == this.selectedEquipId);
          if (blueprint != null)
            for (var i = 0; i < blueprint.levels.length; i++) {
              if (!this.ownedFilter || this.checkLevelOwnership(blueprint.levels[i].id))
                this.selectEquipLevels.push({ id: blueprint.levels[i].id, name: $localize`COMMON_LEVEL` + " " + blueprint.levels[i].level });
            }
        }
        if (this.selectedDesign!.design!.equipmentId != null) {
          this.selectedDesign!.design!.equipmentId = null
          this.runCalc("A");
        }
        break;
      }
      case "EQUIP-LVL": {
        this.runCalc("A");
        break;
      }
      case "CHEST": {
        this.selectChestLevels = [];
        if (this.selectedChestId != -1) {
          var blueprint = this.blueprints.find(bp => bp.id == this.selectedChestId);
          if (blueprint != null)
            for (var i = 0; i < blueprint.levels.length; i++) {
              if (!this.ownedFilter || this.checkLevelOwnership(blueprint.levels[i].id))
                this.selectChestLevels.push({ id: blueprint.levels[i].id, name: $localize`COMMON_LEVEL` + " " + blueprint.levels[i].level });
            }
        }
        if (this.selectedDesign!.design!.chestId != null) {
          this.selectedDesign!.design!.chestId = null
          this.runCalc("A");
        }
        break;
      }
      case "CHEST-LVL": {
        this.runCalc("A");
        break;
      }
      case "BOOTS": {
        this.selectBootsLevels = [];
        if (this.selectedBootsId != -1) {
          var blueprint = this.blueprints.find(bp => bp.id == this.selectedBootsId);
          if (blueprint != null)
            for (var i = 0; i < blueprint.levels.length; i++) {
              if (!this.ownedFilter || this.checkLevelOwnership(blueprint.levels[i].id))
                this.selectBootsLevels.push({ id: blueprint.levels[i].id, name: $localize`COMMON_LEVEL` + " " + blueprint.levels[i].level });
            }
        }
        if (this.selectedDesign!.design!.bootsId != null) {
          this.selectedDesign!.design!.bootsId = null
          this.runCalc("A");
        }
        break;
      }
      case "BOOTS-LVL": {
        this.runCalc("A");
        break;
      }
      //================================================
      case "HANDONE": {
        this.selectHandOneALevels = [];
        if (this.selectedHandOneAId != -1) {
          var blueprint = this.blueprints.find(bp => bp.id == this.selectedHandOneAId);
          if (blueprint != null) {
            for (var i = 0; i < blueprint.levels.length; i++) {
              if (!this.ownedFilter || this.checkLevelOwnership(blueprint.levels[i].id))
                this.selectHandOneALevels.push({ id: blueprint.levels[i].id, name: $localize`COMMON_LEVEL` + " " + blueprint.levels[i].level });
            }
            if ((blueprint.gearPositionFLagActive & 16) > 0) {
              this.selectHandTwoALevels = [];
              this.selectedHandTwoAId = -1;
              this.handTwoBlockedA = true;
              if (this.selectedDesign!.design!.hand2AId != null) {
                this.selectedDesign!.design!.hand2AId = null
                this.runCalc("A");
              }
            }
            else
              this.handTwoBlockedA = false;
          }
        }
        if (this.selectedDesign!.design!.hand1AId != null) {
          this.selectedDesign!.design!.hand1AId = null
          this.runCalc("A");
        }
        break;
      }
      case "HANDONE-LVL": {
        this.runCalc("A");
        break;
      }
      case "HANDTWO": {
        this.selectHandTwoALevels = [];
        if (this.selectedHandTwoAId != -1) {
          var blueprint = this.blueprints.find(bp => bp.id == this.selectedHandTwoAId);
          if (blueprint != null)
            for (var i = 0; i < blueprint.levels.length; i++) {
              if (!this.ownedFilter || this.checkLevelOwnership(blueprint.levels[i].id))
                this.selectHandTwoALevels.push({ id: blueprint.levels[i].id, name: $localize`COMMON_LEVEL` + " " + blueprint.levels[i].level });
            }
        }
        if (this.selectedDesign!.design!.hand2AId != null) {
          this.selectedDesign!.design!.hand2AId = null
          this.runCalc("A");
        }
        break;
      }
      case "HANDTWO-LVL": {
        this.runCalc("A");
        break;
      }
      case "BELT": {
        this.selectBeltALevels = [];
        if (this.selectedBeltAId != -1) {
          var blueprint = this.blueprints.find(bp => bp.id == this.selectedBeltAId);
          if (blueprint != null)
            for (var i = 0; i < blueprint.levels.length; i++) {
              if (!this.ownedFilter || this.checkLevelOwnership(blueprint.levels[i].id))
                this.selectBeltALevels.push({ id: blueprint.levels[i].id, name: $localize`COMMON_LEVEL` + " " + blueprint.levels[i].level });
            }
        }
        if (this.selectedDesign!.design!.beltAId != null) {
          this.selectedDesign!.design!.beltAId = null
          this.runCalc("A");
        }
        break;
      }
      case "BELT-LVL": {
        this.runCalc("A");
        break;
      }
      case "BACK": {
        this.selectBackALevels = [];
        if (this.selectedBackAId != -1) {
          var blueprint = this.blueprints.find(bp => bp.id == this.selectedBackAId);
          if (blueprint != null)
            for (var i = 0; i < blueprint.levels.length; i++) {
              if (!this.ownedFilter || this.checkLevelOwnership(blueprint.levels[i].id))
                this.selectBackALevels.push({ id: blueprint.levels[i].id, name: $localize`COMMON_LEVEL` + " " + blueprint.levels[i].level });
            }
        }
        if (this.selectedDesign!.design!.backAId != null) {
          this.selectedDesign!.design!.backAId = null
          this.runCalc("A");
        }
        break;
      }
      case "BACK-LVL": {
        this.runCalc("A");
        break;
      }
    }
  }

  designBChanged(changedValue: string) {
    //if (this.selectedDesign == null || this.selectedDesign.design == null)
    //  return;
    //var d: Design = this.selectedDesign.design;
    this.changedMade = true;
    switch (changedValue) {
      case "RECRUIT": {
        this.runCalc("-");
        break;
      }
      case "MOUNT": {
        this.runCalc("-");
        break;
      }
      //================================================
      case "HANDONE": {
        this.selectHandOneBLevels = [];
        if (this.selectedHandOneBId != -1) {
          var blueprint = this.blueprintsB.find(bp => bp.id == this.selectedHandOneBId);
          if (blueprint != null) {
            for (var i = 0; i < blueprint.levels.length; i++) {
              if (!this.ownedFilter || this.checkLevelOwnership(blueprint.levels[i].id))
                this.selectHandOneBLevels.push({ id: blueprint.levels[i].id, name: $localize`COMMON_LEVEL` + " " + blueprint.levels[i].level });
            }
            //if (this.selectedDesign != null && this.selectedDesign.design != null && this.selectHandOneBLevels.length == 1)
            //  this.selectedDesign.design.hand1BId = this.selectHandOneBLevels[0].id;
            if ((blueprint.gearPositionFLagActive & 16) > 0) {
              this.selectHandTwoBLevels = [];
              this.selectedHandTwoBId = -1;
              this.handTwoBlockedB = true;
              if (this.selectedDesign!.design!.hand2BId != null) {
                this.selectedDesign!.design!.hand2BId = null
                this.runCalc("B");
              }
            }
            else
              this.handTwoBlockedB = false;
          }
        }
        if (this.selectedDesign!.design!.hand1BId != null) {
          this.selectedDesign!.design!.hand1BId = null
          this.runCalc("B");
        }
        break;
      }
      case "HANDONE-LVL": {
        this.runCalc("B");
        break;
      }
      case "HANDTWO": {
        this.selectHandTwoBLevels = [];
        if (this.selectedHandTwoBId != -1) {
          var blueprint = this.blueprintsB.find(bp => bp.id == this.selectedHandTwoBId);
          if (blueprint != null) {
            for (var i = 0; i < blueprint.levels.length; i++) {
              if (!this.ownedFilter || this.checkLevelOwnership(blueprint.levels[i].id))
                this.selectHandTwoBLevels.push({ id: blueprint.levels[i].id, name: $localize`COMMON_LEVEL` + " " + blueprint.levels[i].level });
            }
            //if (this.selectHandTwoBLevels.length == 1)
            //  this.selectedDesign!.design!.hand2BId = this.selectHandTwoBLevels[0].id;
          }
        }
        if (this.selectedDesign!.design!.hand2BId != null) {
          this.selectedDesign!.design!.hand2BId = null
          this.runCalc("B");
        }
        break;
      }
      case "HANDTWO-LVL": {
        this.runCalc("B");
        break;
      }
      case "BELT": {
        this.selectBeltBLevels = [];
        if (this.selectedBeltBId != -1) {
          var blueprint = this.blueprintsB.find(bp => bp.id == this.selectedBeltBId);
          if (blueprint != null) {
            for (var i = 0; i < blueprint.levels.length; i++) {
              if (!this.ownedFilter || this.checkLevelOwnership(blueprint.levels[i].id))
                this.selectBeltBLevels.push({ id: blueprint.levels[i].id, name: $localize`COMMON_LEVEL` + " " + blueprint.levels[i].level });
            }
            //if (this.selectBeltBLevels.length == 1)
            //  this.selectedDesign!.design!.beltBId = this.selectBeltBLevels[0].id;
          }
        }
        if (this.selectedDesign!.design!.beltBId != null) {
          this.selectedDesign!.design!.beltBId = null
          this.runCalc("B");
        }
        break;
      }
      case "BELT-LVL": {
        this.runCalc("B");
        break;
      }
      case "BACK": {
        this.selectBackBLevels = [];
        if (this.selectedBackBId != -1) {
          var blueprint = this.blueprintsB.find(bp => bp.id == this.selectedBackBId);
          if (blueprint != null) {
            for (var i = 0; i < blueprint.levels.length; i++) {
              if (!this.ownedFilter || this.checkLevelOwnership(blueprint.levels[i].id))
                this.selectBackBLevels.push({ id: blueprint.levels[i].id, name: $localize`COMMON_LEVEL` + " " + blueprint.levels[i].level });
            }
            //if (this.selectBackBLevels.length == 1)
            //  this.selectedDesign!.design!.backBId = this.selectBackBLevels[0].id;
          }
        }
        if (this.selectedDesign!.design!.backBId != null) {
          this.selectedDesign!.design!.backBId = null
          this.runCalc("B");
        }
        break;
      }
      case "BACK-LVL": {
        this.runCalc("B");
        break;
      }
    }
  }

  buildSelectData() {

    this.selectHelm = [this.dummy];
    this.selectChest = [this.dummy];
    this.selectBoots = [this.dummy];
    this.selectEquip = [this.dummy];

    this.selectHandOneA = [this.dummy];
    this.selectHandTwoA = [this.dummy];
    this.selectBackA = [this.dummy];
    this.selectBeltA = [this.dummy];


    for (var i = 0; i < this.blueprints.length; i++) {
      var addBlueprint: boolean = true;
      if (this.nationFilter) {
        //TODO check nation
        addBlueprint = false;
      }
      if (this.ownedFilter) {
        //TODO check owned by player
        addBlueprint = false;
      }
      if (addBlueprint) {
        //Helmet = 1, Chest = 2, Boots = 4, OneHand = 8, TwoHands = 16, Back = 32, Belt = 64, Equip = 128
        if ((this.blueprints[i].gearPositionFLagActive & 1) > 0 || (this.blueprints[i].gearPositionFLagPassive != null && (this.blueprints[i].gearPositionFLagPassive! & 1) > 0))
          this.selectHelm.push({ id: this.blueprints[i].id, name: this.blueprints[i].name });
        if ((this.blueprints[i].gearPositionFLagActive & 2) > 0 || (this.blueprints[i].gearPositionFLagPassive != null && (this.blueprints[i].gearPositionFLagPassive! & 2) > 0))
          this.selectChest.push({ id: this.blueprints[i].id, name: this.blueprints[i].name });
        if ((this.blueprints[i].gearPositionFLagActive & 4) > 0 || (this.blueprints[i].gearPositionFLagPassive != null && (this.blueprints[i].gearPositionFLagPassive! & 4) > 0))
          this.selectBoots.push({ id: this.blueprints[i].id, name: this.blueprints[i].name });
        if ((this.blueprints[i].gearPositionFLagActive & 128) > 0 || (this.blueprints[i].gearPositionFLagPassive != null && (this.blueprints[i].gearPositionFLagPassive! & 128) > 0))
          this.selectEquip.push({ id: this.blueprints[i].id, name: this.blueprints[i].name });

        if ((this.blueprints[i].gearPositionFLagActive & 8) > 0 || (this.blueprints[i].gearPositionFLagPassive != null && (this.blueprints[i].gearPositionFLagPassive! & 8) > 0)) {
          this.selectHandOneA.push({ id: this.blueprints[i].id, name: this.blueprints[i].name + " (1)" });
          this.selectHandTwoA.push({ id: this.blueprints[i].id, name: this.blueprints[i].name });
        }
        if ((this.blueprints[i].gearPositionFLagActive & 16) > 0 || (this.blueprints[i].gearPositionFLagPassive != null && (this.blueprints[i].gearPositionFLagPassive! & 16) > 0))
          this.selectHandOneA.push({ id: this.blueprints[i].id, name: this.blueprints[i].name + " (2)" });
        if ((this.blueprints[i].gearPositionFLagActive & 32) > 0 || (this.blueprints[i].gearPositionFLagPassive != null && (this.blueprints[i].gearPositionFLagPassive! & 32) > 0))
          this.selectBackA.push({ id: this.blueprints[i].id, name: this.blueprints[i].name });
        if ((this.blueprints[i].gearPositionFLagActive & 64) > 0 || (this.blueprints[i].gearPositionFLagPassive != null && (this.blueprints[i].gearPositionFLagPassive! & 64) > 0))
          this.selectBeltA.push({ id: this.blueprints[i].id, name: this.blueprints[i].name });

      }
    }
  }

  bindDesign(d: DesignMinimal) {
    if (d.design == null)
      return;
    this.selectedDesign = d;

    var blueprint: Blueprint | null = null

    this.selectedHelmId = -1;
    var blueprint = this.findBlueprintByLevel(d.design.helmId);
    this.selectHelmLevels = [];
    if (blueprint != null) {
      for (var i = 0; i < blueprint.levels.length; i++) {
        if (!this.ownedFilter || this.checkLevelOwnership(blueprint.levels[i].id))
          this.selectHelmLevels.push({ id: blueprint.levels[i].id, name: $localize`COMMON_LEVEL` + " " + blueprint.levels[i].level });
      }
      this.selectedHelmId = blueprint.id;
    }
    this.selectedChestId = -1;
    var blueprint = this.findBlueprintByLevel(d.design.chestId);
    this.selectChestLevels = [];
    if (blueprint != null) {
      for (var i = 0; i < blueprint.levels.length; i++) {
        if (!this.ownedFilter || this.checkLevelOwnership(blueprint.levels[i].id))
          this.selectChestLevels.push({ id: blueprint.levels[i].id, name: $localize`COMMON_LEVEL` + " " + blueprint.levels[i].level });
      }
      this.selectedChestId = blueprint.id;
    }
    this.selectedBootsId = -1;
    var blueprint = this.findBlueprintByLevel(d.design.bootsId);
    this.selectBootsLevels = [];
    if (blueprint != null) {
      for (var i = 0; i < blueprint.levels.length; i++) {
        if (!this.ownedFilter || this.checkLevelOwnership(blueprint.levels[i].id))
          this.selectBootsLevels.push({ id: blueprint.levels[i].id, name: $localize`COMMON_LEVEL` + " " + blueprint.levels[i].level });
      }
      this.selectedBootsId = blueprint.id;
    }
    this.selectedEquipId = -1;
    var blueprint = this.findBlueprintByLevel(d.design.equipmentId);
    this.selectEquipLevels = [];
    if (blueprint != null) {
      for (var i = 0; i < blueprint.levels.length; i++) {
        if (!this.ownedFilter || this.checkLevelOwnership(blueprint.levels[i].id))
          this.selectEquipLevels.push({ id: blueprint.levels[i].id, name: $localize`COMMON_LEVEL` + " " + blueprint.levels[i].level });
      }
      this.selectedEquipId = blueprint.id;
    }

    this.selectedHandOneAId = -1;
    var blueprint = this.findBlueprintByLevel(d.design.hand1AId);
    this.selectHandOneALevels = [];
    if (blueprint != null) {
      for (var i = 0; i < blueprint.levels.length; i++) {
        if (!this.ownedFilter || this.checkLevelOwnership(blueprint.levels[i].id))
          this.selectHandOneALevels.push({ id: blueprint.levels[i].id, name: $localize`COMMON_LEVEL` + " " + blueprint.levels[i].level });
        if ((blueprint.gearPositionFLagActive & 16) > 0) {
          this.selectHandTwoALevels = [];
          this.selectedHandTwoAId = -1;
          this.handTwoBlockedA = true;
          this.selectedDesign!.design!.hand2AId = null
        }
      }
      this.selectedHandOneAId = blueprint.id;
    }
    this.selectedHandTwoAId = -1;
    var blueprint = this.findBlueprintByLevel(d.design.hand2AId);
    this.selectHandTwoALevels = [];
    if (blueprint != null) {
      for (var i = 0; i < blueprint.levels.length; i++) {
        if (!this.ownedFilter || this.checkLevelOwnership(blueprint.levels[i].id))
          this.selectHandTwoALevels.push({ id: blueprint.levels[i].id, name: $localize`COMMON_LEVEL` + " " + blueprint.levels[i].level });
      }
      this.selectedHandTwoAId = blueprint.id;
    }
    this.selectedBeltAId = -1;
    var blueprint = this.findBlueprintByLevel(d.design.beltAId);
    this.selectBeltALevels = [];
    if (blueprint != null) {
      for (var i = 0; i < blueprint.levels.length; i++) {
        if (!this.ownedFilter || this.checkLevelOwnership(blueprint.levels[i].id))
          this.selectBeltALevels.push({ id: blueprint.levels[i].id, name: $localize`COMMON_LEVEL` + " " + blueprint.levels[i].level });
      }
      this.selectedBeltAId = blueprint.id;
    }
    this.selectedBackAId = -1;
    var blueprint = this.findBlueprintByLevel(d.design.backAId);
    this.selectBackALevels = [];
    if (blueprint != null) {
      for (var i = 0; i < blueprint.levels.length; i++) {
        if (!this.ownedFilter || this.checkLevelOwnership(blueprint.levels[i].id))
          this.selectBackALevels.push({ id: blueprint.levels[i].id, name: $localize`COMMON_LEVEL` + " " + blueprint.levels[i].level });
      }
      this.selectedBackAId = blueprint.id;
    }

    //TODO B DESIGN
    this.selectedHandOneBId = -1;
    var blueprint = this.findBlueprintByLevel(d.design.hand1BId);
    this.selectHandOneBLevels = [];
    if (blueprint != null) {
      for (var i = 0; i < blueprint.levels.length; i++) {
        if (!this.ownedFilter || this.checkLevelOwnership(blueprint.levels[i].id))
          this.selectHandOneBLevels.push({ id: blueprint.levels[i].id, name: $localize`COMMON_LEVEL` + " " + blueprint.levels[i].level });
        if ((blueprint.gearPositionFLagActive & 16) > 0) {
          this.selectHandTwoBLevels = [];
          this.selectedDesign!.design!.hand2BId = null
        }
      }
      this.selectedHandOneBId = blueprint.id;
    }

    this.selectedHandTwoBId = -1;
    var blueprint = this.findBlueprintByLevel(d.design.hand2BId);
    this.selectHandTwoBLevels = [];
    if (blueprint != null) {
      for (var i = 0; i < blueprint.levels.length; i++) {
        if (!this.ownedFilter || this.checkLevelOwnership(blueprint.levels[i].id))
          this.selectHandTwoBLevels.push({ id: blueprint.levels[i].id, name: $localize`COMMON_LEVEL` + " " + blueprint.levels[i].level });
      }
      this.selectedHandTwoBId = blueprint.id;
    }

    this.selectedBeltBId = -1;
    var blueprint = this.findBlueprintByLevel(d.design.beltBId);
    this.selectBeltBLevels = [];
    if (blueprint != null) {
      for (var i = 0; i < blueprint.levels.length; i++) {
        if (!this.ownedFilter || this.checkLevelOwnership(blueprint.levels[i].id))
          this.selectBeltBLevels.push({ id: blueprint.levels[i].id, name: $localize`COMMON_LEVEL` + " " + blueprint.levels[i].level });
      }
      this.selectedBeltBId = blueprint.id;
    }

    this.selectedBackBId = -1;
    var blueprint = this.findBlueprintByLevel(d.design.backBId);
    this.selectBackBLevels = [];
    if (blueprint != null) {
      for (var i = 0; i < blueprint.levels.length; i++) {
        if (!this.ownedFilter || this.checkLevelOwnership(blueprint.levels[i].id))
          this.selectBackBLevels.push({ id: blueprint.levels[i].id, name: $localize`COMMON_LEVEL` + " " + blueprint.levels[i].level });
      }
      this.selectedBackBId = blueprint.id;
    }
    this.runCalc("-");
  }

  checkLevelOwnership(levelId: number) {
    //TODO
    return true;
  }

  runCalc(layout: string) {
    this.weightMax = 0;
    this.weight = 0;
    if (this.selectedDesign == null || this.selectedDesign.design == null)
      return;
    var d: Design = this.selectedDesign.design;

    //check recruite selected
    if (!d.recruitTypeId || d.recruitTypeId == null || d.recruitTypeId < 0)
      return;

    var recruit = this.recruits.find(r => r.id == d.recruitTypeId);
    if (recruit == null)
      return;
    this.weightMax = recruit!.carryCapacity;

    var mount = this.mounts.find(m => m.id == d.mountTypeId)
    if (mount != null)
      this.weightMax += mount!.carryCapacity;

    var level = this.findBlueprintLevel(d.helmId);
    if (level != null)
      this.weight += level.weight;
    level = this.findBlueprintLevel(d.chestId);
    if (level != null)
      this.weight += level.weight;
    level = this.findBlueprintLevel(d.bootsId);
    if (level != null)
      this.weight += level.weight;
    level = this.findBlueprintLevel(d.equipmentId);
    if (level != null)
      this.weight += level.weight;

    level = this.findBlueprintLevel(d.hand1AId);
    if (level != null)
      this.weight += level.weight;
    level = this.findBlueprintLevel(d.hand2AId);
    if (level != null)
      this.weight += level.weight;
    level = this.findBlueprintLevel(d.beltAId);
    if (level != null)
      this.weight += level.weight;
    level = this.findBlueprintLevel(d.backAId);
    if (level != null)
      this.weight += level.weight;


    if (layout == "A" || (layout == "-" && this.selectedTab == "A")) {
      //TODO calc A-Layout
    }
    else {
      //TODO calc B-Layout
    }
  }

  validate() {
    //check design selected
    if (this.selectedDesign == null || this.selectedDesign.design == null)
      return true;
    var d: Design = this.selectedDesign.design;

    //check recruite selected
    if (!d.recruitTypeId || d.recruitTypeId == null || d.recruitTypeId < 0) {
      this.toastService.showWarningToast($localize`COMMON_WARNING`, $localize`DESIGN_WARNING_NO-RECRUIT`);
      return false;
    }
    var result = true;

    //check max weight is not exeeded
    if (this.weight > this.weightMax) {
      this.toastService.showWarningToast($localize`COMMON_WARNING`, $localize`DESIGN_WARNING_MAX-WEIGHT`);
      result = false;
    }

    //compare A to B
    var idsA: number[] = [];
    if (d.hand1AId != null && d.hand1AId >= 0)
      idsA.push(d.hand1AId);
    if (d.hand2AId != null && d.hand2AId >= 0)
      idsA.push(d.hand2AId);
    if (d.beltAId != null && d.beltAId >= 0)
      idsA.push(d.beltAId);
    if (d.backAId != null && d.backAId >= 0)
      idsA.push(d.backAId);
    if (idsA.length == 0)
      return result;


    var idsB: number[] = [];
    if (d.hand1BId != null && d.hand1BId >= 0)
      idsA.push(d.hand1BId);
    if (d.hand2BId != null && d.hand2BId >= 0)
      idsA.push(d.hand2BId);
    if (d.beltBId != null && d.beltBId >= 0)
      idsA.push(d.beltBId);
    if (d.backBId != null && d.backBId >= 0)
      idsA.push(d.backBId);

    if (idsB.length == 0)
      return result;

    if (idsA.length != idsB.length) {
      this.toastService.showWarningToast($localize`COMMON_WARNING`, $localize`DESIGN_WARNING_B-INCOMPLETE`);
      result = false;
    }

    var missing = idsA.filter(i => idsB.indexOf(i) < 0);
    if (missing.length > 0) {
      this.toastService.showWarningToast($localize`COMMON_WARNING`, $localize`DESIGN_WARNING_B-MISSMATCH`);
      result = false;
    }

    return result;
  }

  findBlueprintByLevel(levelId: number | null) {
    if (levelId == null)
      return null;
    for (var i = 0; i < this.blueprints.length; i++) {
      var level = this.blueprints[i].levels.find(l => l.id == levelId);
      if (level != null)
        return this.blueprints[i];
    }
    return null;
  }

  findBlueprintLevel(levelId: number | null) {
    if (levelId == null)
      return null;
    for (var i = 0; i < this.blueprints.length; i++) {
      var level = this.blueprints[i].levels.find(l => l.id == levelId);
      if (level != null)
        return level;
    }
    return null;
  }

  copyBlueprint(original: Blueprint) {
    var copy: Blueprint = {
      id: original.id, name: original.name, ability: original.ability, gearPositionFLagActive: original.gearPositionFLagActive,
      gearPositionFLagPassive: original.gearPositionFLagPassive, keyWords: original.keyWords, levels: [], nationFlag: original.nationFlag, rarerity: original.rarerity
    };
    return copy;
  }

  //====== GET DATA ======
  getStaticData() {
    this.blueprints = this.dataService.getBlueprints();
    this.recruits = this.dataService.getRecruits();
    this.mounts = this.dataService.getMounts();
    if (this.blueprints.length == 0 || this.recruits.length == 0 || this.mounts.length == 0) {
      //load static blueprints data if not present in service yet
      this.spinnerService.addToLoading();
      this.itemService.getBlueprintsData(false).subscribe((result: DesignStaticData) => {
        this.blueprints = result.blueprints;

        this.recruits = result.recruits;
        //TODO TRANSLATE ON RECIVE

        this.mounts = result.mounts;
        //TODO TRANSLATE ON RECIVE
        this.mounts.unshift({ id: -1, baseHitpoints: 0, baseSpeed: 0, carryCapacity: 0, flagPosition: 0, name: "-", nationFlag: 31, recruitingTicks: 0, size: 0 });

        this.buildSelectData();
        this.dataService.setBlueprints(this.blueprints);
        this.dataService.setRecruits(this.recruits);
        this.dataService.setMounts(this.mounts);
        this.spinnerService.removeFromLoading();
      }, error => {
        this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
        this.spinnerService.removeFromLoading();
      });
    }
    else
      this.buildSelectData();
    this.getDesigns()
  }

  getDesigns() {
    this.spinnerService.addToLoading();
    this.designs = [];
    this.itemService.getDesigns().subscribe((result: DesignMinimal[]) => {
      this.designs = result;
      this.spinnerService.removeFromLoading();
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.spinnerService.removeFromLoading();
    });
  }

  getDesign(d: DesignMinimal) {
    this.spinnerService.addToLoading();
    this.itemService.getDesign(d.id).subscribe((result: Design) => {
      this.spinnerService.removeFromLoading();
      d.design = result;      //TODO FILL SELECTS
      this.bindDesign(d);
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.spinnerService.removeFromLoading();
    });
  }

  saveDesign() {
    if (this.selectedDesign == null || this.selectedDesign.design == null || !this.validate())
      return;
    this.spinnerService.addToLoading();

    this.itemService.saveDesign(this.selectedDesign.design).subscribe((result: number) => {
      this.spinnerService.removeFromLoading();
      this.toastService.showSuccessToast($localize`COMMON_SUCCESS`, $localize`SAVE_DESIGN_SUCCESS`);
      this.selectedDesign!.design!.id = result;
      this.changedMade = false;
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.spinnerService.removeFromLoading();
    });

  }
}
