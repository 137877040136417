<div class="spinner-outer">
</div>
<div class="spinner">
  <div class="spinner-content">
    <span class="loader"></span>
  </div>
</div>




