<table style="margin: 0 5px 5px 5px;">
  <tr *ngFor="let line of scienceStructure; let j = index">
    <td *ngFor="let field of line"
        [attr.colspan]="field && field.type !== null && field.type < 1 ? '1' : '3'"
        style="width: 30px; height: 30px;">

      <!--{{field.type}}-->
      <div *ngIf="field.type == 0" style="width: 100%; height: 100%; margin: -6px -2px 0px -2px; position: relative; }">
        <span *ngIf="field.up"
              [ngStyle]="{'background-color': field.active ? 'black' : 'gray' }"
              style="width: 20%; height: 70%; top: 0; position: absolute; left: 50%; display: block;"></span>
        <span *ngIf="field.left"
              [ngStyle]="{'background-color': field.active ? 'black' : 'gray' }"
              style="width: 70%; height: 20%; top: 50%; position: absolute; left: 0; display: block;"></span>
        <span *ngIf="field.down"
              [ngStyle]="{'background-color': field.active ? 'black' : 'gray' }"
              style="width: 20%; height: 70%; top: 50%; position: absolute; left: 50%; display: block;"></span>
        <span *ngIf="field.right"
              [ngStyle]="{'background-color': field.active ? 'black' : 'gray' }"
              style="width: 70%; height: 20%; top: 50%; position: absolute; left: 50%; display: block;"></span>
      </div>
      <div *ngIf="field.type == 1 &&field && field.scienceSpot !== null"
           [ngStyle]="{'color': field.active ? 'black' : 'gray' }"
           style="height: 100%; border-top: solid black 2px; border-left: solid black 2px; border-right: solid black 2px;"
           (click)="clickSpot(field.scienceSpot)"
           data-bs-toggle="modal"
           data-bs-target="#fieldActionModal">
        <span>{{getOutput(field.scienceSpot,'name')}}</span>
      </div>
      <div *ngIf="field.type == 2"
           [ngStyle]="{'color': field.active ? 'black' : 'gray' }"
           style="height: 100%; border-bottom: solid black 2px; border-left: solid black 2px; border-right: solid black 2px;"
           (click)="clickSpot(field.scienceSpot)"
           data-bs-toggle="modal"
           data-bs-target="#fieldActionModal">
        <span>{{getOutput(field.scienceSpot,'details')}}</span>
      </div>
    </td>
  </tr>
</table>


<div class="modal fade" id="fieldActionModal" tabindex="-1" aria-labelledby="fieldActionModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="fieldActionModalLabel" i18n>SCIENCE_INFO</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">

        <div *ngIf="selectedSpot != null">
          <h4>{{selectedSpot.name}}</h4>
          <span>{{selectedSpot.description}}</span>

          <!--TODO EFFECTS-->
          <div class="row" style="padding: 0 20px 0 8px;">
            <div class="col-sm-6" *ngIf="selectedSpot.scienceLevelNow">
              <span i18n>SCIENCE_EFFECT_NOW</span>
              <ul class="list-group" *ngIf="selectedSpot.scienceLevelNow.effectData.length > 0">
                <li *ngFor="let effectNow of selectedSpot.scienceLevelNow.effectData" class="list-group-item d-flex justify-content-between align-items-center" style="padding: 3px 6px;">
                  {{getTranslation(effectNow.name, 'effectName')}}
                  <span class="badge bg-primary rounded-pill">{{effectNow.value}}</span>
                </li>
              </ul>
            </div>
            <div class="col-sm-6" *ngIf="selectedSpot.scienceLevelNext">
              <span i18n>SCIENCE_EFFECT_NEXT</span>
              <ul class="list-group" *ngIf="selectedSpot.scienceLevelNext.effectData.length > 0">
                <li *ngFor="let effectNext of selectedSpot.scienceLevelNext.effectData" class="list-group-item d-flex justify-content-between align-items-center" style="padding: 3px 6px;">
                  {{getTranslation(effectNext.name, 'effectName')}}
                  <span class="badge bg-primary rounded-pill">{{effectNext.value}}</span>
                </li>
              </ul>
            </div>
          </div>



          <!--TODO REQUIREMENTS-->
          <div class="row" *ngIf="selectedSpot.requirements.length > 0" style="padding: 0 20px 0 8px;">
            <div class="col-sm-6">
              <span i18n>REQUIREMENTS</span>
              <ul class="list-group" style="font-weight: bold;">
                <li class="list-group-item d-flex justify-content-between align-items-center">
                  <ul class="list-group">
                    <li *ngFor="let req of selectedSpot.requirements; let k = index" class="justify-content-between align-items-center">
                      <span *ngIf="!req.fullfilled" style=" color: darkred ">{{getOutput(req.spot,'name')}} {{req.requiredLevel}}</span>
                      <span *ngIf="req.fullfilled" style=" color: black ">{{getOutput(req.spot,'name')}} {{req.requiredLevel}}</span>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>


          <div class="row" *ngIf="selectedSpot.active && selectedSpot.scienceLevelNext != null">
            <div class=" col col-sm-3" style="padding-top: 12px;">
              <span [innerHTML]="clockIcon"></span><span>{{selectedSpot.scienceLevelNext.researchTicks!}}</span>
            </div>
            <div class="col-sm-9" style="padding: 8px 40px 0 12px;">
              <button class="btn btn-success"
                      style="width: 100%"
                      data-bs-dismiss="modal"
                      (click)="startResearch()"
                      [disabled]="!selectedSpot || !selectedSpot.scienceLevelNext" i18n>
                START_SCIENCE
              </button>
            </div>
          </div>
        </div>



      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" i18n>COMMON_CLOSE</button>
      </div>
    </div>
  </div>
</div>
