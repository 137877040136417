<div style="width: 110px; float: left; padding-left: 4px;">
  <div>
    <span i18n>MAP_ZOOM</span><br />
    <button type="button" class="btn btn-secondary" [disabled]="zoom != 1"
            style="margin-left: 10px; font-size: 16px; width: 26px; height: 26px; font-weight: bold; margin-right: 8px; "
            (click)="zoomMap('-')">
      <div [innerHTML]="getIconByName('minusIcon')" style="font-size: 26px; margin: -23px;"></div>
    </button>
    <button type="button" class="btn btn-secondary" [disabled]="zoom == 1"
            style="margin-left: 2px; font-size: 16px; width: 26px; height: 26px; font-weight: bold; "
            (click)="zoomMap('+')">
      <div [innerHTML]="getIconByName('plusIcon')" style="font-size: 26px; margin: -22px;"></div>
    </button>
  </div>

  <div>
    <span i18n>MAP_MOVE</span><br />
    <div>
      <button type="button" class="btn btn-secondary"
              style="margin-left: 26px; width: 26px; height: 26px;"
              (click)="moveMap('up')">
        <div [innerHTML]="getIconByName('upIcon')" style="font-size: 26px; margin: -20px;"></div>
      </button>
    </div>
    <div>
      <button type="button" class="btn btn-secondary"
              style=" width: 26px; height: 26px;"
              (click)="moveMap('left')">
        <div [innerHTML]="getIconByName('leftIcon')" style="font-size: 26px; margin: -20px;"></div>
      </button>
      <button type="button" class="btn btn-secondary"
              style="margin-left: 26px; width: 26px; height: 26px;"
              (click)="moveMap('right')">
        <div [innerHTML]="getIconByName('rightIcon')" style="font-size: 26px; margin: -20px;"></div>
      </button>
    </div>
    <div>
      <button type="button" class="btn btn-secondary"
              style="margin-left: 26px; width: 26px; height: 26px;"
              (click)="moveMap('down')">
        <div [innerHTML]="getIconByName('downIcon')" style="font-size: 26px; margin: -20px;"></div>
      </button>
    </div>
  </div>

  <div>
    <span i18n>MAP_GOTO</span>
    <div style="height: 30px;">
      <div style="width: 12px; float: left; padding-left: 2px;">
        X:
      </div>
      <div style="width: 65px; float: left;">
        <input [(ngModel)]="gotoX" type="number" id="amount" name="x" style="width: 64px; margin-left: 6px;" />
      </div>
    </div>
    <div>
      <div style="width: 12px; float: left; padding-left: 2px;">
        Y:
      </div>
      <div style="width: 65px; float: left;">
        <input [(ngModel)]="gotoY" type="number" id="amount" name="y" style="width: 64px; margin-left: 6px;" />
      </div>
    </div>
    <button type="button" class="btn btn-secondary"
            style="margin-left: 18px; width: 26px; height: 26px;"
            (click)="goto()">
      <div [innerHTML]="getIconByName('gotoIcon')" style="font-size: 26px; margin: -20px;"></div>
    </button>
  </div>
</div>

<div style="width: 700px; float: left;">
  <table style="border-spacing: 2px; border-collapse: separate;">
    <tr>
      <td></td>
      <td *ngFor="let field of map[0]; let i = index"
          style="text-align: center;">
        {{x-(zoom*4)+i}}
      </td>
    </tr>
    <tr *ngFor="let line of map; let j = index">
      <td>{{y+(zoom*4)-j}}</td>
      <td *ngFor="let field of line"
          [ngStyle]="{'width': zoom == 1 ? '68px' : '36px', 'height': zoom == 1 ? '68px' : '36px' }"
          [ngClass]="field.classes"
          (click)="clickField(field)"
          data-bs-toggle="modal" data-bs-target="#fieldActionModal">
        <span [innerHTML]="getIconByName('villageIcon')"
              *ngIf="field.villageId && field.hasTown"
              [ngStyle]="{'font-size': zoom == 1 ? '38px' : '17px', 'margin-left': zoom == 1 ? '4px' : '2px' }"></span>
      </td>
      <td>{{y+(zoom*4)-j}}</td>
    </tr>
    <tr>
      <td></td>
      <td *ngFor="let field of map[0]; let i = index"
          style="text-align: center;">
        {{x-(zoom*4)+i}}
      </td>
    </tr>
  </table>
</div>

<!-- Modal -->
<div class="modal fade" id="fieldActionModal" tabindex="-1" aria-labelledby="fieldActionModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="fieldActionModalLabel"
            *ngIf="selectedMapField != null && selectedMapField.fieldType != null" i18n>
          MAP_FIELD_INFO - {{getTranslation('fieldType', selectedMapField!.fieldType.name)}}
        </h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" *ngIf="selectedMapField != null">
        <div *ngIf="selectedMapField!.fieldType != null">
          <div class="row">
            <div class=" col col-sm-3">
              <span [innerHTML]="getIconByName('woodIcon')"></span><span [ngStyle]="{'color': selectedMapField!.fieldType.woodProduction < 100 ? 'red' : 'green' }">{{selectedMapField!.fieldType.woodProduction}}%</span>
            </div>
            <div class=" col col-sm-3">
              <span [innerHTML]="getIconByName('stoneIcon')"></span><span [ngStyle]="{'color': selectedMapField!.fieldType.stoneProduction < 100 ? 'red' : 'green' }">{{selectedMapField!.fieldType.stoneProduction}}%</span>
            </div>
            <div class=" col col-sm-3">
              <span [innerHTML]="getIconByName('ironIcon')"></span><span [ngStyle]="{'color': selectedMapField!.fieldType.ironProduction < 100 ? 'red' : 'green' }">{{selectedMapField!.fieldType.ironProduction}}%</span>
            </div>
            <div class=" col col-sm-3">
              <span [innerHTML]="getIconByName('foodIcon')"></span><span [ngStyle]="{'color': selectedMapField!.fieldType.foodProduction < 100 ? 'red' : 'green' }">{{selectedMapField!.fieldType.foodProduction}}%</span>
            </div>
          </div>
          <div class="row">
            <div *ngFor="let nation of nations" class="col-sm-2" style="">
              <button *ngIf="getNationState(selectedMapField!.fieldType, nation) == 1" type="button" class="btn btn-primary" [disabled]="true"
                      style="padding: 2px; font-size: 12px; width: 60px; font-weight: bold;">
                {{getTranslation('nation', nation.name)}}
              </button>
              <button *ngIf="getNationState(selectedMapField!.fieldType, nation) == 2" type="button" class="btn btn-success" [disabled]="true"
                      style="padding: 2px; font-size: 12px; width: 60px; font-weight: bold;">
                {{getTranslation('nation', nation.name)}}
              </button>
              <button *ngIf="getNationState(selectedMapField!.fieldType, nation) == 3" type="button" class="btn btn-secondary" [disabled]="true"
                      style="padding: 2px; font-size: 12px; width: 60px; font-weight: bold;">
                {{getTranslation('nation', nation.name)}}
              </button>
              <button *ngIf="getNationState(selectedMapField!.fieldType, nation) == 4" type="button" class="btn btn-danger" [disabled]="true"
                      style="padding: 2px; font-size: 12px; width: 60px; font-weight: bold;">
                {{getTranslation('nation', nation.name)}}
              </button>
            </div>
          </div>

          <div class="row">
            <div class="col col-sm-4" i18n>VILLAGE</div>
            <div class="col col-sm-8" *ngIf="selectedMapField != null && selectedMapField!.villageId != null">{{selectedMapField!.villageName}}</div>
            <div class="col col-sm-8" *ngIf="selectedMapField == null || selectedMapField!.villageId == null">-</div>
          </div>
          <div class="row">
            <div class="col col-sm-4" i18n>PLAYER</div>
            <div class="col col-sm-8" *ngIf="selectedMapField != null && selectedMapField!.playerId != null">{{selectedMapField!.playerName}}</div>
            <div class="col col-sm-8" *ngIf="selectedMapField == null || selectedMapField!.playerId == null">-</div>
          </div>
          <div class="row">
            <div class="col col-sm-4" i18n>ALLIANCE</div>
            <div class="col col-sm-8" *ngIf="selectedMapField != null && selectedMapField!.allianceId != null">{{selectedMapField!.allianceName}}</div>
            <div class="col col-sm-8" *ngIf="selectedMapField == null || selectedMapField!.allianceId == null">-</div>
          </div>

          <div class="row" style="margin-top:8px">
            <div class="col col-sm-2">
              <button type="button" class="btn btn-secondary" [disabled]="getActionButtons('defend')"
                      style="width: 50px; height: 50px; "
                      (click)="runActionButton('defend')"
                      data-bs-dismiss="modal">
                <div [innerHTML]="getIconByName('defendIcon')" style="font-size: 40px; margin: -32px; }"></div>
              </button>
            </div>
            <div class="col col-sm-2">
              <button type="button" class="col-sm-2 btn btn-secondary" [disabled]="getActionButtons('attack')"
                      style="width: 50px; height: 50px; "
                      (click)="runActionButton('attack')"
                      data-bs-dismiss="modal">
                <div [innerHTML]="getIconByName('attackIcon')" style="font-size: 40px; margin: -32px; }"></div>
              </button>
            </div>
            <div class="col col-sm-2">
              <button type="button" class="col-sm-2 btn btn-secondary" [disabled]="getActionButtons('special')"
                      style="width: 50px; height: 50px; "
                      (click)="runActionButton('special')"
                      data-bs-dismiss="modal">
                <div [innerHTML]="getIconByName('specialIcon')" style="font-size: 40px; margin: -32px; }"></div>
              </button>
            </div>
            <div class="col col-sm-2">
              <button type="button" class="col-sm-2 btn btn-secondary" [disabled]="getActionButtons('pve')"
                      style="width: 50px; height: 50px; "
                      (click)="runActionButton('pve')"
                      data-bs-dismiss="modal">
                <div [innerHTML]="getIconByName('pveIcon')" style="font-size: 40px; margin: -32px; }"></div>
              </button>
            </div>
            <div class="col col-sm-2">
              <button type="button" class="col-sm-2 btn btn-secondary" [disabled]="getActionButtons('trade')"
                      style="width: 50px; height: 50px; "
                      (click)="runActionButton('trade')"
                      data-bs-dismiss="modal">
                <div [innerHTML]="getIconByName('tradeIcon')" style="font-size: 40px; margin: -32px; }"></div>
              </button>
            </div>
            <div class="col col-sm-2">
              <button type="button" class="col-sm-2 btn btn-secondary" [disabled]="getActionButtons('bpo')"
                      style="width: 50px; height: 50px; "
                      (click)="runActionButton('bpo')"
                      data-bs-dismiss="modal">
                <div [innerHTML]="getIconByName('bpoIcon')" style="font-size: 40px; margin: -32px; }"></div>
              </button>
            </div>

          </div>

        </div>
      </div>
      <div class="modal-body" *ngIf="selectedMapField == null">

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" i18n>COMMON_CLOSE</button>
      </div>
    </div>
  </div>
</div>
