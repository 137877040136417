export const environment = {
  production: false,
  API_URL: 'https://dzo.vexlon.eu/api/',
  lifeTime: 1,//in h?
  serverId: 1,
  tickLength: 10, //durration of a tick in seconds
  reloadTicks: 10, //number of tick until next automated relaod (message/events)
  reloadDelay: 2, //delay of a relady within a tick: tickDuration / reloadDelay = delayTime (ses)
  staticDataTime: 48 //time thathas to be exceeded befor static data gets romoved from lokal storage
};
