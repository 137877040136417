<ul class="nav nav-tabs" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <button class="nav-link active" id="outer-tab" data-bs-toggle="tab" data-bs-target="#outer-tab-pane" type="button" role="tab" aria-controls="outer-tab-pane" aria-selected="true" i18n>VILLAGE_OUTER</button>
  </li>
  <li class="nav-item" role="presentation">
    <button class="nav-link" id="inner-tab" data-bs-toggle="tab" data-bs-target="#inner-tab-pane" type="button" role="tab" aria-controls="inner-tab-pane" aria-selected="false" i18n>VILLAGE_INNER</button>
  </li>
</ul>
<div class="tab-content" id="myTabContent">
  <div class="tab-pane fade show active " id="outer-tab-pane" role="tabpanel" aria-labelledby="outer-tab" tabindex="0">
    <!--<div class="row">
    </div>-->
    <table style="border-spacing: 2px; border-collapse: separate;">
      <tr>
        <td></td>
        <td *ngFor="let field of map[0]; let i = index"
            style="text-align: center;">
          {{this.villageResult.village.x-3+i}}
        </td>
      </tr>
      <tr *ngFor="let line of map; let j = index">
        <td>{{this.villageResult.village.y+3-j}}</td>




        <td *ngFor="let field of line"
            style="width: 60px; height: 60px;"
            [ngClass]="field.classes"
            (click)="clickField(field, 2)"
            data-bs-toggle="modal" data-bs-target="#fieldActionModal">
          <!--data-bs-modalField="field"-->
          <span [innerHTML]="villageIcon"
                *ngIf="field.villageId && field.hasTown"
                style="font-size: 25px; margin-left: 8px;"></span>
          <!--{{field.x}}/{{field.y}}-->
        </td>




        <td>{{this.villageResult.village.y+3-j}}</td>
      </tr>
      <tr>
        <td></td>
        <td *ngFor="let field of map[0]; let i = index"
            style="text-align: center;">
          {{this.villageResult.village.x-3+i}}
        </td>
      </tr>
    </table>
  </div>
  <div class="tab-pane fade" id="inner-tab-pane" role="tabpanel" aria-labelledby="inner-tab" tabindex="1">
    <div class="row">
      <div class=" col col-sm-4">
        <!--spacer-->
      </div>

      <div class=" col col-sm-4">
        <div class="row" style="padding-top: 12px; padding-bottom: 12px;">
          <button class="btn btn-light" (click)="clickField(map[3][3], 1)" data-bs-toggle="modal" data-bs-target="#fieldActionModal"
                  style="width: 160px; margin: 0px auto;" i18n>
            MAINBUILDING
          </button>
        </div>
        <div class="row" style="padding-bottom: 12px;">
          <button class="btn btn-light" (click)="clickField(map[3][3], 4)" data-bs-toggle="modal" data-bs-target="#fieldActionModal"
                  style="width: 160px; margin: 0px auto;" i18n>
            TOWN
          </button>
        </div>
        <div class="row" style="padding-bottom: 12px;">
          <button class="btn btn-light" (click)="clickField(map[3][3], 8)" data-bs-toggle="modal" data-bs-target="#fieldActionModal"
                  style="width: 160px; margin: 0px auto;" i18n>
            DEFENCE
          </button>
        </div>
        <div class="row" style="padding-bottom: 12px;">
          <button class="btn btn-light" (click)="clickField(map[3][3], 16)" data-bs-toggle="modal" data-bs-target="#fieldActionModal"
                  style="width: 160px; margin: 0px auto;" i18n>
            WALL
          </button>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Modal -->
<div class="modal fade" id="fieldActionModal" tabindex="-1" aria-labelledby="fieldActionModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="fieldActionModalLabel" i18n>VILLAGE_FIELD_INFO</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div *ngIf="selectedMapFieldFieldType != null">
          {{fileTypeName}}
          <div class="row">
            <div class=" col col-sm-3">
              <span [innerHTML]="woodIcon"></span><span [ngStyle]="{'color': selectedMapFieldFieldType.woodProduction < 100 ? 'red' : 'green' }">{{selectedMapFieldFieldType.woodProduction}}%</span>
            </div>
            <div class=" col col-sm-3">
              <span [innerHTML]="stoneIcon"></span><span [ngStyle]="{'color': selectedMapFieldFieldType.stoneProduction < 100 ? 'red' : 'green' }">{{selectedMapFieldFieldType.stoneProduction}}%</span>
            </div>
            <div class=" col col-sm-3">
              <span [innerHTML]="ironIcon"></span><span [ngStyle]="{'color': selectedMapFieldFieldType.ironProduction < 100 ? 'red' : 'green' }">{{selectedMapFieldFieldType.ironProduction}}%</span>
            </div>
            <div class=" col col-sm-3">
              <span [innerHTML]="foodIcon"></span><span [ngStyle]="{'color': selectedMapFieldFieldType.foodProduction < 100 ? 'red' : 'green' }">{{selectedMapFieldFieldType.foodProduction}}%</span>
            </div>
          </div>
          <div class="row">

            <div *ngFor="let nation of fieldNations" class="col-sm-1" style="">
              <button *ngIf="nation.state == 1" type="button" class="btn btn-primary" [disabled]="true"
                      style="padding: 2px; font-size: 12px; width: 60px; font-weight: bold;">
                {{nation.name}}
              </button>
              <button *ngIf="nation.state == 2" type="button" class="btn btn-success" [disabled]="true"
                      style="padding: 2px; font-size: 12px; width: 60px; font-weight: bold;">
                {{nation.name}}
              </button>
              <button *ngIf="nation.state == 3" type="button" class="btn btn-secondary" [disabled]="true"
                      style="padding: 2px; font-size: 12px; width: 60px; font-weight: bold;">
                {{nation.name}}
              </button>
              <button *ngIf="nation.state == 4" type="button" class="btn btn-danger" [disabled]="true"
                      style="padding: 2px; font-size: 12px; width: 60px; font-weight: bold;">
                {{nation.name}}
              </button>
            </div>


          </div>
        </div>
        <!--CLAIME-->
        <div *ngIf="modalMapFieldType == 2">
          <div class="row">
            <span i18n>CLAIM_FIELD_DESCRIPTION</span>
          </div>
          <div class="row">
            <button class="btn btn-primary" (click)="claimMapField()" data-bs-dismiss="modal"
                    style="width: 160px; margin: 0px auto;" i18n>
              CLAIME_FIELD
            </button>
          </div>
        </div>


        <!--BUILD-->
        <div *ngIf="modalMapFieldType == 3">
          <div *ngFor="let slot of slots" class="card" style="margin-top: 10px;">
            <div (click)="openSlot(slot)" class="card-header">
              <span>{{slot.slotTypeName}} - </span>
              <span *ngIf="slot.buildingLevelAndType === null" i18n>COMMON_EMPTY</span>
              <span *ngIf="slot.buildingLevelAndType != null">{{slot.buildingLevelAndType.name}} (</span>
              <span *ngIf="slot.buildingLevelAndType != null" i18n>COMMON_LEVEL</span>
              <span *ngIf="slot.buildingLevelAndType != null"> {{slot.buildingLevelAndType.levelNow}})</span>
              <span *ngIf="slot.underConstruction != null && slot.underConstruction > 0"> => </span>
              <span *ngIf="slot.underConstruction != null && slot.underConstruction > 0" i18n>COMMON_LEVEL</span>
              <span *ngIf="slot.underConstruction != null && slot.underConstruction > 0 && slot.buildingLevelAndType != null && slot.buildingLevelAndType.levelNow != null"> {{slot.buildingLevelAndType.levelNow + slot.underConstruction}}</span>
            </div>
            <!--NEW-->
            <div *ngIf="slot.buildingLevelAndType === null && slot.isOpen" class="card-body">
              <div class="form-check form-switch" style="padding-bottom: 14px;">
                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" [(ngModel)]="showAllBuildings" (ngModelChange)="buildingsForDisplayChange(slot)">
                <label class="form-check-label" for="flexSwitchCheckDefault" i18n>SHOW_ALL_BUILDINGS</label>
              </div>
              <div class="row">
                <div *ngFor="let build of buildingsForDisplay" class="col-sm-6" style="padding: 0;">
                  <div class="card" style="margin: 4px;">
                    <div class="card-header" style="padding: 2px 0 4px 0; text-align: center;">
                      {{build.name}}
                    </div>
                    <div class="card-body" style="padding: 4px 0 10px 26px;">
                      <div class="row">
                        {{build.description}}
                      </div>

                      <!--EFFECTS-->
                      <div class="row" style="padding: 0 20px 0 8px;">
                        <ul class="list-group" *ngIf="build.effectDataNext.length > 0">
                          <li *ngFor="let effectNext of build.effectDataNext" class="list-group-item d-flex justify-content-between align-items-center" style="padding: 3px 6px;">
                            {{effectNext.name}}
                            <span class="badge bg-primary rounded-pill">{{effectNext.value}}</span>
                          </li>
                        </ul>
                      </div>

                      <!--BLOCKED-->
                      <div class="row" *ngIf="build.errorGroups.length > 0" style="margin-top:10px">
                        <ul class="list-group" style="padding: 0 32px 0 8px; font-weight: bold;">
                          <li *ngFor="let eg of build.errorGroups; let k = index" class="list-group-item d-flex justify-content-between align-items-center">
                            <!--<span *ngFor="let em of eg.messages">{{em}}</span>-->
                            <ul class="list-group">
                              <li *ngFor="let em of eg.messages" class="justify-content-between align-items-center">
                                <span style=" color: darkred ">{{em}}</span>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>

                      <!--RESOURCES-->
                      <div class="row">
                        <div class=" col col-sm-3">
                          <span [innerHTML]="woodIcon"></span><span [ngStyle]="{'color': resData != null && build.costWood > resData.wood ? 'red' : 'black' }">{{build.costWood}}</span>
                        </div>
                        <div class=" col col-sm-3">
                          <span [innerHTML]="stoneIcon"></span><span [ngStyle]="{'color': resData != null && build.costStone > resData.stone ? 'red' : 'black' }">{{build.costStone}}</span>
                        </div>
                        <div class=" col col-sm-3">
                          <span [innerHTML]="ironIcon"></span><span [ngStyle]="{'color': resData != null && build.costIron > resData.iron ? 'red' : 'black' }">{{build.costIron}}</span>
                        </div>
                        <div class=" col col-sm-3">
                          <span [innerHTML]="foodIcon"></span><span [ngStyle]="{'color': resData != null && build.costFood > resData.food ? 'red' : 'black' }">{{build.costFood}}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class=" col col-sm-3" style="padding-top: 12px;">
                          <span [innerHTML]="clockIcon"></span><span>{{build.buildingTicks}}</span>
                        </div>
                        <div class="col-sm-9" style="padding: 8px 40px 0 12px;">
                          <button class="btn btn-success"
                                  style="width: 100%"
                                  data-bs-dismiss="modal"
                                  *ngIf="build.nextLevelId!=null"
                                  (click)="constructBuilding(slot.slotId, build.nextLevelId)"
                                  [disabled]="!build.allowBuilding || resData == null || build.costWood > resData.wood || build.costStone > resData.stone|| build.costIron > resData.iron || build.costFood > resData.food" i18n>
                            CONSTRUCT_BUILDING
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--UPGRADE-->
            <div *ngIf="slot.buildingLevelAndType !== null && slot.isOpen" class="card-body">
              <div class="row">
                {{slot.buildingLevelAndType.description}}
              </div>

              <!--EFFECTS-->
              <div class="row" style="padding: 0 20px 0 8px;">
                <div class="col-sm-6">
                  <span i18n>BUILDING_EFFECT_NOW</span>
                  <ul class="list-group" *ngIf="slot.buildingLevelAndType.effectDataNow.length > 0">
                    <li *ngFor="let effectNow of slot.buildingLevelAndType.effectDataNow" class="list-group-item d-flex justify-content-between align-items-center" style="padding: 3px 6px;">
                      {{effectNow.name}}
                      <span class="badge bg-primary rounded-pill">{{effectNow.value}}</span>
                    </li>
                  </ul>
                </div>
                <div class="col-sm-6" *ngIf="slot.buildingLevelAndType.nextLevelId!=null">
                  <span i18n>BUILDING_EFFECT_NEXT</span>
                  <ul class="list-group" *ngIf="slot.buildingLevelAndType.effectDataNext.length > 0">
                    <li *ngFor="let effectNext of slot.buildingLevelAndType.effectDataNext" class="list-group-item d-flex justify-content-between align-items-center" style="padding: 3px 6px;">
                      {{effectNext.name}}
                      <span class="badge bg-primary rounded-pill">{{effectNext.value}}</span>
                    </li>
                  </ul>
                </div>
              </div>

              <!--BLOCKED-->
              <div class="row" *ngIf="slot.buildingLevelAndType.errorGroups.length > 0" style="margin-top:10px">
                <ul class="list-group" style="padding: 0 32px 0 8px; font-weight: bold;">
                  <li *ngFor="let eg of slot.buildingLevelAndType.errorGroups; let k = index" class="list-group-item d-flex justify-content-between align-items-center">
                    <!--<span *ngFor="let em of eg.messages">{{em}}</span>-->
                    <ul class="list-group">
                      <li *ngFor="let em of eg.messages" class="justify-content-between align-items-center">
                        <span style="color: darkred ">{{em}}</span>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>

              <!--RESOURCES-->
              <div class="row" *ngIf="slot.buildingLevelAndType.nextLevelId!=null">
                <div class=" col col-sm-3">
                  <span [innerHTML]="woodIcon"></span><span [ngStyle]="{'color': resData != null && slot.buildingLevelAndType.costWood > resData.wood ? 'red' : 'black' }">{{slot.buildingLevelAndType.costWood}}</span>
                </div>
                <div class=" col col-sm-3">
                  <span [innerHTML]="stoneIcon"></span><span [ngStyle]="{'color': resData != null && slot.buildingLevelAndType.costStone > resData.stone ? 'red' : 'black' }">{{slot.buildingLevelAndType.costStone}}</span>
                </div>
                <div class=" col col-sm-3">
                  <span [innerHTML]="ironIcon"></span><span [ngStyle]="{'color': resData != null && slot.buildingLevelAndType.costIron > resData.iron ? 'red' : 'black' }">{{slot.buildingLevelAndType.costIron}}</span>
                </div>
                <div class=" col col-sm-3">
                  <span [innerHTML]="foodIcon"></span><span [ngStyle]="{'color': resData != null && slot.buildingLevelAndType.costFood > resData.food ? 'red' : 'black' }">{{slot.buildingLevelAndType.costFood}}</span>
                </div>
              </div>
              <div class="row" *ngIf="slot.buildingLevelAndType.nextLevelId!=null">
                <div class=" col col-sm-3" style="padding-top: 12px;">
                  <span [innerHTML]="clockIcon"></span><span>{{slot.buildingLevelAndType.buildingTicks}}</span>
                </div>
                <div class="col-sm-9" style="padding: 8px 40px 0 12px;">
                  <button class="btn btn-success"
                          style="width: 100%"
                          data-bs-dismiss="modal"
                          (click)="constructBuilding(slot.slotId, slot.buildingLevelAndType.nextLevelId!)"
                          [disabled]="resData == null || slot.buildingLevelAndType.costWood > resData.wood || slot.buildingLevelAndType.costStone > resData.stone|| slot.buildingLevelAndType.costIron > resData.iron || slot.buildingLevelAndType.costFood > resData.food" i18n>
                    CONSTRUCT_BUILDING
                  </button>
                </div>
              </div>
            </div>

          </div>
        </div>

        <!--OTHER-->
        <div *ngIf="modalMapFieldType == 4">
          OTHER VILLAGE

        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" i18n>COMMON_CLOSE</button>
      </div>
    </div>
  </div>
</div>
