<p i18n>EVENTS</p>
<div>
  <span [innerHTML]="militaryIcon"></span>
  <span>{{militaryEvents.length}} </span>
  <span i18n>EVENTS_MILITARY</span>
</div>
<div>
  <span [innerHTML]="constractionIcon"></span>
  <span>{{constructionEvents.length}} </span>
  <span i18n>EVENTS_CONSTRUCTION</span>

  <!--<div *ngFor="let e of militaryEvents"
       style="padding-left:20px;">
    <span [innerHTML]="" *ngIf="e.isIncoming"></span>
    <span [innerHTML]="" *ngIf="!e.isIncoming"></span>
    <span>{{e.eventTick}} </span>
  </div>-->

</div>
<div>
  <span [innerHTML]="recruitIcon"></span>
  <span>{{recruitEvents.length}} </span>
  <span i18n>EVENTS_RECRUIT</span>
</div>
<div>
  <span [innerHTML]="scienceIcon"></span>
  <span>{{scienceEvents.length}} </span>
  <span i18n>EVENTS_SCIENCE</span>
</div>
<div>
  <span [innerHTML]="tradeIcon"></span>
  <span>{{tradeEvents.length}} </span>
  <span i18n>EVENTS_TRADE</span>
</div>
<div>
  <span [innerHTML]="otherIcon"></span>
  <span>{{otherEvents.length}} </span>
  <span i18n>EVENTS_OTHER</span>
</div>



