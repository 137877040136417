<div class="row" style="padding: 12px">
  <div *ngFor="let item of this.playerItems"
       class="float-left box"
       title="{{item.name}}"
       (click)="prepareModal(item)">

    <span *ngIf="item.ItemType != null && item.ItemType.boxType != null"
          [innerHTML]="boxIcon"
          class="icon"></span>
    <span *ngIf="item.ItemType != null && item.ItemType.resourcePackType != null"
          [innerHTML]="resPackIcon"
          class="icon"></span>
    <span *ngIf="item.ItemType != null && item.ItemType.blueprintPartType != null"
          [innerHTML]="partIcon"
          class="icon"></span>

    <span *ngIf="item.ItemType != null && item.ItemType.blueprintPartType != null"
          [innerHTML]="getIcon('partNumber', item.ItemType.partNumber)"
          class="number"></span>

    <span *ngIf="item.ItemType != null"
          [innerHTML]="getIcon('rarity', item.ItemType.rarity)"
          class="rarity"></span>

    <span *ngIf="item.ItemType != null"
          [innerHTML]="getIcon('secondary', item.ItemType.secondaryIcon)"
          class="secondary"></span>

    <span class="amount">{{item.amount}}</span>
  </div>
</div>

<!-- Modal -->
<div class="modal" id="actionModal" tabindex="-1" aria-labelledby="actionModal" aria-hidden="true"
     [ngStyle]="{'display': modalIsOpen ? 'block' : 'none' }">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="fieldActionModalLabel" i18n>ITEM_ACTION</h1>
        <button type="button" class="btn-close" (click)="closeModal()" aria-label="Close"></button>
      </div>
      <div class="modal-body" style="padding: 0;">
        <div class="row" style="padding: 12px;" *ngIf="selectedItem != null && selectedItem.ItemType != null && selectedItem.ItemType.boxType != null">
          <span i18n>TEXT_OPENBOX</span>
        </div>
        <div class="row" style="padding: 12px;" *ngIf="selectedItem != null && selectedItem.ItemType != null && selectedItem.ItemType.resourcePackType != null">
          <span i18n>TEXT_USE_RES_PACK</span>
        </div>
        <div class="row" style="padding: 12px;" *ngIf="selectedItem != null && selectedItem.ItemType != null && selectedItem.ItemType.blueprintPartType != null">
          <span i18n>TEXT_COMBINE_BP</span>
        </div>
        <div class="row" style="padding: 12px;" *ngIf="selectedItem != null && selectedItem.ItemType != null && selectedItem.ItemType.blueprintPartType == null">
          <div class="col col-lg-12" style="text-align: center;" *ngIf="selectedItem != null">
            <span i18n>ITEM_AMOUNT</span><br />
            <input [(ngModel)]="amount" type="number" id="amount" name="amount" min="1" max="{{selectedItem.amount}}" />
          </div>
        </div>
        <div class="row" style="padding: 12px;">
          <div class="col col-lg-6">
            <button *ngIf="selectedItem != null && selectedItem.ItemType != null && selectedItem.ItemType.boxType != null" class="btn btn-primary" (click)="openBox()" i18n>ACTION_OPEN_BOX</button>
            <button *ngIf="selectedItem != null && selectedItem.ItemType != null && selectedItem.ItemType.resourcePackType != null" class="btn btn-primary" (click)="useResourcePacks()" i18n>ACTION_OPEN_RESPACK</button>
            <button *ngIf="selectedItem != null && selectedItem.ItemType != null && selectedItem.ItemType.blueprintPartType != null" class="btn btn-primary" (click)="combineParts()" i18n>ACTION_COMBINE_BP</button>
          </div>
          <div class="col col-lg-6" style="text-align: right;">
            <button *ngIf="selectedItem != null && selectedItem.ItemType != null" class="btn btn-danger" (click)="sellItems()" i18n>ACTION_SELL_ITEM</button>
          </div>


        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeModal()" i18n>COMMON_CLOSE</button>
      </div>
    </div>
  </div>
</div>


<style>
  .row span.amount {
    position: absolute;
    top: 72px;
    left: 10px;
    font-size: 36px;
    font-weight: bold;
    color: blue;
    z-index: 2;
  }

  .row span.rarity {
    position: absolute;
    top: 0;
    left: 68px;
    font-size: 30px;
    z-index: 2;
  }

  .row span.number {
    position: absolute;
    top: 6px;
    left: 32px;
    font-size: 32px;
    z-index: 2;
    font-weight: bold;
  }

  .row span.secondary {
    position: absolute;
    top: 0;
    left: 5px;
    font-size: 30px;
    z-index: 2;
  }

  .row span.icon {
    position: absolute;
    top: -15px;
    left: 5px;
    font-size: 75px;
    opacity: 0.7;
    z-index: 1;
  }

  .row div.box {
    margin: 12px;
    width: 120px;
    height: 120px;
    position: relative;
  }
</style>
