import { Component, OnInit } from '@angular/core';
import { User } from '../../models/single/user';
import { AuthService } from '../../services/data/auth.service';
import { SpinnerService } from '../../services/spinner.service';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-reg',
  templateUrl: './reg.component.html',
  styleUrls: ['./reg.component.css']
})
export class RegComponent implements OnInit {
  user = new User();
  passwordConfirm = '';
  regMessage = '';
  constructor(private authService: AuthService,
    private toastService: ToastService,
    private spinnerService: SpinnerService) { }

  ngOnInit() {
    this.regMessage = '';
  }

  register(user: User) {
    if (user.password.length < 8) {
      this.regMessage = $localize`REGISTRATION_ERROR_PASSWORDLENGTH`;
      return;
    }
    if (this.passwordConfirm != user.password) {
      this.regMessage = $localize`REGISTRATION_ERROR_PASSWORDMISSMATCH`;
      return;
    }
    this.spinnerService.addToLoading();
    this.authService.register(user).subscribe((data: boolean) => {
      this.regMessage = $localize`REGISTRATION_SUCCESS`;
      this.spinnerService.removeFromLoading();
    }, error => {
      console.log('Register oops: ', error);
      if (error == "Username Taken")
        this.regMessage = $localize`REGISTRATION_ERROR_NAME`;
      else if (error == "Email Taken")
        this.regMessage = $localize`REGISTRATION_ERROR_EMAIL`;
      else
        this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.spinnerService.removeFromLoading();
    });
  }

  activationMail(user: User) {
    this.spinnerService.addToLoading();
    user.email = user.username;
    this.authService.activationMail(user).subscribe((result: string) => {
      this.toastService.showSuccessToast($localize`COMMON_SUCCESS`, $localize`ACTIVATIONMAIL_SUCCESS`);
      this.spinnerService.removeFromLoading();
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.spinnerService.removeFromLoading();
    });
  }
}
