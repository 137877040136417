import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Resources } from '../../../models/box-models';
import { VillageAdministrationData } from '../../../models/building-models';
import { BuildingType, BuildingTypeLevel, VillageLevelType } from '../../../models/staticData-models';
import { BuildingService } from '../../../services/data/building.service';
import { DataService } from '../../../services/data.service';
import { SpinnerService } from '../../../services/spinner.service';
import { ToastService } from '../../../services/toast.service';
import { TranslationService } from '../../../services/translation.service';

@Component({
  selector: 'app-village-administration',
  templateUrl: './village-administration.component.html',
  styleUrls: ['./village-administration.component.css']
})
export class VillageAdministrationComponent implements OnInit {
  villageLevelTypes: VillageLevelType[];
  possibleUpgrades: VillageLevelType[];
  buildingTypes: BuildingType[];
  buildingTypeLevels: BuildingTypeLevel[];
  villageId: number | null = null;
  villageAdministrationData: VillageAdministrationData | null = null;
  resData: Resources | null = null;

  woodIcon = "&#129683;";
  stoneIcon = "&#9935;";
  ironIcon = "&#128296;";
  foodIcon = "&#127859;";
  clockIcon = "&#128337;";

  constructor(private toastService: ToastService, private buildingService: BuildingService, private spinnerService: SpinnerService,
    private translationService: TranslationService, private dataService: DataService, private router: Router) {
    this.villageLevelTypes = [];
    this.possibleUpgrades = [];
    this.buildingTypes = [];
    this.buildingTypeLevels = [];

  }

  ngOnInit() {
    this.dataService._broadcastSource$.subscribe(broadcast => {
      if (broadcast == "CHANGED-VILLAGE" || broadcast == "LOAD-VILLAGEADMINISTRATION") {
        this.getVillageAdministrationData(false)
      }
    });
    this.getStaticData(0);    
  }

  returnToStart(reason: string) {
    this.router.navigate(['game/start']);
  }

  getTranslation(name: string, type: string): string {
    var result = "";
  if(type == 'effectName') {
      result = this.translationService.getEffectName(name);
    }
    return result;
  }

  getName(flagPosition: number): string {
    var result = "NAME_" + flagPosition;
    var villageLevel = this.villageLevelTypes.find(vl => vl.flagPosition == flagPosition);
    if (villageLevel != null) {
      result = this.translationService.getVillageLevelTranslations("VILLAGE_LEVEL_CATEGORY_" + villageLevel.levelCategory) + " " + villageLevel.level;
    }
    return result
  }

  getSlotString(slots: number[]): string {
    var result = "";
    for (var i = 0; i < slots.length; i++) {
      if (result.length > 0)
        result += ", ";
      result += this.translationService.getVillageLevelTranslations("BUILDING_SLOT_" + slots[i])
    }
    return result
  }

  prepareData() {
    if (this.villageAdministrationData == null || this.villageAdministrationData.villageLevels == null)
      return;

    this.resData = this.dataService.getResourceData();

    var buildingTypeLevelsPresent = [];
    for (var i = 0; i < this.buildingTypeLevels.length; i++) {
      var matchingId = this.villageAdministrationData.buildingLevels.find(bl => bl == this.buildingTypeLevels[i].id);
      if (matchingId != null)
        buildingTypeLevelsPresent.push(this.buildingTypeLevels[i]);
    }

    this.possibleUpgrades = [];
    for (var i = 0; i < this.villageLevelTypes.length; i++) {
      this.villageLevelTypes[i].requiredBuildingComment = null;
      if ((this.villageAdministrationData.villageLevels & this.villageLevelTypes[i].requiredFlagPosition) > 0 &&
        (this.villageAdministrationData.villageLevels & this.villageLevelTypes[i].flagPosition) == 0 &&
        (this.villageLevelTypes[i].blockedByFlagPosition == null || (this.villageAdministrationData.villageLevels & this.villageLevelTypes[i].blockedByFlagPosition) == 0)) {
        if (this.villageLevelTypes[i].requiredBuildingLevelId != null) {
          var requiredBuildingLevel = this.buildingTypeLevels.find(bl => bl.id == this.villageLevelTypes[i].requiredBuildingLevelId)
          if (requiredBuildingLevel != null) {
            var levelMatching = buildingTypeLevelsPresent.find(blp => blp.buildingTypeId == requiredBuildingLevel!.buildingTypeId && blp.level >= requiredBuildingLevel!.level)
            if (levelMatching == null) {
              var buildingType = this.buildingTypes.find(bt => bt.id == requiredBuildingLevel!.buildingTypeId)
              if (buildingType != null) {
                this.villageLevelTypes[i].requiredBuildingComment = this.translationService.getBlockedMessages(buildingType.name + "_NOT_" + requiredBuildingLevel.level);
              }
            }
            this.possibleUpgrades.push(this.villageLevelTypes[i]);
          }
        }
        else
          this.possibleUpgrades.push(this.villageLevelTypes[i]);
      }
    }
  }

  //====== GET DATA ======
  getStaticData(counter: number) {
    var version = this.dataService.getStaticDataVersion();
    if (!version || version.length == 0) {
      if (counter < 10) {
        counter++;
        setTimeout(() => {
          this.getStaticData(counter);
        }, 200);
      }
      else
        this.returnToStart("no staticData");
    }

    //clear data
    this.villageLevelTypes = [];
    this.buildingTypes = [];
    this.buildingTypeLevels = [];

    var tmpString = localStorage.getItem(version + '-villageLevels');
    if (tmpString != null)
      this.villageLevelTypes = JSON.parse(tmpString);


    var tmpString = localStorage.getItem(version + '-buildings');
    if (tmpString != null)
      this.buildingTypes = JSON.parse(tmpString);

    var tmpString = localStorage.getItem(version + '-buildingLevels');
    if (tmpString != null)
      this.buildingTypeLevels = JSON.parse(tmpString);

    if (version || version.length != 0)
      this.getVillageAdministrationData(true);
  }

  getVillageAdministrationData(onInit: boolean) {
    this.villageId = this.dataService.getSelectedVillageId();
    if (!this.villageId || this.villageId == -1) {
      if (onInit)
        return;
      else
        this.returnToStart("no villageId");
    }
    this.spinnerService.addToLoading();
    this.buildingService.getAdministrationData(this.villageId).subscribe((result: VillageAdministrationData) => {

      this.villageAdministrationData = result;
      this.prepareData();

      this.spinnerService.removeFromLoading();
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.spinnerService.removeFromLoading();
    });
  }

  increaseVillageLevel(levelId: number) {
    if (!this.villageId || this.villageAdministrationData == null)
      return;

    this.spinnerService.addToLoading();

    this.buildingService.increaseVillageLevel(this.villageId, levelId).subscribe((result: string) => {
      this.toastService.showSuccessToast($localize`COMMON_SUCCESS`, $localize`CONSTRUCT BUILDING_SUCCESS`);

      this.dataService.sendBroadcast('LOAD-RESOURCES');
      this.dataService.sendBroadcast('LOAD-EVENTS');
      this.villageAdministrationData!.upgrading = levelId;

      this.spinnerService.removeFromLoading();
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.spinnerService.removeFromLoading();
    });
  }
}
