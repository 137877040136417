import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  /*private loading: boolean = false;*/
  private busyCount = 0;

  private loading = new Subject<boolean>();
  loadingAction$ = this.loading.asObservable();

  constructor() { }

  addToLoading() {
    this.busyCount++;
    setTimeout(() => this.performCheck(), 0)
  }
  removeFromLoading() {
    this.busyCount--;
    setTimeout(() => this.performCheck(), 0)
  }

  performCheck() {
        if (this.busyCount > 0)
      this.loading.next(true);
    else
      this.loading.next(false);
  }
}
