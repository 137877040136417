import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { AuthInterceptor } from './services/auth.interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { MainComponent } from './components/main/main.component';
import { SettingsComponent } from './components/settings/settings.component';
import { RegComponent } from './components/reg/reg.component';
import { HomeComponent } from './components/home/home.component';
import { ToastComponent } from './components/toast/toast.component';
import { ToasterComponent } from './components/toaster/toaster.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { MapComponent } from './components/game/map/map.component';
import { StartComponent } from './components/game/start/start.component';
import { SoonComponent } from './components/game/soon/soon.component';
import { ResourceBoxComponent } from './components/game/resource-box/resource-box.component';
import { VillageBoxComponent } from './components/game/village-box/village-box.component';
import { MessageBoxComponent } from './components/game/message-box/message-box.component';
import { EventBoxComponent } from './components/game/event-box/event-box.component';
import { TickBoxComponent } from './components/game/tick-box/tick-box.component';
import { StaticDataComponent } from './components/game/static-data/static-data.component';
import { VillageComponent } from './components/game/village/village.component';
import { ScienceComponent } from './components/game/science/science.component';
import { TechComponent } from './components/game/tech/tech.component';
import { VillageAdministrationComponent } from './components/game/village-administration/village-administration.component';
import { InventoryComponent } from './components/game/inventory/inventory.component';
import { DesignComponent } from './components/game/design/design.component';
import { BarracksComponent } from './components/game/barracks/barracks.component';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    SettingsComponent,
    RegComponent,
    HomeComponent,
    ToastComponent,
    ToasterComponent,
    SpinnerComponent,
    MapComponent,
    StartComponent,
    SoonComponent,
    ResourceBoxComponent,
    VillageBoxComponent,
    MessageBoxComponent,
    EventBoxComponent,
    TickBoxComponent,
    StaticDataComponent,
    VillageComponent,
    ScienceComponent,
    TechComponent,
    VillageAdministrationComponent,
    InventoryComponent,
    DesignComponent,
    BarracksComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true,
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
