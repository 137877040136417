<div #toastElement
     class="toast fade toast-width mt-2"
     [ngClass]="type"
     role="alert"
     aria-live="assertive"
     aria-atomic="true">
  <div class="toast-header">
    <strong class="me-auto">{{ title }}</strong>
    <button type="button" class="btn-close" aria-label="Close" (click)="hide()"></button>
  </div>
  <div class="toast-body">{{ message }}</div>
</div>
