import { Component, OnInit } from '@angular/core';
import { SpinnerService } from '../../../services/spinner.service';
import { ToastService } from '../../../services/toast.service';
import { DataService } from '../../../services/data.service';
import { TranslationService } from '../../../services/translation.service';
import { Router } from '@angular/router';
import { ScienceStructureElement, ScienceSpot, PlayerScienceLevel } from '../../../models/science-models';
import { ScienceType, ScienceTypeLevel } from '../../../models/staticData-models';
import { ScienceService } from '../../../services/data/science.service';

@Component({
  selector: 'app-science',
  templateUrl: './science.component.html',
  styleUrls: ['./science.component.css']
})
export class ScienceComponent implements OnInit {
  scienceStructure: ScienceStructureElement[][] = [];
  scienceWidth: number = 60;

  scienceTypes: ScienceType[];
  scienceLevelTypes: ScienceTypeLevel[];
  playerScienceLevel: PlayerScienceLevel[];
  scienceSpots: ScienceSpot[];
  selectedSpot: ScienceSpot | null;
  clockIcon = "&#128337;";


  constructor(private toastService: ToastService, private scienceService: ScienceService, private spinnerService: SpinnerService,
    private translationService: TranslationService, private dataService: DataService, private router: Router) {
    this.scienceTypes = [];
    this.scienceLevelTypes = [];
    this.playerScienceLevel = [];
    this.scienceSpots = [];
    this.selectedSpot = null;
  }

  ngOnInit() {
    this.dataService._broadcastSource$.subscribe(broadcast => {
      if (broadcast == "LOAD-SCIENCE") {
        this.getScienceData()
      }
    });
    this.getStaticData(0);
  }

  returnToStart(reason: string) {
    this.router.navigate(['game/start']);
  }

  //====== INTERACTION ======

  getOutput(spot: number | null, type: string): string {
    var result = " ";
    //spot out of array
    if (spot == null || spot + 1 > this.scienceSpots.length || !this.scienceSpots[spot].scienceType)
      return result;

    if (type == 'name') {
      result = this.scienceSpots[spot].scienceType!.name;
    }
    else if (type == 'details') {
      if (!this.scienceSpots[spot].scienceLevelNow)
        return result;
      result = $localize`COMMON_LEVEL` + " " + this.scienceSpots[spot].scienceLevelNow!.level.toString();
      if (this.scienceSpots[spot].researching) {
        result = result + " => " + (this.scienceSpots[spot].researching! + this.scienceSpots[spot].scienceLevelNow!.level)
      }
    }
    return result;
  }

  getTranslation(name: string, type: string): string {
    var result = "";
    if (type == 'effectName') {
      result = this.translationService.getEffectName(name);
    }
    return result;
  }

  clickSpot(spot: number | null) {
    if (spot == null || spot + 1 > this.scienceSpots.length) {
      this.selectedSpot = null;
      return;
    }
    this.selectedSpot = this.scienceSpots[spot];
  }

  //====== BUILDING DATA ======

  buildArray() {
    this.scienceSpots = this.getNewSpotArray();
    //add levels obtained by player
    if (this.playerScienceLevel && this.playerScienceLevel.length > 0) {
      for (var i = 0; i < this.playerScienceLevel.length; i++) {
        var scienceLevel = this.scienceLevelTypes.find(slt => slt.id === this.playerScienceLevel[i].scienceLevelTypeId);
        if (scienceLevel) {
          var scienceType = this.scienceTypes.find(st => st.id === scienceLevel!.scienceTypeId);
          if (scienceType) {
            this.scienceSpots[scienceType.spot].scienceLevelNow = scienceLevel;
            this.scienceSpots[scienceType.spot].playerScienceLevelId = this.playerScienceLevel[i].id;
            this.scienceSpots[scienceType.spot].researching = this.playerScienceLevel[i].researching;
            if (this.playerScienceLevel[i].researching != null)
              var nextlevel = this.scienceLevelTypes.find(slt => slt.scienceTypeId === scienceType!.id && slt.level == (scienceLevel!.level + 1 + this.playerScienceLevel[i].researching!));
            if (nextlevel)
              this.scienceSpots[scienceType.spot].scienceLevelNext = nextlevel;
          }

        }
      }
    }

    //check which ones could be upgraded /are active
    for (var i = 0; i < this.scienceSpots.length; i++) {
      if (this.scienceSpots[i].scienceType) {
        this.scienceSpots[i].name = this.translationService.getScienceName(this.scienceSpots[i].scienceType!.name)
        this.scienceSpots[i].description = this.translationService.getScienceDescription(this.scienceSpots[i].scienceType!.name)
        //science has been researched allready => it must be active
        if (this.scienceSpots[i].scienceLevelNow)
          this.scienceSpots[i].active = true;
        //check requirements
        else if (this.scienceSpots[i].scienceType!.requiredSpotLevel.length > 0) {
          var requirementsMet = true;
          for (var j = 0; j < this.scienceSpots[i].scienceType!.requiredSpotLevel.length; j++) {
            var spot = this.scienceSpots[i].scienceType!.requiredSpotLevel[j].id;
            var level = this.scienceSpots[i].scienceType!.requiredSpotLevel[j].value;
            if (spot + 1 > this.scienceSpots.length) {
              requirementsMet = false;
              this.scienceSpots[i].requirements.push({ spot: spot, requiredLevel: level, fullfilled: false });
            }
            else if (!this.scienceSpots[spot].scienceLevelNow || this.scienceSpots[spot].scienceLevelNow!.level < level) {
              requirementsMet = false;
              this.scienceSpots[i].requirements.push({ spot: spot, requiredLevel: level, fullfilled: false });
            }
            else
              this.scienceSpots[i].requirements.push({ spot: spot, requiredLevel: level, fullfilled: true });

          }
          if (requirementsMet)
            this.scienceSpots[i].active = true;
        }
        //has no requirements => must be active
        else
          this.scienceSpots[i].active = true;

        if (!this.scienceSpots[i].scienceLevelNow && !this.scienceSpots[i].scienceLevelNext) {
          var nextlevel = this.scienceLevelTypes.find(slt => slt.scienceTypeId === this.scienceSpots[i].scienceType!.id && slt.level == 1);
          if (nextlevel)
            this.scienceSpots[i].scienceLevelNext = nextlevel;
        }
      }
    }

    //building DisplayStructure
    var data = this.getStringDataArray();
    this.scienceStructure = [];
    for (var i = 0; i < data.length; i++)
      this.scienceStructure.push(this.parseLineIntoSubArray(data[i]));
    return true;
  }

  parseLineIntoSubArray(line: string): ScienceStructureElement[] {
    var result: ScienceStructureElement[] = []
    //TODO parsing goes here
    if (!line || line.length === 0)
      for (var i = 0; i < this.scienceWidth; i++)
        result.push(this.getNewElement());
    else {
      //split string into groups
      var groupSplitts = line.split("#");
      for (var i = 0; i < groupSplitts.length; i++) {
        if (groupSplitts[i].length > 0) {
          //split group into elements
          var elementSplitts = groupSplitts[i].split(";");
          for (var j = 0; j < elementSplitts.length; j++) {
            if (j === 0) {
              //first part is allwas the count of empty cells
              var count = Number(elementSplitts[j]);
              if (count > 0)
                for (var k = 0; k < count; k++)
                  result.push(this.getNewElement());
            }
            else {
              //split element into values
              var valueSplitts = elementSplitts[j].split("-");
              if (valueSplitts.length === 2) {//SCIENCE                
                if (valueSplitts[1] === "1")
                  result.push(this.getScienceNameElement(valueSplitts));
                else
                  result.push(this.getScienceDetailElement(valueSplitts));
              }
              else if (valueSplitts.length === 4) //CONNECTION
              {
                var count = Number(valueSplitts[0]);
                for (var k = 0; k < count; k++)
                  result.push(this.getConnectionElement(valueSplitts));
              }
            }
          }
        }
      }
    }
    return result;
  }

  getNewSpotArray(): ScienceSpot[] {
    //geting max spot
    var maxSpot: number = 0;
    if (this.scienceTypes && this.scienceTypes.length > 0)
      for (var i = 0; i < this.scienceTypes.length; i++)
        if (this.scienceTypes[i].spot > maxSpot)
          maxSpot = this.scienceTypes[i].spot
    //building new array
    var result: ScienceSpot[] = [];
    for (var i = 0; i <= maxSpot; i++)
      result.push({ active: false, scienceLevelNext: null, scienceLevelNow: null, scienceType: null, researching: 0, requirements: [], name: "", description: "", playerScienceLevelId: null });
    //filling Array with ScienceTypes (position according to their Spot)
    for (var i = 0; i < this.scienceTypes.length; i++)
      result[this.scienceTypes[i].spot] = { active: false, scienceLevelNext: null, scienceLevelNow: null, scienceType: this.scienceTypes[i], researching: 0, requirements: [], name: "", description: "", playerScienceLevelId: null };
    return result;
  }

  getNewElement(): ScienceStructureElement {
    return {
      type: -1, //type of the element: 0 = connection, 1 science, 2 info, -1 new
      down: null,   //for connection which direktion to display
      left: null,   //for connection which direktion to display
      right: null,  //for connection which direktion to display
      up: null,     //for connection which direktion to display
      active: false, //for connection - if any related is available connection becomes "active"
      scienceSpot: null,
      requiredLevel: null
    };
  }

  getConnectionElement(valueSplitts: string[]): ScienceStructureElement {
    var result = this.getNewElement();
    //element splitcount 3=connection: count - flag - list of target spots - list of source spots
    //    FLAGS: 1:up, 2:right, 4:down, 8:left, 16:display needed level of forst related spot
    //1-5-2,3  
    var directionsFlag = valueSplitts[1];
    var targetSpots = valueSplitts[2];
    var sourceSpots = valueSplitts[3];
    result.type = 0;
    result.up = (Number(directionsFlag) & 1) > 0;
    result.right = (Number(directionsFlag) & 2) > 0;
    result.down = (Number(directionsFlag) & 4) > 0;
    result.left = (Number(directionsFlag) & 8) > 0;
    var displayLevel = (Number(directionsFlag) & 16) > 0;

    //RELATION
    var targetSpotsSplits = targetSpots.split(",");
    var sourceSpotsSplits = sourceSpots.split(",");
    for (var i = 0; i < targetSpotsSplits.length; i++) {
      var targetSpot = Number(targetSpotsSplits[i]);
      if ((targetSpot + 1) <= this.scienceSpots.length) {
        //if target is active connection is active
        if (this.scienceSpots[targetSpot].active) {
          result.active = true;
          i = targetSpotsSplits.length;
        }
        else if (this.scienceSpots[targetSpot].requirements && this.scienceSpots[targetSpot].requirements.length > 0) {
          for (var j = 0; j < sourceSpotsSplits.length; j++) {
            var sourceSpot = Number(sourceSpotsSplits[j]);
            if (sourceSpot) {
              var req = this.scienceSpots[targetSpot].requirements.find(r => r.spot == sourceSpot);
              if (req && req.fullfilled) {
                result.active = true;
                j = sourceSpotsSplits.length;
                i = targetSpotsSplits.length;
              }
            }
          }
        }
      }
    }
    //REQUIRED LEVEL
    if (displayLevel) {
      //TODO ... is this needed
    }

    return result;
  }

  getScienceNameElement(valueSplitts: string[]): ScienceStructureElement {
    var result = this.getNewElement();
    var spot = valueSplitts[0];
    result.type = 1;
    result.scienceSpot = Number(spot);
    result.active = false;
    if ((result.scienceSpot + 1) <= this.scienceSpots.length)
      result.active = this.scienceSpots[result.scienceSpot].active;
    return result;
  }

  getScienceDetailElement(valueSplitts: string[]): ScienceStructureElement {
    var result = this.getNewElement();
    var spot = valueSplitts[0];
    result.type = 2;
    result.scienceSpot = Number(spot);
    result.active = false;
    if ((result.scienceSpot + 1) <= this.scienceSpots.length)
      result.active = this.scienceSpots[result.scienceSpot].active;
    return result;
  }

  //====== GET DATA ======
  getStaticData(counter: number) {
    var version = this.dataService.getStaticDataVersion();
    if (!version || version.length == 0) {
      if (counter < 10) {
        counter++;
        setTimeout(() => {
          this.getStaticData(counter);
        }, 200);
      }
      else
        this.returnToStart("no staticData");
    }

    //clear data
    this.scienceTypes = [];
    this.scienceLevelTypes = [];

    var tmpString = localStorage.getItem(version + '-sciences');
    if (tmpString != null)
      this.scienceTypes = JSON.parse(tmpString);

    tmpString = localStorage.getItem(version + '-scienceLevels');
    if (tmpString != null)
      this.scienceLevelTypes = JSON.parse(tmpString);

    if (version || version.length != 0)
      this.getScienceData();
  }

  getScienceData() {
    this.spinnerService.addToLoading();
    this.scienceService.getScienceData().subscribe((result: PlayerScienceLevel[]) => {
      this.playerScienceLevel = result;
      this.buildArray();
      this.spinnerService.removeFromLoading();
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.spinnerService.removeFromLoading();
    });
  }

  startResearch() {
    if (!this.selectedSpot || !this.selectedSpot.scienceLevelNext)
      return;
    this.spinnerService.addToLoading();

    this.scienceService.startResearch(this.selectedSpot.scienceLevelNext.id, this.selectedSpot.playerScienceLevelId).subscribe((result: string) => {
      this.toastService.showSuccessToast($localize`COMMON_SUCCESS`, $localize`CONSTRUCT BUILDING_SUCCESS`);

      //TODO reload or update? broadcast update events/res?
      this.dataService.sendBroadcast('LOAD-EVENTS');
      this.getScienceData();

      this.spinnerService.removeFromLoading();
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.spinnerService.removeFromLoading();
    });
  }


  //=============== RAW STRUCTURE DATA ===============
  getStringDataArray(): string[] {
    var data: string[] = [];
    //# seperating groups ; seperating elemenst in group
    //first element number of emptycells befor group
    //element splitcount 4=connection: count - flag - list of target spots - list of source spots
    //    FLAGS: 1:up, 2:right, 4:down, 8:left, 16:display needed level of forst related spot
    //element splitcount 2= sience spot: type-spotNumber-slotType (sciencename/sciencedetails)
    data.push("15;1-5-1-#15");
    data.push("14;1-1#14");
    data.push("14;1-2#14");
    data.push("15;1-5-2,3,8-1#15");
    data.push("12;1-6-2-1#0;2-10-2-1#0;1-15-2,3,8-1#0;2-10-3-1#0;1-12-3-1#12");
    data.push("12;1-5-2-1#2;1-5-8-1#2;1-5-3-1#12");
    data.push("11;2-1#1;1-5-8-1#1;3-1#11");
    data.push("11;2-2#1;1-5-8-1#1;3-2#11");
    data.push("12;1-5-4,5,6,8-2#2;1-5-8-1#2;1-5-7-3#12");
    data.push("1;1-6-4-2#0;3-10-4-2#0;1-14-4,5-2#0;3-10-4,5-2#0;1-14-4,5,6-2#0;2-10-4,5,6-2#0;1-13-4,5,6,8-2#2;1-5-8-1#2;1-5-7-3#12");
    data.push("1;1-5-4-2#3;1-5-5-2#3;1-5-6-2#2;1-5-8-2#2;1-5-8-1#2;1-5-7-3#12");
    data.push("0;4-1#1;5-1#1;6-1#1;1-5-8-2#2;1-5-8-1#1;7-1#11");
    data.push("0;4-2#1;5-2#1;6-2#1;1-5-8-2#2;1-5-8-1#1;7-2#11");
    data.push("12;1-5-8-2#2;1-5-8-1#2;1-5-8-7#12");
    data.push("12;1-3-8-2#0;2-10-8-2#0;1-15-8-1,2,7#0;2-10-8-7#0;1-9-8-7#12");
    data.push("15;1-5-8-1,2,7#15");
    data.push("14;8-1#14");
    data.push("14;8-2#14");
    return data;
  }

}
