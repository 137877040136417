import { Component, OnInit } from '@angular/core';
import { SpinnerService } from '../../../services/spinner.service';
import { ToastService } from '../../../services/toast.service';
import { DataService } from '../../../services/data.service';
import { GameBasicService } from '../../../services/data/game-basic.service';
import { environment } from '../../../../environments/environment';
import { BuildingType, BuildingTypeLevel, MapFieldType, ScienceType, ScienceTypeLevel, Nation, TechType, VillageLevelType, ItemType } from '../../../models/staticData-models';
import { nameDate } from '../../../models/pair-models';

@Component({
  selector: 'app-static-data',
  templateUrl: './static-data.component.html',
  styleUrls: ['./static-data.component.css']
})
export class StaticDataComponent implements OnInit {
  version: string = "";
  staticDataTime: number = environment.staticDataTime;
  versions: nameDate[] = [];
  buildings: BuildingType[] = [];
  buildingLevels: BuildingTypeLevel[] = [];
  mapFields: MapFieldType[] = [];
  nations: Nation[] = [];
  sciences: ScienceType[] = [];
  scienceLevels: ScienceTypeLevel[] = [];
  techs: TechType[] = [];
  villageLevels: VillageLevelType[] = [];
  items: ItemType[] = [];

  constructor(private gameBasicService: GameBasicService,
    private dataService: DataService,
    private toastService: ToastService,
    private spinnerService: SpinnerService) { }

  ngOnInit() {
    this.getVersion();
  }

  checkStaticData() {
    var serializedVersions = localStorage.getItem('StaticDataVersions') || '[]';
    this.versions = JSON.parse(serializedVersions);
    if (this.version.length === 0) {
      //problem - no version received from server
      this.dataService.clearStaticDataVersion();
    }
    var versionFound = false;
    var dateNow = new Date();
    var compareDate = new Date();
    compareDate.setTime(compareDate.getTime() - (this.staticDataTime * 60 * 60 * 1000));
    if (this.versions.length > 0) {
      for (var i = this.versions.length - 1; i >= 0; i--) {
        if (this.versions[i].name == this.version) {
          versionFound = true;
          this.versions[i].date = dateNow;
          this.dataService.setStaticDataVersion(this.version);
        }
        else if (new Date(this.versions[i].date) < compareDate) {
          localStorage.removeItem(this.versions[i].name + '-buildings');
          localStorage.removeItem(this.versions[i].name + '-buildingLevels');
          localStorage.removeItem(this.versions[i].name + '-mapFields');
          localStorage.removeItem(this.versions[i].name + '-nations');
          localStorage.removeItem(this.versions[i].name + '-sciences');
          localStorage.removeItem(this.versions[i].name + '-scienceLevels');
          localStorage.removeItem(this.versions[i].name + '-techs');
          localStorage.removeItem(this.versions[i].name + '-villageLevels');
          localStorage.removeItem(this.versions[i].name + '-items');
          this.versions.splice(i, 1);
        }
      }
    }
    var serializedVersions = JSON.stringify(this.versions);
    localStorage.setItem('StaticDataVersions', serializedVersions);

    if (!versionFound) {
      this.dataService.clearStaticDataVersion();
      this.getBuildings();
    }
      
  }

  getStaticDataDone(status: boolean) {
    if (status) {
      localStorage.setItem(this.version + '-buildings', JSON.stringify(this.buildings))
      localStorage.setItem(this.version + '-buildingLevels', JSON.stringify(this.buildingLevels))
      localStorage.setItem(this.version + '-mapFields', JSON.stringify(this.mapFields))
      localStorage.setItem(this.version + '-nations', JSON.stringify(this.nations))
      localStorage.setItem(this.version + '-sciences', JSON.stringify(this.sciences))
      localStorage.setItem(this.version + '-scienceLevels', JSON.stringify(this.scienceLevels))
      localStorage.setItem(this.version + '-techs', JSON.stringify(this.techs))
      localStorage.setItem(this.version + '-villageLevels', JSON.stringify(this.villageLevels))
      localStorage.setItem(this.version + '-items', JSON.stringify(this.items))
      this.versions.push({ name: this.version, date: new Date() });
      this.dataService.setStaticDataVersion(this.version);
      var serializedVersions = JSON.stringify(this.versions);
      localStorage.setItem('StaticDataVersions', serializedVersions);
    }
    else {

    }
    this.spinnerService.removeFromLoading();
  }

  //===========================================================

  getVersion() {
    this.spinnerService.addToLoading();
    this.gameBasicService.getStaticDataVersion().subscribe((result: string) => {
      this.spinnerService.removeFromLoading();
      this.version = result;
      this.checkStaticData();
    }, error => {
      this.version = "";
      this.spinnerService.removeFromLoading();
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
    });
  }

  //===========================================================
  //==================== GET DATA-FUNCTION ====================
  //===========================================================
  async getBuildings() {
    this.spinnerService.addToLoading();
    this.gameBasicService.getBuildings().subscribe((result: BuildingType[]) => {
      this.buildings = result;
      this.getBuildingLevels();
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.getStaticDataDone(false);
    });
  }
  async getBuildingLevels() {
    this.gameBasicService.getBuildingLevels().subscribe((result: BuildingTypeLevel[]) => {
      this.buildingLevels = result;
      this.getMapFields();
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.getStaticDataDone(false);
    });
  }
  async getMapFields() {
    this.gameBasicService.getMapFields().subscribe((result: MapFieldType[]) => {
      this.mapFields = result;
      this.getNations();
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.getStaticDataDone(false);
    });
  }
  async getNations() {
    this.gameBasicService.getNations().subscribe((result: Nation[]) => {
      this.nations = result;
      this.getSciences();
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.getStaticDataDone(false);
    });
  }
  async getSciences() {
    this.gameBasicService.getSciences().subscribe((result: ScienceType[]) => {
      this.sciences = result;
      this.getScienceLevels();
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.getStaticDataDone(false);
    });
  }
  async getScienceLevels() {
    this.gameBasicService.getScienceLevels().subscribe((result: ScienceTypeLevel[]) => {
      this.scienceLevels = result;
      this.getTechs();
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.getStaticDataDone(false);
    });
  }
  async getTechs() {
    this.gameBasicService.getTechs().subscribe((result: TechType[]) => {
      this.techs = result;
      this.getVillageLevels();
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.getStaticDataDone(false);
    });
  }
  async getVillageLevels() {
    this.gameBasicService.getVillageLevels().subscribe((result: VillageLevelType[]) => {
      this.villageLevels = result;
      this.getItems();
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.getStaticDataDone(false);
    });
  }
  async getItems() {
    this.gameBasicService.getItems().subscribe((result: ItemType[]) => {
      this.items = result;
      this.getStaticDataDone(true);
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.getStaticDataDone(false);
    });
  }
}
