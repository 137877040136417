import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {MapDetailsResult, MapResult, MapStandingsResult } from '../../models/map-models';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  baseApiUrl: string = environment.API_URL;
  constructor(private http: HttpClient) { }

  //public recruitUnits(designId: number, villageId: number, amount: number): Observable<string> {
  //  var request = { designId: designId, villageId: villageId, amount: amount }
  //  return this.http.post(this.baseApiUrl + 'Village/recruitUnits', request, { responseType: 'text' });
  //}

  public getMap(x: number, y: number, zoom: number, villageId: number | null): Observable<MapResult> {
    return this.http.get<MapResult>(this.baseApiUrl + 'Map/getMap?x=' + x + '&y=' + y + '&zoom=' + zoom + '&villageId=' + villageId);
  }

  public getMapDetails(x: number, y: number): Observable<MapDetailsResult> {
    return this.http.get<MapDetailsResult>(this.baseApiUrl + 'Map/getMapDetails?x=' + x + '&y=' + y);
  }

  public getMapStandings(): Observable<MapStandingsResult> {
    return this.http.get<MapStandingsResult>(this.baseApiUrl + 'Map/getMapStandings' );
  }
}
