<div class="row" style="margin: 0; padding: 12px 0;">
  <div style="width:33%" *ngFor="let upgrade of possibleUpgrades">
    <div class="card border-text-dark" style="padding: 0;">
      <div class="card-header"
           style="font-size: 14px;">{{getName(upgrade.flagPosition)}}</div>
      <div class="card-body text-text-dark" style="font-size: 14px;">


        <div  style="margin-top:10px" *ngIf="upgrade.requiredBuildingComment != null">
          <ul class="list-group" style="padding: 0 32px 0 8px; font-weight: bold;">
            <li  class="list-group-item d-flex justify-content-between align-items-center">
              <ul class="list-group">
                <li class="justify-content-between align-items-center">
                  <span style="color: darkred ">{{upgrade.requiredBuildingComment}}</span>
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <ul class="list-group" *ngIf="upgrade.effectData.length > 0">
          <li *ngFor="let effect of upgrade.effectData" class="list-group-item d-flex justify-content-between align-items-center" style="padding: 3px 6px;">
            {{getTranslation(effect.name, 'effectName')}}
            <span class="badge bg-primary rounded-pill">{{effect.value}}</span>
          </li>
        </ul>

        <Span *ngIf="upgrade.additionalFields > 0" i18n>ADDITIONAL_MAP_FIELDS</Span>
        <Span *ngIf="upgrade.additionalFields > 0">: {{upgrade.additionalFields}}</Span>
        <br *ngIf="upgrade.additionalFields > 0" />

        <Span *ngIf="upgrade.slots.length > 0" i18n>ADDITIONAL_BUILDING_SLOTS</Span>
        <Span *ngIf="upgrade.slots.length > 0">: {{getSlotString(upgrade.slots)}}</Span>
        <br *ngIf="upgrade.slots.length > 0" />

        <Span *ngIf="upgrade.blockedByFlagPosition != null" i18n>BLOCKED_BY_LEVEL</Span>
        <Span *ngIf="upgrade.blockedByFlagPosition != null">: {{getName(upgrade.blockedByFlagPosition)}}</Span>
        <div class="row">
          <div class=" col col-sm-3">
            <span [innerHTML]="woodIcon"></span><span [ngStyle]="{'color': resData != null && upgrade.costWood > resData.wood ? 'red' : 'black' }">{{upgrade.costWood}}</span>
          </div>
          <div class=" col col-sm-3">
            <span [innerHTML]="stoneIcon"></span><span [ngStyle]="{'color': resData != null && upgrade.costStone > resData.stone ? 'red' : 'black' }">{{upgrade.costStone}}</span>
          </div>
          <div class=" col col-sm-3">
            <span [innerHTML]="ironIcon"></span><span [ngStyle]="{'color': resData != null && upgrade.costIron > resData.iron ? 'red' : 'black' }">{{upgrade.costIron}}</span>
          </div>
          <div class=" col col-sm-3">
            <span [innerHTML]="foodIcon"></span><span [ngStyle]="{'color': resData != null && upgrade.costFood > resData.food ? 'red' : 'black' }">{{upgrade.costFood}}</span>
          </div>
        </div>
        <div class="row">
          <div class=" col col-sm-3" style="padding-top: 12px;">
            <span [innerHTML]="clockIcon"></span><span>{{upgrade.upgradingTicks}}</span>
          </div>
          <div class="col-sm-9" style="padding: 8px 40px 0 12px;">
            <button class="btn btn-success"
                    style="width: 100%"
                    *ngIf="villageAdministrationData == null || villageAdministrationData.upgrading == null || villageAdministrationData.upgrading != upgrade.id"
                    (click)="increaseVillageLevel(upgrade.id)"
                    [disabled]="villageAdministrationData == null || villageAdministrationData.upgrading != null || upgrade.requiredBuildingComment != null || resData == null || upgrade.costWood > resData.wood || upgrade.costStone > resData.stone|| upgrade.costIron > resData.iron || upgrade.costFood > resData.food" i18n>
              BUY_UPGRADE
            </button>
            <button class="btn btn-success"
                    style="width: 100%"
                    *ngIf="villageAdministrationData != null && villageAdministrationData.upgrading != null && villageAdministrationData.upgrading == upgrade.id"
                    [disabled]="true" i18n>
              UPGRADING...
            </button>
          </div>
        </div>


      </div>
    </div>
  </div>
</div>
