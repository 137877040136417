import { Component, OnInit } from '@angular/core';
import { SpinnerService } from '../../../services/spinner.service';
import { ToastService } from '../../../services/toast.service';
import { DataService } from '../../../services/data.service';
import { GameBasicService } from '../../../services/data/game-basic.service';
import { CurrentTick } from '../../../models/box-models';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-tick-box',
  templateUrl: './tick-box.component.html',
  styleUrls: ['./tick-box.component.css']
})
export class TickBoxComponent implements OnInit {
  tickLength: number = environment.tickLength;
  tick: number = 0;
  nextTick: Date = new Date();
  interval: any;


  displayTimer: string = "0";


  constructor(private gameBasicService: GameBasicService,
    private dataService: DataService,
    private toastService: ToastService,
    private spinnerService: SpinnerService) { }

  ngOnInit() {
    this.getCurrentTick();
  }

  runTick() {
    this.tick++;
    this.dataService.setTick(this.tick);
    this.nextTick.setSeconds(this.nextTick.getSeconds() + this.tickLength);
    this.dataService.sendBroadcast('TICK');
    var clientTimeNow: Date = new Date();
    var delay = (this.nextTick.getTime() - clientTimeNow.getTime())
    this.runTimer();
    setTimeout(() => {
      this.runTick();
    }, delay);
  }

  runTimer() {
    var clientTimeNow: Date = new Date();
    var delay = (this.nextTick.getTime() - clientTimeNow.getTime());
    if (delay > 0) {
      this.displayTimer = this.millisecondsToTimeString(delay);
      setTimeout(() => {
        this.runTimer();
      }, 100);
    }
  }

  millisecondsToTimeString(input: number) {
    if (input < 0)
      return "--:--";
    const seconds: number = Math.floor(input / 1000);
    var result = seconds + ':' + (input - seconds * 1000);
    return result;
  }

  getCurrentTick() {
    this.spinnerService.addToLoading();
    this.gameBasicService.getCurrentTick().subscribe((result: CurrentTick) => {
      this.tick = result.tick;
      this.dataService.setTick(this.tick);

      var clientTimeNow: Date = new Date();
      var clientTimeZone = clientTimeNow.getTimezoneOffset();
      //var serverTime = new Date(result.serverTime);
      //var serverTimeZone = serverTime.getTimezoneOffset();
      var serverTimeZone = this.parseTimezone(result.serverTime.toString());

      var timeDifMinutes = serverTimeZone - clientTimeZone;

      var serverLastTick = new Date(result.timeTickStarted);
      var serverNextTick = serverLastTick;
      serverNextTick.setSeconds(serverNextTick.getSeconds() + this.tickLength);

      this.nextTick = serverNextTick;
      this.nextTick.setMinutes(this.nextTick.getMinutes() + timeDifMinutes);

      this.spinnerService.removeFromLoading();
      var delay = (this.nextTick.getTime() - clientTimeNow.getTime())

      this.runTimer();
      setTimeout(() => {
        this.runTick();
      }, delay);
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.spinnerService.removeFromLoading();
    });
  }

  parseTimezone(s: string):number {    
    //2023-10-26T22:10:26.9817137+00:00
    var timezoneString = s.substring(s.length - 6, s.length);
    var direction = timezoneString.substring(0, 1);
    var hours = Number(timezoneString.substring(1, 3));
    var minutes = Number(timezoneString.substring(4, 6));
    var result: number = (hours * 60) + minutes;
    if (direction == "+")
      result = result * -1;
    return result;
  }
}
