import { Component, OnInit } from '@angular/core';
import { SpinnerService } from '../../../services/spinner.service';
import { ToastService } from '../../../services/toast.service';
import { DataService } from '../../../services/data.service';
import { GameBasicService } from '../../../services/data/game-basic.service';
import { EventMinimal } from '../../../models/box-models';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-event-box',
  templateUrl: './event-box.component.html',
  styleUrls: ['./event-box.component.css']
})
export class EventBoxComponent implements OnInit {
  villageId: number = -1;
  highestId: number = -1;
  tickCount: number = 0;
  currentTick: number = 0;
  tickLength: number = environment.tickLength;
  reloadTicks: number = environment.reloadTicks;
  reloadDelay: number = environment.reloadDelay;
  delayTime: number = 0;
  allEvents: EventMinimal[] = [];
  //militaryEvents: EventMinimal[] = [];
  //villageEvents: EventMinimal[] = [];
  //otherEvents: EventMinimal[] = [];
  //militaryIcon = "&#9876;";
  //villageIcon = "&#127969;";
  //otherIcon = "&#9200;";

  militaryEvents: EventMinimal[] = [];
  recruitEvents: EventMinimal[] = [];
  constructionEvents: EventMinimal[] = [];
  scienceEvents: EventMinimal[] = [];
  tradeEvents: EventMinimal[] = [];
  otherEvents: EventMinimal[] = []; //upgrade, settle
  militaryIcon = "&#9876;"; //2 swords
  recruitIcon = "&#128106;"; //family
  constractionIcon = "&#129521;"; //brick
  scienceIcon = "&#9879;"; //flask
  tradeIcon = "&#9878;"; //scale
  otherIcon = "&#9200;"; //clock


  constructor(private gameBasicService: GameBasicService,
    private dataService: DataService,
    private toastService: ToastService,
    private spinnerService: SpinnerService) { }

  ngOnInit() {
    if (this.reloadDelay > 0) {
      var maxRandom = this.tickLength * 1000 / this.reloadDelay;
      this.delayTime = Math.floor(Math.random() * maxRandom) + 1;
    }

    this.dataService._broadcastSource$.subscribe(broadcast => {
      if (broadcast == "CHANGED-VILLAGE" || broadcast == "LOAD-EVENTS") {
        this.villageId = this.dataService.getSelectedVillageId();
        if (this.villageId && this.villageId > -1) {
          this.getEvents();
        }
      }
      if (broadcast == "TICK") {
        this.currentTick = this.dataService.getTick();
        this.checkEvents();
        this.tickCount++;
        if (this.tickCount > this.reloadTicks)
          if (this.delayTime > 0) {
            setTimeout(() => {
              this.getEventsNewestId();
            }, this.delayTime);
          }
          else
            this.getEventsNewestId();
      }
    })
  }

  checkEvents() {
    //check wich events are done and remove those
    var eventsToCheck: EventMinimal[] = []
    for (var i = this.allEvents.length - 1; i >= 0; i--) {
      if (this.currentTick >= this.allEvents[i].eventTick) {
        eventsToCheck.push(this.allEvents[i]);
        this.allEvents.splice(i, 1);
      }
    }
    this.prepareEvents();
    setTimeout(() => {
      this.checkReloads(eventsToCheck);
    }, 500);

  }

  checkReloads(events: EventMinimal[]) {
    //check which reloads need to be triggered
    var eventTypes: number[] = [];
    for (var i = 0; i < events.length; i++)
      eventTypes.push(events[i].type);

    //ENUM EventTypes
      //Special = 0,
      //Build = 1,
      //Settle = 2,
      //ArmyAttack = 3,
      //ArmyRaid = 4,
      //ArmySiege = 5,
      //Trade = 6,
      //Recruit = 7,
      //Research = 8,
      //VillageLevelUpgrade = 9,
      //ItemMove = 10,

    var existingType = eventTypes.find(et => et == 0)
    if (existingType != null) {
      //...
    }
    existingType = eventTypes.find(et => et == 1)
    if (existingType != null) {
      this.dataService.sendBroadcast('LOAD-RESOURCES');
      this.dataService.sendBroadcast('LOAD-VILLAGE');
    }
    existingType = eventTypes.find(et => et == 2)
    if (existingType != null) {
      this.dataService.sendBroadcast('LOAD-VILLAGES');
    }
    existingType = eventTypes.find(et => et == 3)
    if (existingType != null) {
      //...
    }
    existingType = eventTypes.find(et => et == 4)
    if (existingType != null) {
      this.dataService.sendBroadcast('LOAD-RESOURCES');
    }
    //existingType = eventTypes.find(et => et == 5)
    //if (existingType != null) {
    //  this.dataService.sendBroadcast('LOAD-RESOURCES');
    //}
    existingType = eventTypes.find(et => et == 7)
    if (existingType != null) {
      this.dataService.sendBroadcast('LOAD-TROOPS');
    }
    existingType = eventTypes.find(et => et == 8)
    if (existingType != null) {
      this.dataService.sendBroadcast('LOAD-SCIENCE');
      this.dataService.sendBroadcast('LOAD-RESOURCES');
    }
    existingType = eventTypes.find(et => et == 8)
    if (existingType != null) {
      this.dataService.sendBroadcast('LOAD-VILLAGEADMINISTRATION');
      this.dataService.sendBroadcast('LOAD-RESOURCES');
    }
    existingType = eventTypes.find(et => et == 9)
    if (existingType != null) {
      //...
    }
  }

  prepareEvents() {
    this.militaryEvents = [];
    this.recruitEvents = [];
    this.constructionEvents = [];
    this.scienceEvents = [];
    this.tradeEvents = [];
    this.otherEvents = []; //upgrade, settle
    //get highestId and group events for display
    for (var i = 0; i < this.allEvents.length; i++) {
      if (this.highestId < this.allEvents[i].id)
        this.highestId = this.allEvents[i].id;

      if (this.allEvents[i].type == 3 || this.allEvents[i].type == 4 || this.allEvents[i].type == 5)
        this.militaryEvents.push(this.allEvents[i]);
      else if (this.allEvents[i].type == 7)
        this.recruitEvents.push(this.allEvents[i]);
      else if (this.allEvents[i].type == 1)
        this.constructionEvents.push(this.allEvents[i]);
      else if (this.allEvents[i].type == 8)
        this.scienceEvents.push(this.allEvents[i]);
      else if (this.allEvents[i].type == 5)
        this.tradeEvents.push(this.allEvents[i]);
      else //2, 8, 9
        this.otherEvents.push(this.allEvents[i]);
    }
  }

  //====== GET DATA ======
  getEventsNewestId() {
    //this.spinnerService.addToLoading();
    this.gameBasicService.getEventsNewestId(this.villageId).subscribe((result: number) => {

      this.tickCount = 0;
      this.spinnerService.removeFromLoading();
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      //this.spinnerService.removeFromLoading();
    });
  }

  getEvents() {
    this.spinnerService.addToLoading();
    this.gameBasicService.getEvents(this.villageId).subscribe((result: EventMinimal[]) => {
      this.allEvents = result;
      this.prepareEvents();
      this.tickCount = 0;
      this.dataService.setEventData(this.allEvents);
      this.spinnerService.removeFromLoading();
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.spinnerService.removeFromLoading();
    });
  }
}
