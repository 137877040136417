<div class="row" style="margin: 0;">
  <div class="col-sm-2"
       style="padding: 6px;">
    <button class="btn btn-success"
            style="width: 100%"
            (click)="addDesign()"
            [disabled]="designs.length >= maxDesigns"
            title="{{designs.length}} / {{maxDesigns}}"
            i18n>
      ADD_DESIGN
    </button>
  </div>
  <div class="col-sm-2"
       style="padding: 6px;">
    <button class="btn btn-warning"
            style="width: 100%"
            (click)="saveDesign()"
            [disabled]="false"
            i18n>
      SAVE_DESIGN
    </button>
  </div>
</div>
<div class="row" style="margin: 0;">
  <!--LIST-->
  <div class="col-sm-2" style="padding: 6px;">
    <div class="col-sm-12"
         *ngFor="let d of designs"
         style="border: 1px solid black; border-radius: 5px; padding-left: 4px; margin: 2px 0px; "
         [ngStyle]="{'background-color': d == selectedDesign ? 'lightgray' : '','font-weight': d == selectedDesign ? 'bold' : '' }"
         (click)="selectDesign(d)">
      <span [innerHTML]="getIcon(d.icon)" style=""></span>
      {{d.name}}
    </div>
  </div>

  <!--DETAILS-->
  <div class="col-sm-10"
       style="padding: 6px;"
       *ngIf="selectedDesign != null && selectedDesign.design != null">

    <div class="row" style="padding-bottom: 10px">
      <div class="col-sm-4">
        <span i18n>COMMON_NAME</span>
        <input [(ngModel)]="selectedDesign.name" placeholder="Name" style="width: 100%; padding: 0" (ngModelChange)="nameChanged()" />

        <span i18n>RECRUIT</span>
        <select class="form-select" style="padding:0"
                [(ngModel)]="selectedDesign.design.recruitTypeId"
                (ngModelChange)="designChanged('RECRUIT')">
          <option *ngFor="let recruit of recruits" [value]="recruit.id">
            {{recruit.name}}
          </option>
        </select>

        <span i18n>MOUNT</span>
        <select class="form-select" style="padding:0"
                [(ngModel)]="selectedDesign.design.mountTypeId"
                (ngModelChange)="designChanged('MOUNT')">
          <option *ngFor="let mount of mounts" [value]="mount.id">
            {{mount.name}}
          </option>
        </select>
      </div>
      <div class="col-sm-4">
        ICON
        <div class="row">
          <select class="form-select" style="padding:0"
                  [(ngModel)]="selectedDesign.design.icon"
                  (ngModelChange)="iconChanged()">
            <option *ngFor="let icon of icons" [value]="icon.id">
              {{icon.description}}
            </option>
          </select>
        </div>
        <div class="row">
          <span [innerHTML]="getIcon(selectedDesign.design.icon)" style="font-size: 46px; padding-top: 16px; padding-left: 30%;"></span>
        </div>
      </div>
      <div class="col-sm-4" style="padding-left:10px">
        <span style="padding-left:10px" i18n>DESIGN_STATISTICS</span><br />
        <span i18n>MAX_WEIGHT</span>
        <span>: {{weightMax}}</span><br />
        <span i18n>WEIGHT</span>
        <span>: {{weight}}</span><br />
      </div>

    </div>

    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button class="nav-link active" id="ALayout" data-bs-toggle="tab" data-bs-target="#A-Layout" type="button"
                role="tab" aria-controls="A-Layout" aria-selected="true" (click)="changeTab('A')" i18n>A_LAYOUT</button>
      </li>

      <li class="nav-item" role="presentation">
        <button class="nav-link" id="BLayout" data-bs-toggle="tab" data-bs-target="#A-Layout" type="button"
                role="tab" aria-controls="A-Layout" aria-selected="false" (click)="changeTab('B')" i18n>B_LAYOUT</button>
      </li>
    </ul>
    <div class="tab-content" id="myTabContent" style="border: 1px solid black">
      <div class="tab-pane fade show active " id="A-Layout" role="tabpanel" aria-labelledby="ALayout" tabindex="0">
        <div class="row" style="margin: 0;">

          <!--======== LEFT COL ========-->
          <div class="col-sm-4">
            <!--EQUIP-->
            <div class="card" style="margin: 104px 0 4px 0 ">
              <div class="card-header" style="padding: 2px 8px;" i18n>
                EQUIP
              </div>
              <div class="card-body" style="padding:4px">
                <select class="form-select" style="padding:0"
                        [(ngModel)]="selectedEquipId"
                        (ngModelChange)="designChanged('EQUIP')"
                        [disabled]="selectedTab == 'B'">
                  <option *ngFor="let s of selectEquip" [value]="s.id">
                    {{s.name}}
                  </option>
                </select>
                <select class="form-select" style="padding: 0; margin-top:4px"
                        [(ngModel)]="selectedDesign.design.equipmentId"
                        (ngModelChange)="designChanged('EQUIP-LVL')"
                        [disabled]="selectEquipLevels.length == 0 || selectedTab == 'B'">
                  <option *ngFor="let s of selectEquipLevels" [value]="s.id">
                    {{s.name}}
                  </option>
                </select>
              </div>
            </div>

            <!--HAND ONE A-->
            <div class="card" style="margin:12px 0" *ngIf="selectedTab == 'A'">
              <div class="card-header" style="padding: 2px 8px;" i18n>
                HAND_ONE
              </div>
              <div class="card-body" style="padding:4px">
                <select class="form-select" style="padding:0"
                        [(ngModel)]="selectedHandOneAId"
                        (ngModelChange)="designChanged('HANDONE')">
                  <option *ngFor="let s of selectHandOneA" [value]="s.id">
                    {{s.name}}
                  </option>
                </select>
                <select class="form-select" style="padding: 0; margin-top:4px"
                        [(ngModel)]="selectedDesign.design.hand1AId"
                        (ngModelChange)="designChanged('HANDONE-LVL')"
                        [disabled]="selectHandOneALevels.length == 0">
                  <option *ngFor="let s of selectHandOneALevels" [value]="s.id">
                    {{s.name}}
                  </option>
                </select>
              </div>
            </div>
            <!--HAND ONE B-->
            <div class="card" style="margin:12px 0" *ngIf="selectedTab == 'B'">
              <div class="card-header" style="padding: 2px 8px;" i18n>
                HAND_ONE
              </div>
              <div class="card-body" style="padding:4px">
                <select class="form-select" style="padding:0"
                        [(ngModel)]="selectedHandOneBId"
                        (ngModelChange)="designBChanged('HANDONE')">
                  <option *ngFor="let s of selectHandOneB" [value]="s.id">
                    {{s.name}}
                  </option>
                </select>
                <select class="form-select" style="padding: 0; margin-top:4px"
                        [(ngModel)]="selectedDesign.design.hand1BId"
                        (ngModelChange)="designBChanged('HANDONE-LVL')"
                        [disabled]="selectHandOneBLevels.length == 0">
                  <option *ngFor="let s of selectHandOneBLevels" [value]="s.id">
                    {{s.name}}
                  </option>
                </select>
              </div>
            </div>

            <!--BELT A-->
            <div class="card" style="margin: 12px 0" *ngIf="selectedTab == 'A'">
              <div class="card-header" style="padding: 2px 8px;" i18n>
                BELT
              </div>
              <div class="card-body" style="padding:4px">
                <select class="form-select" style="padding:0"
                        [(ngModel)]="selectedBeltAId"
                        (ngModelChange)="designChanged('BELT')">
                  <option *ngFor="let s of selectBeltA" [value]="s.id">
                    {{s.name}}
                  </option>
                </select>
                <select class="form-select" style="padding: 0; margin-top:4px"
                        [(ngModel)]="selectedDesign.design.beltAId"
                        (ngModelChange)="designChanged('BELT-LVL')"
                        [disabled]="selectBeltALevels.length == 0">
                  <option *ngFor="let s of selectBeltALevels" [value]="s.id">
                    {{s.name}}
                  </option>
                </select>
              </div>
            </div>
            <!--BELT B-->
            <div class="card" style="margin: 12px 0" *ngIf="selectedTab == 'B'">
              <div class="card-header" style="padding: 2px 8px;" i18n>
                BELT
              </div>
              <div class="card-body" style="padding:4px">
                <select class="form-select" style="padding:0"
                        [(ngModel)]="selectedBeltBId"
                        (ngModelChange)="designBChanged('BELT')">
                  <option *ngFor="let s of selectBeltB" [value]="s.id">
                    {{s.name}}
                  </option>
                </select>
                <select class="form-select" style="padding: 0; margin-top:4px"
                        [(ngModel)]="selectedDesign.design.beltBId"
                        (ngModelChange)="designBChanged('BELT-LVL')"
                        [disabled]="selectBeltBLevels.length == 0">
                  <option *ngFor="let s of selectBeltBLevels" [value]="s.id">
                    {{s.name}}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <!--======== MID COL ========-->
          <div class="col-sm-4">
            <!--HELMET-->
            <div class="card" style="margin:4px 0">
              <div class="card-header" style="padding: 2px 8px;" i18n>
                HELM
              </div>
              <div class="card-body" style="padding:4px">
                <select class="form-select" style="padding:0"
                        [(ngModel)]="selectedHelmId"
                        (ngModelChange)="designChanged('HELM')"
                        [disabled]="selectedTab == 'B'">
                  <option *ngFor="let s of selectHelm" [value]="s.id">
                    {{s.name}}
                  </option>
                </select>
                <select class="form-select" style="padding: 0; margin-top:4px"
                        [(ngModel)]="selectedDesign.design.helmId"
                        (ngModelChange)="designChanged('HELM-LVL')"
                        [disabled]="selectHelmLevels.length == 0 || selectedTab == 'B'">
                  <option *ngFor="let s of selectHelmLevels" [value]="s.id">
                    {{s.name}}
                  </option>
                </select>
              </div>
            </div>

            <!--IMAGE-->
            <div style="border: 1px solid black">
              <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d4/Simple_Stick_Figure.svg/1200px-Simple_Stick_Figure.svg.png" alt=""
                   style="width: 100%;   max-width: 300px;   height: auto;" />
            </div>

            <!--BOOTS-->
            <div class="card" style="margin:4px 0">
              <div class="card-header" style="padding: 2px 8px;" i18n>
                BOOTS
              </div>
              <div class="card-body" style="padding:4px">
                <select class="form-select" style="padding:0"
                        [(ngModel)]="selectedBootsId"
                        (ngModelChange)="designChanged('BOOTS')"
                        [disabled]="selectedTab == 'B'">
                  <option *ngFor="let s of selectBoots" [value]="s.id">
                    {{s.name}}
                  </option>
                </select>
                <select class="form-select" style="padding: 0; margin-top:4px"
                        [(ngModel)]="selectedDesign.design.bootsId"
                        (ngModelChange)="designChanged('BOOTS-LVL')"
                        [disabled]="selectBootsLevels.length == 0 || selectedTab == 'B'">
                  <option *ngFor="let s of selectBootsLevels" [value]="s.id">
                    {{s.name}}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <!--======== RIGHT COL ========-->
          <div class="col-sm-4">
            <!--BACK A-->
            <div class="card" style="margin:104px 0 4px 0" *ngIf="selectedTab == 'A'">
              <div class="card-header" style="padding: 2px 8px;" i18n>
                BACK
              </div>
              <div class="card-body" style="padding:4px">
                <select class="form-select" style="padding:0"
                        [(ngModel)]="selectedBackAId"
                        (ngModelChange)="designChanged('BACK')">
                  <option *ngFor="let s of selectBackA" [value]="s.id">
                    {{s.name}}
                  </option>
                </select>
                <select class="form-select" style="padding: 0; margin-top:4px"
                        [(ngModel)]="selectedDesign.design.backAId"
                        (ngModelChange)="designChanged('BACK-LVL')"
                        [disabled]="selectBackALevels.length == 0">
                  <option *ngFor="let s of selectBackALevels" [value]="s.id">
                    {{s.name}}
                  </option>
                </select>
              </div>
            </div>
            <!--BACK B-->
            <div class="card" style="margin:104px 0 4px 0" *ngIf="selectedTab == 'B'">
              <div class="card-header" style="padding: 2px 8px;" i18n>
                BACK
              </div>
              <div class="card-body" style="padding:4px">
                <select class="form-select" style="padding:0"
                        [(ngModel)]="selectedBackBId"
                        (ngModelChange)="designBChanged('BACK')">
                  <option *ngFor="let s of selectBackB" [value]="s.id">
                    {{s.name}}
                  </option>
                </select>
                <select class="form-select" style="padding: 0; margin-top:4px"
                        [(ngModel)]="selectedDesign.design.backBId"
                        (ngModelChange)="designBChanged('BACK-LVL')"
                        [disabled]="selectBackBLevels.length == 0">
                  <option *ngFor="let s of selectBackBLevels" [value]="s.id">
                    {{s.name}}
                  </option>
                </select>
              </div>
            </div>

            <!--HAND TWO A-->
            <div class="card" style="margin:12px 0" *ngIf="selectedTab == 'A'">
              <div class="card-header" style="padding: 2px 8px;" i18n>
                HAND_TWO
              </div>
              <div class="card-body" style="padding:4px">
                <select class="form-select" style="padding:0"
                        [(ngModel)]="selectedHandTwoAId"
                        (ngModelChange)="designChanged('HANDTWO')"
                        [disabled]="handTwoBlockedA">
                  <option *ngFor="let s of selectHandTwoA" [value]="s.id">
                    {{s.name}}
                  </option>
                </select>
                <select class="form-select" style="padding: 0; margin-top:4px"
                        [(ngModel)]="selectedDesign.design.hand2AId"
                        (ngModelChange)="designChanged('HANDTWO-LVL')"
                        [disabled]="selectHandTwoALevels.length == 0 || handTwoBlockedA">
                  <option *ngFor="let s of selectHandTwoALevels" [value]="s.id">
                    {{s.name}}
                  </option>
                </select>
              </div>
            </div>
            <!--HAND TWO B-->
            <div class="card" style="margin:12px 0" *ngIf="selectedTab == 'B'">
              <div class="card-header" style="padding: 2px 8px;" i18n>
                HAND_TWO
              </div>
              <div class="card-body" style="padding:4px">
                <select class="form-select" style="padding:0"
                        [(ngModel)]="selectedHandTwoBId"
                        (ngModelChange)="designBChanged('HANDTWO')"
                        [disabled]="handTwoBlockedB">
                  <option *ngFor="let s of selectHandTwoB" [value]="s.id">
                    {{s.name}}
                  </option>
                </select>
                <select class="form-select" style="padding: 0; margin-top:4px"
                        [(ngModel)]="selectedDesign.design.hand2BId"
                        (ngModelChange)="designBChanged('HANDTWO-LVL')"
                        [disabled]="selectHandTwoBLevels.length == 0 || handTwoBlockedB">
                  <option *ngFor="let s of selectHandTwoBLevels" [value]="s.id">
                    {{s.name}}
                  </option>
                </select>
              </div>
            </div>

            <!--CHEST-->
            <div class="card" style="margin:12px 0">
              <div class="card-header" style="padding: 2px 8px;" i18n>
                CHEST
              </div>
              <div class="card-body" style="padding:4px">
                <select class="form-select" style="padding:0"
                        [(ngModel)]="selectedChestId"
                        (ngModelChange)="designChanged('CHEST')"
                        [disabled]="selectedTab == 'B'">
                  <option *ngFor="let s of selectChest" [value]="s.id">
                    {{s.name}}
                  </option>
                </select>
                <select class="form-select" style="padding: 0; margin-top:4px"
                        [(ngModel)]="selectedDesign.design.chestId"
                        (ngModelChange)="designChanged('CHEST-LVL')"
                        [disabled]="selectChestLevels.length == 0 || selectedTab == 'B'">
                  <option *ngFor="let s of selectChestLevels" [value]="s.id">
                    {{s.name}}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="B-Layout" role="tabpanel" aria-labelledby="BLayout" tabindex="1">
        <div class="row" style="margin: 0;">
          <!--left-->
          <div class="col-sm-4" style="border: 1px solid black">
            B
          </div>
          <!--mid-->
          <div class="col-sm-4" style="border: 1px solid black">
            PICTURE<br />OF<br />SOLDIER
          </div>
          <!--right-->
          <div class="col-sm-4" style="border: 1px solid black">
            B
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
