<!-- Toaster -->
<app-toaster></app-toaster>
<!--Spinner-->
<div *ngIf="showSpinner$ | async as showSpinner">
  <app-spinner *ngIf="showSpinner"></app-spinner>
</div>

<!--NAVBAR MAIN-->
<nav class="navbar navbar-expand-lg navbar-dark bg-dark" [ngClass]="{ menuCollapsed:isGame == true }">
  <div class="container">
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" routerLink="main" i18n>MAIN</a>
        </li>
        <!--NOT LOGGED IN-->
        <li class="nav-item" *ngIf="!loggedIn">
          <input [(ngModel)]="user.username" placeholder="Name/Email" />
        </li>
        <li class="nav-item" *ngIf="!loggedIn">
          <input [(ngModel)]="user.password" placeholder="Password" type="password" />
        </li>
        <li class="nav-item" *ngIf="!loggedIn">
          <button (click)="login(user)" i18n>LOGIN</button>
        </li>
        <li class="nav-item" *ngIf="!loggedIn">
          <button (click)="resetPassword(user)" i18n>
            RESETPW
          </button>
        </li>
        <li class="nav-item" *ngIf="!loggedIn">
          <a class="nav-link active" aria-current="page" routerLink="reg" i18n>REGISTER</a>
        </li>
        <!--LOGGED IN-->
        <li class="nav-item" *ngIf="loggedIn">
          <a class="nav-link active" aria-current="page" routerLink="home" i18n>HOME</a>
        </li>
        <li class="nav-item" *ngIf="loggedIn">
          <a class="nav-link active" aria-current="page" routerLink="game/start" i18n>GAME</a>
        </li>
        <li class="nav-item" *ngIf="loggedIn">
          <button (click)="logout()" i18n>LOGOUT</button>
        </li>
        <li class="nav-item" *ngIf="loggedIn">
          <a class="nav-link active" aria-current="page" routerLink="settings" i18n>SETTINGS</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
<!--NAVBAR GAME-->
<div class="container">
  <!--========== HOME ==========-->
  <div *ngIf="!isGame" class="row">
    <div class="box col col-sm-12">
      <router-outlet></router-outlet>
    </div>
  </div>

  <!--========== GAME ==========-->
  <div *ngIf="isGame" class="row">
    <div class="box col col-sm-1" style="padding:0">

      <div class="box">
        <app-static-data></app-static-data>
        <app-tick-box></app-tick-box>
      </div>

      <div class="row" style="margin-top: 6px; margin-left:6px">
        <button class="menuButton" [innerHTML]="overviewIcon" [ngClass]="{ active:url == 'game/start' }" aria-current="page" routerLink="game/start" i18n-title title="TOOLTIP_OVERVIEW"></button>
      </div>
      <div class="row" style="margin-top: 6px; margin-left:6px">
        <button class="menuButton" [innerHTML]="scienceIcon" [ngClass]="{ active:url == 'game/science' }" aria-current="page" routerLink="game/science" i18n-title title="TOOLTIP_SCIENCE"></button>
      </div>
      <div class="row" style="margin-top: 6px; margin-left:6px">
        <button class="menuButton" [innerHTML]="inventoryIcon" [ngClass]="{ active:url == 'game/inventory' }" aria-current="page" routerLink="game/inventory" i18n-title title="TOOLTIP_INVENTORY"></button>
      </div>
      <div class="row" style="margin-top: 6px; margin-left:6px">
        <button class="menuButton" [innerHTML]="designIcon" [ngClass]="{ active:url == 'game/design' }" aria-current="page" routerLink="game/design" i18n-title title="TOOLTIP_DESIGN"></button>
      </div>

      <div class="row" style="margin-top: 6px; margin-left:6px">
        <button class="menuButton" [innerHTML]="mapIcon" [ngClass]="{ active:url == 'game/map' }" aria-current="page" routerLink="game/map" i18n-title title="TOOLTIP_MAP"></button>
      </div>

      <div class="row" style="margin-top: 6px; margin-left:6px">
        <button class="menuButton" [innerHTML]="allianceIcon" [ngClass]="{ active:url == 'game/soon' }" aria-current="page" routerLink="game/soon" i18n-title title="TOOLTIP_ALLIANCE"></button>
      </div>
      <div class="row" style="margin-top: 6px; margin-left:6px">
        <button class="menuButton" [innerHTML]="rankingIcon" [ngClass]="{ active:url == 'game/soon' }" aria-current="page" routerLink="game/soon" i18n-title title="TOOLTIP_RANKING"></button>
      </div>
    </div>
    <div class="box col col-sm-11" style="padding:0">
      <div class="row" style="margin: 0;">
        <div class="box col col-sm-9" style="padding:0">
          <div class="box">
            <app-resource-box></app-resource-box>
          </div>
          <div class="box">
            <router-outlet></router-outlet>
          </div>
        </div>
        <div class="col col-sm-3" style="padding:0">
          <div class="row" style="margin: 0;">
            <!--<div class="box col col-sm-9" style="padding: 0;">
              <app-message-box></app-message-box>
            </div>
            <div class="box col col-sm-3">
              <app-tick-box></app-tick-box>
            </div>-->
            <div class="box col col-sm-12" style="padding: 0;">
              <app-message-box></app-message-box>
            </div>
          </div>
          <div class="row" style="margin: 0;">
            <div class="box">
              <app-event-box></app-event-box>
            </div>
            <div class="box">
              <app-village-box></app-village-box>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>








<style>
  .box {
    border: solid 1px black;
  }

  .vertical-menu {
    width: 100%; /* Set a width if you like */
  }

    .vertical-menu a {
      background-color: #eee; /* Grey background color */
      color: black; /* Black text color */
      display: block; /* Make the links appear below each other */
      padding: 12px; /* Add some padding */
      text-decoration: none; /* Remove underline from links */
    }

      .vertical-menu a:hover {
        background-color: #ccc; /* Dark grey background on mouse-over */
      }

      .vertical-menu a.active {
        background-color: #ccc;
        font-weight: bold;
      }


  .row button.active {
    background-color: #ccc !important;
  }

  .row button.menuButton {
    font-size: 48px;
    width: 84px;
    border-radius: 48px;
    padding: 0 8px 8px 8px;
    background-color: white;
    border: black solid 2px;
  }

  .menuCollapsed {
    margin-top: -40px;
    padding-bottom: 0;
  }

  nav:hover {
    margin-top: 0px !important;
    padding-bottom: 8px !important;
  }
</style>
