import { Component, OnInit } from '@angular/core';
import { SpinnerService } from '../../../services/spinner.service';
import { ToastService } from '../../../services/toast.service';
import { DataService } from '../../../services/data.service';
import { TranslationService } from '../../../services/translation.service';
import { VillageResult, MapObject, MapField, SlotTypeBuilding, SlotObject, BuildingTypeAndLevelObject, FieldNation, ErrorGroup, BlockedBuilding } from '../../../models/village-models';
import { BuildingType, BuildingTypeLevel, MapFieldType, Nation } from '../../../models/staticData-models';
import { VillageService } from '../../../services/data/village.service';
import { Router } from '@angular/router';
import { Resources } from '../../../models/box-models';
import { NameValue } from '../../../models/pair-models';

@Component({
  selector: 'app-village',
  templateUrl: './village.component.html',
  styleUrls: ['./village.component.css']
})
export class VillageComponent implements OnInit {
  villageResult: VillageResult;
  buildingTypes: BuildingType[];
  buildingTypeLevels: BuildingTypeLevel[];
  mapFieldTypes: MapFieldType[];
  nations: Nation[];
  slotTypeBuilding: SlotTypeBuilding[];
  villageId: number = -1;
  map: MapObject[][] = [];
  slots: SlotObject[] = [];
  villageIcon = "&#127969;";
  woodIcon = "&#129683;";
  stoneIcon = "&#9935;";
  ironIcon = "&#128296;";
  foodIcon = "&#127859;";
  clockIcon = "&#128337;";
  selectedMapFieldId: number | null;
  selectedMapFieldFieldType: MapFieldType | null;
  modalMapFieldType: number | null;
  modalData: any;
  showAllBuildings: boolean = false;
  resData: Resources | null = null;
  buildingsForDisplay: BuildingTypeAndLevelObject[] = [];
  fileTypeName: string = "";
  fieldNations: FieldNation[] = [];

  constructor(private toastService: ToastService, private villageService: VillageService, private spinnerService: SpinnerService,
    private translationService: TranslationService, private dataService: DataService, private router: Router) {
    this.villageResult = {
      slots: [],
      blockedBuildings: [],
      mapFields: [],
      village: {
        id: 0,
        isCapital: false,
        constructionAllowed: true,
        LevelFlags: 0,
        maxFieldCount: 0,
        name: "",
        x: 0,
        y: 0,
        ownersFlagPosition: 0
      }
    }
    this.buildingTypes = [];
    this.buildingTypeLevels = [];
    this.mapFieldTypes = [];
    this.nations = [];
    this.slotTypeBuilding = [];
    this.selectedMapFieldId = null;
    this.modalMapFieldType = null;
    this.selectedMapFieldFieldType = null;
  }


  ngOnInit() {
    this.dataService._broadcastSource$.subscribe(broadcast => {
      //TODO change to checkservice
      if (broadcast == "CHANGED-VILLAGE" || broadcast == "LOAD-VILLAGE") {
        this.getVillageData(false)
      }

    })

    //getstaticdata
    this.getStaticData(0);
    //this.getVillageData(true);
  }

  returnToStart(reason: string) {
    this.router.navigate(['game/start']);
  }

  //====== PREPARE DATA ======
  buildDisplayObjects() {
    if (this.villageResult.mapFields.length === 0 || this.buildingTypes.length === 0)
      return;

    var claimedFieldCount = 0;

    //==== map ====
    var dummy: MapObject = { mapfieldId: null, villageId: null, x: null, y: null, hasTown: false, fieldTypeId: null, claimable: false, classes: "", relationType: null, nationFlags: 0 }
    this.map = [[dummy, dummy, dummy, dummy, dummy, dummy, dummy],
    [dummy, dummy, dummy, dummy, dummy, dummy, dummy], [dummy, dummy, dummy, dummy, dummy, dummy, dummy],
    [dummy, dummy, dummy, dummy, dummy, dummy, dummy], [dummy, dummy, dummy, dummy, dummy, dummy, dummy],
    [dummy, dummy, dummy, dummy, dummy, dummy, dummy], [dummy, dummy, dummy, dummy, dummy, dummy, dummy]];
    var centerX = this.villageResult.village.x;
    var centerY = this.villageResult.village.y;
    //map[Y][X]
    for (var i = 0; i < this.villageResult.mapFields.length; i++) {
      var mfo = this.createMapFieldObject(this.villageResult.mapFields[i]);
      if (mfo != null) {
        var difX: number | null = null;
        var difY: number | null = null;
        if (centerX != null && mfo.x != null)
          difX = mfo.x - centerX;
        if (centerY != null && mfo.y != null)
          difY = centerY - mfo.y;
        if (difX != null && difY != null)
          this.map[3 + difY][3 + difX] = mfo;
        if (mfo.villageId == this.villageId)
          claimedFieldCount++;
      }
    }

    for (var y = 0; y < 7; y++) {
      //for (var y = 6; y >= 0; y--) {
      for (var x = 0; x < 7; x++) {

        //check top
        if (y > 0 && this.map[y][x].villageId && this.map[y][x].villageId != this.map[y - 1][x].villageId) {
          if (this.map[y][x].relationType !== undefined && this.map[y][x].relationType !== null)
            this.map[y][x].classes += " bt_" + this.map[y][x].relationType;
          else
            this.map[y][x].classes += " bt_x";
        }
        //check right
        if (x < 6 && this.map[y][x].villageId && this.map[y][x].villageId != this.map[y][x + 1].villageId) {
          if (this.map[y][x].relationType !== undefined && this.map[y][x].relationType !== null)
            this.map[y][x].classes += " br_" + this.map[y][x].relationType;
          else
            this.map[y][x].classes += " br_x";
        }
        //check bottom
        if (y < 6 && this.map[y][x].villageId && this.map[y][x].villageId != this.map[y + 1][x].villageId) {
          if (this.map[y][x].relationType !== undefined && this.map[y][x].relationType !== null)
            this.map[y][x].classes += " bb_" + this.map[y][x].relationType;
          else
            this.map[y][x].classes += " bb_x";
        }
        //check left
        if (x > 0 && this.map[y][x].villageId && this.map[y][x].villageId != this.map[y][x - 1].villageId) {
          if (this.map[y][x].relationType !== undefined && this.map[y][x].relationType !== null)
            this.map[y][x].classes += " bl_" + this.map[y][x].relationType;
          else
            this.map[y][x].classes += " bl_x";
        }

        //claimable
        if (!this.map[y][x].villageId) {
          var villageHasCapacity = claimedFieldCount < this.villageResult.village.maxFieldCount;
          //var nationIsAllowed = (this.map[y][x].nationFlags & this.villageResult.village.ownersFlagPosition) > 0
          var nationIsAllowed = this.map[y][x].nationFlags !== 0;

          if (villageHasCapacity && nationIsAllowed && x > 0 && this.villageId == this.map[y][x - 1].villageId)
            this.map[y][x].claimable = true;
          if (villageHasCapacity && nationIsAllowed && y > 0 && this.villageId == this.map[y - 1][x].villageId)
            this.map[y][x].claimable = true;
          if (villageHasCapacity && nationIsAllowed && x < 6 && this.villageId == this.map[y][x + 1].villageId)
            this.map[y][x].claimable = true;
          if (villageHasCapacity && nationIsAllowed && y < 6 && this.villageId == this.map[y + 1][x].villageId)
            this.map[y][x].claimable = true;

          if (this.map[y][x].claimable == true)
            this.map[y][x].classes += " b_cl";
          else
            this.map[y][x].classes += " b_x";
        }
      }
    }

    //==== level1s ====
    this.slotTypeBuilding = [];

    for (var i = 0; i < this.buildingTypeLevels.length; i++) {
      if (this.buildingTypeLevels[i].level == 1) {
        //get buildingtype
        var buildingType = this.buildingTypes.find(bt => bt.id === this.buildingTypeLevels[i].buildingTypeId);

        //get blocked
        var blockedEntries: BlockedBuilding[] = [];
        for (var j = 0; j < this.villageResult.blockedBuildings.length; j++) {
          if ((this.villageResult.blockedBuildings[j].buildingTypeLevelId == this.buildingTypeLevels[i].id)
            || (this.villageResult.blockedBuildings[j].isGeneral && this.villageResult.blockedBuildings[j].buildingTypeId == this.buildingTypeLevels[i].buildingTypeId))
            blockedEntries.push(this.villageResult.blockedBuildings[j]);
        }
        //check for capital
        var capitalConflict = false;
        if (buildingType) {
          //check for capital
          var capitalConflict = false;
          if (buildingType.capitalOnly && !this.villageResult.village.isCapital)
            capitalConflict = true;
          //check for village levels
          var levelVillageConflict = "";
          if (buildingType.requiredVillageLevelFlag && (buildingType.requiredVillageLevelFlag & this.villageResult.village.LevelFlags) == 0)
            levelVillageConflict = "VILLAGE_LEVEL_NOT_" + buildingType.requiredVillageLevelFlag;

          if ((buildingType.nationFlag & this.villageResult.village.ownersFlagPosition) > 0) {
            //check if slottype allready exists
            var existingSlot = this.slotTypeBuilding.find(es => es.slotType == buildingType?.slotType)
            if (existingSlot == null) {
              existingSlot = { slotType: buildingType?.slotType, possibleBuildings: [], allBuildings: [] }
              this.slotTypeBuilding.push(existingSlot);
            }

            if (this.villageResult.village.constructionAllowed || buildingType.name == "MAINBUILDING") {
              if (blockedEntries.length == 0 && !capitalConflict && levelVillageConflict == "") {
                //possible => not blocked
                var buildingTypeAndLevelObject = this.createBuildingTypeAndLevelObject(null, this.buildingTypeLevels[i], []);
                if (buildingTypeAndLevelObject != null) {
                  existingSlot.possibleBuildings.push(buildingTypeAndLevelObject);
                  existingSlot.allBuildings.push(buildingTypeAndLevelObject);
                }
              }
              else {
                //not possible == blocked
                var errorGroups: ErrorGroup[] = this.getErrorGroupsFromBlockedEntries(blockedEntries);

                var generalErrorGroup: ErrorGroup = { group: null, messages: [] };
                if (capitalConflict)
                  generalErrorGroup.messages.push(this.translationService.getBlockedMessages("CAPITAL_ONLY"));
                if (levelVillageConflict != "")
                  generalErrorGroup.messages.push(this.translationService.getBlockedMessages(levelVillageConflict));
                if (generalErrorGroup.messages.length > 0)
                  errorGroups.push(generalErrorGroup);
                var buildingTypeAndLevelObject = this.createBuildingTypeAndLevelObject(null, this.buildingTypeLevels[i], errorGroups);
                if (buildingTypeAndLevelObject != null)
                  existingSlot.allBuildings.push(buildingTypeAndLevelObject);
              }
            }
            else //??? NO MAINBUILDING
            {

            }
          }
        }
      }
    }
  }

  createMapFieldObject(mapfield: MapField): MapObject | null {
    var fieldType = this.mapFieldTypes.find(f => f.id === mapfield.mapFieldTypeId);
    if (!fieldType)
      return null;
    var result: MapObject = {
      mapfieldId: mapfield.id,
      villageId: mapfield.villageId,
      x: mapfield.x,
      y: mapfield.y,
      hasTown: mapfield.containsTown,
      fieldTypeId: mapfield.mapFieldTypeId,
      claimable: false,
      // classes: { "border-top-1" :true}
      classes: "",
      relationType: mapfield.relationType,
      nationFlags: fieldType.allowedNationsFlag
    };
    result.classes = "ft_" + fieldType.name
    return result;
  }

  createBuildingTypeAndLevelObject(currentLevel: BuildingTypeLevel | null, nextLevel: BuildingTypeLevel | null, errorGroups: ErrorGroup[]): BuildingTypeAndLevelObject | null {
    var buildingType = this.buildingTypes.find(bt => (nextLevel != null && bt.id == nextLevel.buildingTypeId) || (currentLevel != null && bt.id == currentLevel.buildingTypeId));
    //var buildingType = this.buildingTypes.find(bt => bt.id == nextLevel.buildingTypeId);
    if (!buildingType)
      return null;
    var currentLevelId: number | null = null;
    var currentLevelLevel: number | null = null;
    var effectsNow: NameValue[] = [];
    if (currentLevel) {
      currentLevelId = currentLevel.id;
      effectsNow = currentLevel.effectData;
      currentLevelLevel = currentLevel.level;
    }
    var name = this.translationService.getBuildingName(buildingType.name);
    var description = this.translationService.getBuildingDescription(buildingType.name, []);
    var alloweBuilding = errorGroups.length == 0;
    var result: BuildingTypeAndLevelObject = {
      buildingTicks: 0,
      buildingTypeId: buildingType.id,
      costFood: 0,
      costIron: 0,
      costStone: 0,
      costWood: 0,
      currentLevelId: currentLevelId,
      description: description,
      name: name,
      effectDataNow: effectsNow,
      effectDataNext: [],
      nextLevelId: null,
      levelNext: null,
      levelNow: currentLevelLevel,
      errorGroups: errorGroups,
      allowBuilding: alloweBuilding
    };
    if (nextLevel != null) {
      result.buildingTicks = nextLevel.buildingTicks;
      result.costFood = nextLevel.costFood;
      result.costIron = nextLevel.costIron;
      result.costStone = nextLevel.costStone;
      result.costWood = nextLevel.costWood;
      result.effectDataNext=nextLevel.effectData;
      result.nextLevelId = nextLevel.id;
      result.levelNext = nextLevel.level;
    }
    for (var i = 0; i < result.effectDataNow.length; i++)
      result.effectDataNow[i].name = this.translationService.getEffectName(result.effectDataNow[i].name);
    for (var i = 0; i < result.effectDataNext.length; i++)
      result.effectDataNext[i].name = this.translationService.getEffectName(result.effectDataNext[i].name);
    return result;
  }

  getErrorGroupsFromBlockedEntries(blockedEntries: BlockedBuilding[]): ErrorGroup[] {
    var result: ErrorGroup[] = [];
    for (var i = 0; i < blockedEntries.length; i++) {
      var grouToAdd: ErrorGroup = { group: blockedEntries[i].requirementGroup, messages: [] };
      var errorSplits = blockedEntries[i].messageKeys.split(";");
      for (var j = 0; j < errorSplits.length; j++)
        grouToAdd.messages.push(this.translationService.getBlockedMessages(errorSplits[j]));
      result.push(grouToAdd)
    }
    return result;
  }

  //====== GET DATA ======
  getStaticData(counter: number) {
    var version = this.dataService.getStaticDataVersion();
    if (!version || version.length == 0) {
      if (counter < 10) {
        counter++;
        setTimeout(() => {
          this.getStaticData(counter);
        }, 200);
      }
      else
        this.returnToStart("no staticData");
    }

    //clear data
    this.buildingTypes = [];
    this.buildingTypeLevels = [];
    this.mapFieldTypes = [];
    this.nations = [];

    var tmpString = localStorage.getItem(version + '-buildings');
    if (tmpString != null)
      this.buildingTypes = JSON.parse(tmpString);

    tmpString = localStorage.getItem(version + '-buildingLevels');
    if (tmpString != null)
      this.buildingTypeLevels = JSON.parse(tmpString);

    tmpString = localStorage.getItem(version + '-mapFields');
    if (tmpString != null)
      this.mapFieldTypes = JSON.parse(tmpString);

    tmpString = localStorage.getItem(version + '-nations');
    if (tmpString != null)
      this.nations = JSON.parse(tmpString);

    if (version || version.length != 0)
      this.getVillageData(true);
  }

  getVillageData(onInit: boolean) {
    //get villageId
    this.villageId = this.dataService.getSelectedVillageId();
    if (!this.villageId || this.villageId == -1) {
      if (onInit)
        return;
      else
        this.returnToStart("no villageId");
    }

    this.spinnerService.addToLoading();
    this.villageService.getVillageData(this.villageId).subscribe((result: VillageResult) => {
      this.villageResult = result;
      this.buildDisplayObjects();
      this.spinnerService.removeFromLoading();
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.spinnerService.removeFromLoading();
    });
  }

  //====== INTERACTION ======
  clickField(field: MapObject, slotType: number | null) {
    this.selectedMapFieldId = field.mapfieldId;
    //get basic field info

    var fieldType = this.mapFieldTypes.find(ft => ft.id == field.fieldTypeId);
    if (!fieldType)
      return;
    this.selectedMapFieldFieldType = fieldType;
    this.fileTypeName = this.translationService.getFieldType(this.selectedMapFieldFieldType.name);

    //NATIONS
    this.fieldNations = [];
    for (var i = 0; i < this.nations.length; i++) {
      var state = 3;
      if ((this.nations[i].flagPosition & fieldType.bigBonusFlag) > 0)
        state = 1;
      else if ((this.nations[i].flagPosition & fieldType.smallBonusFlag) > 0)
        state = 2;
      else if ((this.nations[i].flagPosition & fieldType.malusFlag) > 0)
        state = 4;
      this.fieldNations.push({ name: this.translationService.getNationName(this.nations[i].name), state: state });
    }

    //get mapfield get type (1 just details, 2 claimable, 3 build res buildings, 4 other village)
    this.modalMapFieldType = 1;

    if (field.claimable) {
      //claimable
      this.modalMapFieldType = 2;
    }
    else if (field.villageId == this.villageId) {// && !field.hasTown) {
      //build
      this.modalMapFieldType = 3;
      this.resData = this.dataService.getResourceData();
      if (!this.resData || this.resData == null)
        return;
      //get slots with building
      this.slots = [];
      for (var i = 0; i < this.villageResult.slots.length; i++) {
        if (this.villageResult.slots[i].mapFieldId == field.mapfieldId && (!slotType || this.villageResult.slots[i].slotTypeId == slotType)) {
          if (this.villageResult.slots[i].buildingLevelId == null) {
            //empty slot
            var name = this.translationService.getSlotType(this.villageResult.slots[i].slotTypeId)
            var slotToAdd: SlotObject = { slotType: slotType, buildingLevelAndType: null, slotId: this.villageResult.slots[i].id, upgradeable: false, slotTypeName: name, underConstruction: null, isOpen: false }
            this.slots.push(slotToAdd)
          }
          else {
            //slot with building            
            var buildingTypeLevel = this.buildingTypeLevels.find(btl => btl.id == this.villageResult.slots[i].buildingLevelId);
            var buildingType = this.buildingTypes.find(bt => bt.id === buildingTypeLevel?.buildingTypeId)
            if (buildingTypeLevel && buildingType) {
              var upgradeable = false;

              if (this.villageResult.village.constructionAllowed) {
                //underconstruction
                var levelToAdd = 1;
                if (this.villageResult.slots[i].underConstruction)
                  levelToAdd += this.villageResult.slots[i].underConstruction;
                var nextLevel = this.buildingTypeLevels.find(btl => btl.buildingTypeId == buildingTypeLevel?.buildingTypeId && btl.level == buildingTypeLevel?.level + levelToAdd);
                if (nextLevel) {
                  var errorGroups: ErrorGroup[] = [];

                  //CHECKS
                  var blockedEntries: BlockedBuilding[] = [];
                  for (var j = 0; j < this.villageResult.blockedBuildings.length; j++) {
                    if ((this.villageResult.blockedBuildings[j].buildingTypeLevelId == this.buildingTypeLevels[i].id)
                      || (this.villageResult.blockedBuildings[j].isGeneral && this.villageResult.blockedBuildings[j].buildingTypeId == this.buildingTypeLevels[i].buildingTypeId))
                      blockedEntries.push(this.villageResult.blockedBuildings[j]);
                  }
                  //check for village levels
                  var levelVillageConflict = "";
                  if (buildingType.requiredVillageLevelFlag && (buildingType.requiredVillageLevelFlag & this.villageResult.village.LevelFlags) == 0)
                    levelVillageConflict = "VILLAGE_LEVEL_NOT_" + buildingType.requiredVillageLevelFlag;

                  else {
                    errorGroups = this.getErrorGroupsFromBlockedEntries(blockedEntries);
                    var generalErrorGroup: ErrorGroup = { group: null, messages: [] };
                    if (levelVillageConflict != "")
                      generalErrorGroup.messages.push(this.translationService.getBlockedMessages(levelVillageConflict));
                    if (generalErrorGroup.messages.length > 0)
                      errorGroups.push(generalErrorGroup);
                  }
                  var name = this.translationService.getSlotType(this.villageResult.slots[i].slotTypeId)
                  var buildingTypeAndLevelObject = this.createBuildingTypeAndLevelObject(buildingTypeLevel, nextLevel, errorGroups);
                  var slotToAdd: SlotObject = { slotType: slotType, buildingLevelAndType: buildingTypeAndLevelObject, slotId: this.villageResult.slots[i].id, upgradeable: upgradeable, slotTypeName: name, underConstruction: this.villageResult.slots[i].underConstruction, isOpen: false }
                  this.slots.push(slotToAdd)
                }
                else {
                  //no next level found / fully upgraded?
                  var name = this.translationService.getSlotType(this.villageResult.slots[i].slotTypeId)
                  var buildingTypeAndLevelObject = this.createBuildingTypeAndLevelObject(buildingTypeLevel, null, []);
                  var slotToAdd: SlotObject = { slotType: slotType, buildingLevelAndType: buildingTypeAndLevelObject, slotId: this.villageResult.slots[i].id, upgradeable: upgradeable, slotTypeName: name, underConstruction: this.villageResult.slots[i].underConstruction, isOpen: false }
                  this.slots.push(slotToAdd)
                }
              }
            }
          }
        }
      }
      //get possible level 1 buildings for the empty slots



    }
    else if (field.villageId == this.villageId && field.hasTown) {
      //towncenter
      //WHAT TPO DO HERE???
      //    Direct to inner tab?
      //    Just display details
      //    buil resources anyways
    }
    else if (field.villageId) {
      //other village
      this.modalMapFieldType = 4;
      //FOF? 
    }
    else {

    }
  }

  openSlot(slot: SlotObject) {
    var tmp = slot.isOpen
    for (var i = 0; i < this.slots.length; i++)
      this.slots[i].isOpen = false;
    slot.isOpen = !tmp;

    this.buildingsForDisplay = [];
    if (slot.buildingLevelAndType === null) {
      var slotType = this.slotTypeBuilding.find(stb => stb.slotType == slot.slotType)
      if (slotType) {
        if (this.showAllBuildings)
          this.buildingsForDisplay = slotType.allBuildings;
        else
          this.buildingsForDisplay = slotType.possibleBuildings;
      }
    }
  }

  buildingsForDisplayChange(slot: SlotObject) {
    this.buildingsForDisplay = [];
    if (slot.buildingLevelAndType === null) {
      var slotType = this.slotTypeBuilding.find(stb => stb.slotType == slot.slotType)
      if (slotType) {
        if (this.showAllBuildings)
          this.buildingsForDisplay = slotType.allBuildings;
        else
          this.buildingsForDisplay = slotType.possibleBuildings;
      }
    }
  }

  claimMapField() {
    if (this.selectedMapFieldId == null)
      return;
    this.spinnerService.addToLoading();
    this.villageService.claimMapField(this.selectedMapFieldId, this.villageId).subscribe((result: string) => {
      this.toastService.showSuccessToast($localize`COMMON_SUCCESS`, $localize`CLAIMFIELD_SUCCESS`);


      this.getVillageData(false);

      this.spinnerService.removeFromLoading();
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.spinnerService.removeFromLoading();
    });
  }
  constructBuilding(slotId: number, buildingLevelId: number) {
    if (this.villageId == null)
      return;
    this.spinnerService.addToLoading();
    this.villageService.constructBuilding(this.villageId, slotId, buildingLevelId).subscribe((result: string) => {
      this.toastService.showSuccessToast($localize`COMMON_SUCCESS`, $localize`CONSTRUCT_BUILDING_SUCCESS`);

      this.dataService.sendBroadcast('LOAD-RESOURCES');
      this.dataService.sendBroadcast('LOAD-EVENTS');
      this.getVillageData(false);

      this.spinnerService.removeFromLoading();
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.spinnerService.removeFromLoading();
    });
  }

}
