import { Component, OnInit } from '@angular/core';
import { SpinnerService } from '../../../services/spinner.service';
import { ToastService } from '../../../services/toast.service';
import { DataService } from '../../../services/data.service';
import { GameBasicService } from '../../../services/data/game-basic.service';
import { Resources } from '../../../models/box-models';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-resource-box',
  templateUrl: './resource-box.component.html',
  styleUrls: ['./resource-box.component.css']
})
export class ResourceBoxComponent implements OnInit {
  villageId: number = -1;
  currentTick: number = 0;
  lastCalculationTick: number = -1;
  resData: Resources;

  menuFlags: number = -1;
  workshop: boolean = false;
  barracks: boolean = false;
  trader: boolean = false;
  library: boolean = false;

  url = "";
  woodIcon = "&#129683;";
  stoneIcon = "&#9935;";
  ironIcon = "&#128296;";
  foodIcon = "&#127859;";


  villageIcon = "&#127984;";
  administrationIcon = "&#128220;";
  barracksIcon = "&#9876;";
  workshopIcon = "&#9881;";
  tradeIcon = "&#9878;";
  libraryIcon = "&#128218;";

  prodctionString = $localize`COMMON_PRODUCTION`;
  storageString = $localize`COMMON_STORAGE`;

  constructor(private gameBasicService: GameBasicService,
    private dataService: DataService,
    private toastService: ToastService,
    private router: Router,
    private spinnerService: SpinnerService) {
    this.resData = {
      wood: 0,
      woodProduction: 0,
      iron: 0,
      ironProduction: 0,
      stone: 0,
      stoneProduction: 0,
      resourceStorage: 0,
      food: 0,
      foodProduction: 0,
      foodConsumption: 0,
      foodStorage: 0,
      lastCalculationTick: 0
    };
  }

  ngOnInit() {
    this.url = window.location.pathname.substring(1);

    this.dataService._broadcastSource$.subscribe(broadcast => {
      if (broadcast == "CHANGED-VILLAGE" || broadcast == "LOAD-RESOURCES") {
        this.villageId = this.dataService.getSelectedVillageId();
        if (this.villageId && this.villageId > -1) {
          this.lastCalculationTick = -1;
          this.getResources(false, false);
        }
      }
      if (broadcast == "TICK") {
        this.runTick();
      }
      if (broadcast == "LOAD-CALC-RESOURCES") {
        this.villageId = this.dataService.getSelectedVillageId();
        if (this.villageId && this.villageId > -1)
          this.getResources(true, false);
      }
    })
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.url = event.url.substring(1);
        if (this.menuFlags !== -1)
          this.checkUrl();
      }
    });
    this.getResources(false, true);    
  }

  runTick() {
    this.currentTick = this.dataService.getTick();
    var lastCalculationTick = this.lastCalculationTick;
    if (lastCalculationTick == -1)
      lastCalculationTick = this.resData.lastCalculationTick;

    if (this.currentTick !== -1) {
      this.lastCalculationTick = this.currentTick;
      var ticksPassed = this.currentTick - lastCalculationTick;

      //wood
      this.resData.wood = this.resData.wood + (this.resData.woodProduction * ticksPassed)
      if (this.resData.wood > this.resData.resourceStorage)
        this.resData.wood = this.resData.resourceStorage;
      //stone
      this.resData.stone = this.resData.stone + (this.resData.stoneProduction * ticksPassed)
      if (this.resData.stone > this.resData.resourceStorage)
        this.resData.stone = this.resData.resourceStorage;
      //iron
      this.resData.iron = this.resData.iron + (this.resData.ironProduction * ticksPassed)
      if (this.resData.iron > this.resData.resourceStorage)
        this.resData.iron = this.resData.resourceStorage;
      //food
      this.resData.food = this.resData.food + (this.resData.foodProduction * ticksPassed)
      if (this.resData.food > this.resData.foodStorage)
        this.resData.food = this.resData.foodStorage;

      //set in data service
      this.dataService.setResourceData(this.resData);
    }
    else
      this.dataService.clearResourceData();
  }

  returnToStart(reason: string) {
    this.router.navigate(['game/start']);
  }

  getResources(runCalc: boolean, onInit: boolean) {
    this.villageId = this.dataService.getSelectedVillageId();
    if (!this.villageId || this.villageId == -1) {
      if (onInit)
        return;
      else
        this.returnToStart("no villageId");
    }
    this.spinnerService.addToLoading();
    this.gameBasicService.getResources(this.villageId, runCalc).subscribe((result: Resources) => {
      this.resData = result;
      this.getMenuFlags();
      this.runTick();
      this.spinnerService.removeFromLoading();
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.spinnerService.removeFromLoading();
    });
  }

  getMenuFlags() {
    this.gameBasicService.getMenuFlags(this.villageId).subscribe((result: number) => {

      this.workshop = (result & 2) > 0;
      this.barracks = (result & 4) > 0;
      this.trader = (result & 8) > 0;
      this.library = (result & 16) > 0;
      //TODO MORE MENU GOES HERE

      this.checkUrl();
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
    });
  }

  checkUrl() {
    if (!this.workshop && this.url == "game/tech")
      this.router.navigate(['game/start']);

    if (!this.barracks && this.url == "game/barracks")
      this.router.navigate(['game/start']);

    if (!this.trader && this.url == "game/trade")
      this.router.navigate(['game/start']);

    if (!this.library && this.url == "game/library")
      this.router.navigate(['game/start']);

    //TODO MORE MENU GOES HERE
  }
}
