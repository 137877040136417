import { Component, OnInit } from '@angular/core';
import { SpinnerService } from '../../../services/spinner.service';
import { ToastService } from '../../../services/toast.service';
import { DataService } from '../../../services/data.service';
import { GameBasicService } from '../../../services/data/game-basic.service';
import { Startup_JoinRequest } from '../../../models/single/startup_JoinRequest';
import { Startup_Select, Tribe } from '../../../models/single/startup_Select';
import { Startup_Result } from '../../../models/single/startup_Result';
import { TranslationService } from '../../../services/translation.service';

import { ItemType } from '../../../models/staticData-models';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.css']
})
export class StartComponent implements OnInit {
  playerExists = true;
  startup_JoinRequest = new Startup_JoinRequest();
  selectData: Startup_Select;

  tribeOptions: Tribe[] = [];
  itemTypes: ItemType[] = [];
  nationEffects = [{ Name: "", Value: 0 }];
  tribeEffects = [{ Name: "", Value: 0 }];
  statistics = [{ name: "", value: "" }];
  nationDescription = "";
  tribeDescription = "";
  playerName = "";
  modalIsOpen: boolean = false;
  modalItemList: string = "";


  constructor(private gameBasicService: GameBasicService, private toastService: ToastService, private dataService: DataService,
    private translationService: TranslationService, private spinnerService: SpinnerService) {
    this.selectData = {
      availableDirectionsFlag: 0,
      directions: [],
      nations: []
    }
  }

  ngOnInit() {
    this.getStaticData(0);
    this.tribeOptions = [];
  }

  nationChanged() {
    this.tribeOptions = [];
    this.nationEffects = [];
    this.nationDescription = "";
    if (this.startup_JoinRequest && this.startup_JoinRequest.selectedNationId && this.startup_JoinRequest.selectedNationId !== -1) {
      let nation = this.selectData.nations.find(i => i.id == this.startup_JoinRequest.selectedNationId);
      if (nation) {
        this.tribeOptions = nation.tribes;
        this.nationDescription = this.translationService.getNationDescription(nation.name);
        this.nationEffects = JSON.parse(nation.effectData);
        for (var i = 0; i < this.nationEffects.length; i++)
          this.nationEffects[i].Name = this.translationService.getEffectName(this.nationEffects[i].Name);
      }
    }
  }

  tribeChanged() {
    this.tribeEffects = [];
    this.tribeDescription = "";
    if (this.startup_JoinRequest && this.startup_JoinRequest.selectedTribeId && this.startup_JoinRequest.selectedTribeId !== -1) {
      let tribe = this.tribeOptions.find(i => i.id == this.startup_JoinRequest.selectedTribeId);
      if (tribe) {
        this.tribeDescription = this.translationService.getTribeDescription(tribe.name);
        this.tribeEffects = JSON.parse(tribe.effectData);
        for (var i = 0; i < this.tribeEffects.length; i++)
          this.tribeEffects[i].Name = this.translationService.getEffectName(this.tribeEffects[i].Name);
      }
    }
  }

  //====== GET DATA ======
  getStaticData(counter: number) {
    var version = this.dataService.getStaticDataVersion();
    if (!version || version.length == 0) {
      if (counter < 10) {
        counter++;
        setTimeout(() => {
          this.getStaticData(counter);
        }, 200);
      }
      //else
      //  this.returnToStart("no staticData");
    }

    //clear data
    this.itemTypes = [];

    var tmpString = localStorage.getItem(version + '-items');
    if (tmpString != null)
      this.itemTypes = JSON.parse(tmpString);

    if (version || version.length != 0)
      this.getPlayer();
  }

  getPlayer() {
    this.spinnerService.addToLoading();
    this.gameBasicService.getPlayer().subscribe((result: Startup_Result) => {
      if (result.message == 'WELCOME_BACK') {
        this.statistics = [];
        for (var i = 0; i < result.statistics.length; i++) {
          if (result.statistics[i].name == "PLAYER_NAME")
            this.playerName = result.statistics[i].value;
          else
            this.statistics.push(result.statistics[i]);
        }
        if (result.loginReward.length > 0) {
          var itemsString = "";
          for (var i = 0; i < result.loginReward.length; i++) {
            var itemType = this.itemTypes.find(it => it.id == result.loginReward[i])
            if (itemType != null) {
              var name = "";
              if (itemType.boxType != null)
                name = this.translationService.getItemTranslations(itemType.boxType.name);
              if (itemType.resourcePackType != null)
                name = this.translationService.getItemTranslations(itemType.resourcePackType.resource + "_" + itemType.rarity.toString());
              if (itemType.blueprintPartType != null)
                name = this.translationService.getItemTranslations(itemType.blueprintPartType.name);
              if (itemsString.length > 0)
                itemsString += ", ";
              itemsString += name;
            }
          }

          this.modalItemList = itemsString;
          this.modalIsOpen = true;  
        }

        this.playerExists = true;
        this.dataService.sendBroadcast('LOAD-VILLAGES');
        this.dataService.sendBroadcast('LOAD-MESSAGES');
        //TODO do i need to set  the player in order to extend menue / restrict menu until a player is existing??????
        this.spinnerService.removeFromLoading();
      }
      if (result.message == 'WELCOME_NEW') {
        this.playerExists = false;
        this.getSelectData();
      }
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.spinnerService.removeFromLoading();
    });
  }

  closeModal() {
    this.modalIsOpen = false;
  }

  getSelectData() {
    this.gameBasicService.getSelectData().subscribe((result: Startup_Select) => {
      this.selectData = result;
      if (this.selectData && this.selectData.nations)
        for (var i = 0; i < this.selectData.nations.length; i++) {
          this.selectData.nations[i].nameDisplay = this.translationService.getNationName(this.selectData.nations[i].name);
          if (this.selectData.nations[i].tribes)
            for (var j = 0; j < this.selectData.nations[i].tribes.length; j++)
              this.selectData.nations[i].tribes[j].nameDisplay = this.translationService.getTribeName(this.selectData.nations[i].tribes[j].name);
        }

      this.startup_JoinRequest.availableDirectionsFlag = result.availableDirectionsFlag;
      this.spinnerService.removeFromLoading();
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.spinnerService.removeFromLoading();
    });
  }

  joinServer(startup_JoinRequest: Startup_JoinRequest) {
    this.spinnerService.addToLoading();
    this.gameBasicService.joinServer(startup_JoinRequest).subscribe((result: string) => {
      if (result == "SUCCESS")
        setTimeout(() => {
          this.getPlayer();
        }, 1000);
      this.spinnerService.removeFromLoading();
    }, error => {
      if (error == "User Not Found")
        this.toastService.showWarningToast($localize`COMMON_ERROR`, $localize`COMMON_USERNOTFOUND`);
      else if (error == "Playername Taken")
        this.toastService.showWarningToast($localize`COMMON_ERROR`, $localize`JOIN_PLAYERNAME_TAKEN`);
      else if (error == "Nation Not Found")
        this.toastService.showWarningToast($localize`COMMON_ERROR`, $localize`JOIN_NATION_MISSING`);
      else if (error == "Tribe Not Found")
        this.toastService.showWarningToast($localize`COMMON_ERROR`, $localize`JOIN_TRIBE_MISSING`);
      else
        this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.spinnerService.removeFromLoading();
    });
  }
}
