import { Injectable } from '@angular/core';
import { IdNameValueDesc } from '../models/pair-models';

@Injectable({
  providedIn: 'root'
})
export class IconService {

  icons: IdNameValueDesc[] = [
    { id: -1, name: "", value: "&#8260;", description: $localize`DESIGN_ICON_NOTHING` },
    //icons to pick for designs
    { id: 1, name: "dagger", value: "&#128481;", description: $localize`DESIGN_ICON_DAGGER` },
    { id: 2, name: "knife", value: "&#128298;", description: $localize`DESIGN_ICON_KNIFE` },
    { id: 3, name: "bow", value: "&#127993;", description: $localize`DESIGN_ICON_BOW` },
    { id: 4, name: "axe", value: "&#129683;", description: $localize`DESIGN_ICON_AXE` },
    { id: 5, name: "house", value: "&#127968;", description: $localize`DESIGN_ICON_HOUSE` },
    { id: 6, name: "shield", value: "&#128737;", description: $localize`DESIGN_ICON_SHIELD` },
    { id: 7, name: "twoSwords", value: "&#9876;", description: $localize`DESIGN_ICON_TWO_SWORDS` },
    { id: 8, name: "scropion", value: "&#129410;", description: $localize`DESIGN_ICON_SCORPION` },
    { id: 9, name: "snake", value: "&#128013;", description: $localize`DESIGN_ICON_SNAKE` },
    { id: 10, name: "ox", value: "&#128002;", description: $localize`DESIGN_ICON_OX` },
    { id: 11, name: "goat", value: "&#128016;", description: $localize`DESIGN_ICON_GOAT` },
    { id: 12, name: "horse", value: "&#128014;", description: $localize`DESIGN_ICON_HORSE` },
    { id: 13, name: "flagBlack", value: "&#127986;", description: $localize`DESIGN_ICON_FLAG_BLACK` },
    { id: 14, name: "flagWhite", value: "&#127987;", description: $localize`DESIGN_ICON_FLAG_WHITE` },
    //RES icons
    { id: 100, name: "woodIcon", value: "&#129683;", description: "" },
    { id: 101, name: "stoneIcon", value: "&#9935;", description: "" },
    { id: 102, name: "ironIcon", value: "&#128296;", description: "" },
    { id: 103, name: "foodIcon", value: "&#127859;", description: "" },
    { id: 104, name: "clockIcon", value: "&#128337;", description: "" },
    { id: 105, name: "sizeIcon", value: "&#128106;", description: "" },
    //villages
    { id: 150, name: "villageIcon", value: "&#127969;", description: "" },
    //orientation
    { id: 200, name: "plusIcon", value: "&#43;", description: "" },
    { id: 201, name: "minusIcon", value: "&#45;", description: "" },
    { id: 202, name: "gotoIcon", value: "&#11122;", description: "" },
    { id: 203, name: "upIcon", value: "&#11161;", description: "" },
    { id: 204, name: "leftIcon", value: "&#11160;", description: "" },
    { id: 205, name: "rightIcon", value: "&#11162;", description: "" },
    { id: 206, name: "downIcon", value: "&#11163;", description: "" },
    { id: 207, name: "collapsed", value: "&#11208;", description: "" },
    { id: 208, name: "expanded", value: "&#11206;", description: "" },

    { id: 208, name: "first", value: "&#9198;", description: "" },
    { id: 208, name: "previous", value: "&#9194;", description: "" },
    { id: 208, name: "next", value: "&#9193;", description: "" },
    { id: 208, name: "last", value: "&#9197;", description: "" },

    //actions
    { id: 250, name: "defendIcon", value: "&#128737;", description: "" },
    { id: 251, name: "attackIcon", value: "&#9876;", description: "" },
    { id: 252, name: "specialIcon", value: "&#9889;", description: "" },
    { id: 253, name: "pveIcon", value: "&#128481;", description: "" },
    { id: 256, name: "tradeIcon", value: "&#9878;", description: "" },
    { id: 257, name: "bpoIcon", value: "&#128220;", description: "" },

    { id: 280, name: "settleIcon", value: "&#127987;", description: "" },
    { id: 281, name: "terraFormIcon", value: "&#127748;", description: "" },
    { id: 282, name: "scoutIcon", value: "&#128270;", description: "" },

    { id: 283, name: "attackArmyIcon", value: "&#9876;", description: "" },
    { id: 284, name: "attackRaidIcon", value: "&#128298;", description: "" },
    { id: 285, name: "attackSiegeIcon", value: "&#128171;", description: "" }, 

    //template
    { id: 55557, name: "NAME", value: "&#ICONCODE;", description: "" },



  ];


  constructor() { }

  public getIconByName(name: string): string {
    var result = this.icons.find(i => i.name == name);
    if (result != null)
      return result.value;
    return "";
  }

  public getIconById(id: number): string {
    var result = this.icons.find(i => i.id == id);
    if (result != null)
      return result.value;
    return "";
  }

  public getIconObjectById(id: number): IdNameValueDesc | null {
    var result = this.icons.find(i => i.id == id);
    if (result != null)
      return result;
    return null;
  }

  public getIconObjectIdRange(start: number, end:number): IdNameValueDesc[] {
    var result = this.icons.filter(i => i.id >= start && i.id <= end);
    return result;
  }
}
