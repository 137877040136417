import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PlayerItem } from '../../../models/item-models';
import { ItemType } from '../../../models/staticData-models';
import { DataService } from '../../../services/data.service';
import { ItemService } from '../../../services/data/item.service';
import { SpinnerService } from '../../../services/spinner.service';
import { ToastService } from '../../../services/toast.service';
import { TranslationService } from '../../../services/translation.service';

//==== TODOS ====
//- filter

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.css']
})
export class InventoryComponent implements OnInit {
  itemTypes: ItemType[] = [];
  villageId: number | null = null;
  playerItems: PlayerItem[] = [];
  selectedItem: PlayerItem | null = null;
  modalIsOpen: boolean = false;
  amount: number = 1;

  boxIcon = "&#128230;";
  partIcon = "&#129513;";
  resPackIcon = "&#129520;";

  constructor(private toastService: ToastService, private itemService: ItemService, private spinnerService: SpinnerService,
    private translationService: TranslationService, private dataService: DataService, private router: Router) {

  }

  ngOnInit() {
    this.getStaticData(0);
  }

  returnToStart(reason: string) {
    this.router.navigate(['game/start']);
  }

  prepareData() {
    if (this.playerItems.length == 0 || this.itemTypes.length == 0)
      return;
    for (var i = 0; i < this.playerItems.length; i++) {
      var itemType = this.itemTypes.find(it => it.id == this.playerItems[i].itemTypeId)
      if (itemType != null) {
        this.playerItems[i].ItemType = itemType;
        if (itemType.boxType != null)
          this.playerItems[i].name = this.translationService.getItemTranslations(itemType.boxType.name);
        else if (itemType.blueprintPartType != null) {

          this.playerItems[i].name = this.translationService.getItemTranslations(itemType.blueprintPartType.name);
          this.playerItems[i].ItemType!.partNumber = itemType.blueprintPartType.partNumber;
        }

        else if (itemType.resourcePackType != null)
          this.playerItems[i].name = this.translationService.getItemTranslations(itemType.resourcePackType.resource + "_" + itemType.rarity);
      }
    }

    this.playerItems = this.playerItems.sort((a, b) => {
      if (a.itemTypeId > b.itemTypeId)
        return -1;
      else if (a.itemTypeId < b.itemTypeId)
        return 1;
      return 0;
    });
  }

  prepareModal(playerItem: PlayerItem) {
    this.selectedItem = playerItem;
    this.modalIsOpen = true;
  }

  closeModal() {
    this.selectedItem = null;
    this.amount = 1;
    this.modalIsOpen = false;
  }

  getIcon(type: string, iconType: number | null): string {

    if (type == "secondary") {
      if (iconType == 0)
        return "&#10067;"; //Mixed
      if (iconType == 1)
        return "&#129520;"; //ResPack
      if (iconType == 2)
        return "&#128230;"; //Box
      if (iconType == 3)
        return "&#129513;"; //BluePrint

      if (iconType == 20)
        return "&#129683;"; //Wood
      if (iconType == 21)
        return "&#9935;"; //Stone
      if (iconType == 22)
        return "&#128296;"; //Iron
      if (iconType == 23)
        return "&#127859;"; //Food

      if (iconType == 30)
        return "&#128481;"; //knifeIcon
      if (iconType == 31)
        return "&#128737;"; //shieldIcon
      if (iconType == 32)
        return "&#127993;"; //bowIcon
      if (iconType == 33)
        return "&#128085;"; //armorIcon
      if (iconType == 34)
        return "&#128094;"; //bootsIcon
      if (iconType == 35)
        return "&#129686;"; //helmetIcon
      if (iconType == 36)
        return "&#129455;"; //walking aid (spear)





      if (iconType == 500)
        return "&#127987;"; //settleIcon

    }
    else if (type == "rarity") {
      if (iconType == 0)
        return "&#129003;"; //common
      if (iconType == 1)
        return "&#129001;"; //uncommonIcon
      if (iconType == 2)
        return "&#128998;"; //rareIcon
      if (iconType == 3)
        return "&#129002;"; //epicIcon
      if (iconType == 4)
        return "&#128999;"; //legendaryIcon
      if (iconType == 5)
        return "&#128997;"; //mysticalIcon
      if (iconType == 6)
        return "&#129000;"; //godlikeIcon
    }
    else if (type == "partNumber") {
      if (iconType == 1)
        return "&#8544;";
      if (iconType == 2)
        return "&#8545;";
      if (iconType == 3)
        return "&#8546;";
      if (iconType == 4)
        return "&#8547;";
      if (iconType == 5)
        return "&#8548;";
      if (iconType == 6)
        return "&#8549;";
      if (iconType == 7)
        return "&#8550;";
      if (iconType == 8)
        return "&#8551;";
    }
    return "";
  }

  //====== GET DATA ======
  getStaticData(counter: number) {
    var version = this.dataService.getStaticDataVersion();
    if (!version || version.length == 0) {
      if (counter < 10) {
        counter++;
        setTimeout(() => {
          this.getStaticData(counter);
        }, 200);
      }
      else
        this.returnToStart("no staticData");
    }

    //clear data
    this.itemTypes = [];

    var tmpString = localStorage.getItem(version + '-items');
    if (tmpString != null)
      this.itemTypes = JSON.parse(tmpString);

    if (version || version.length != 0)
      this.getItemData();
  }

  getItemData() {
    this.spinnerService.addToLoading();
    this.itemService.getPlayerItems().subscribe((result: PlayerItem[]) => {

      this.selectedItem = null;
      this.amount = 1;
      this.playerItems = result;
      this.prepareData();

      this.spinnerService.removeFromLoading();
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.spinnerService.removeFromLoading();
    });
  }

  openBox() {
    if (this.selectedItem == null || this.amount == null)
      return;

    this.modalIsOpen = false;
    this.spinnerService.addToLoading();
    this.itemService.openBox(this.selectedItem.id, this.amount).subscribe((result: PlayerItem[]) => {

      this.selectedItem = null;
      this.amount = 1;
      this.playerItems = result;
      this.prepareData();

      this.spinnerService.removeFromLoading();
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.spinnerService.removeFromLoading();
    });
  }

  useResourcePacks() {
    if (this.selectedItem == null || this.amount == null)
      return;
    this.villageId = this.dataService.getSelectedVillageId();
    if (!this.villageId || this.villageId == -1) {
      this.returnToStart("no villageId");
    }

    this.modalIsOpen = false;
    this.spinnerService.addToLoading();
    this.itemService.useResourcePacks(this.selectedItem.id, this.amount, this.villageId).subscribe((result: PlayerItem[]) => {

      this.dataService.sendBroadcast('LOAD-RESOURCES');
      this.selectedItem = null;
      this.amount = 1;
      this.playerItems = result;
      this.prepareData();

      this.spinnerService.removeFromLoading();
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.spinnerService.removeFromLoading();
    });
  }

  combineParts() {
    if (this.selectedItem == null)
      return;
    this.villageId = this.dataService.getSelectedVillageId();
    if (!this.villageId || this.villageId == -1) {
      this.returnToStart("no villageId");
    }

    this.modalIsOpen = false;
    this.spinnerService.addToLoading();
    this.itemService.combineParts(this.selectedItem.id, this.villageId).subscribe((result: PlayerItem[]) => {

      this.selectedItem = null;
      this.amount = 1;
      this.playerItems = result;
      this.prepareData();

      this.spinnerService.removeFromLoading();
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.spinnerService.removeFromLoading();
    });
  }

  sellItems() {
    if (this.selectedItem == null || this.amount == null)
      return;
    this.villageId = this.dataService.getSelectedVillageId();
    if (!this.villageId || this.villageId == -1) {
      this.returnToStart("no villageId");
    }

    this.modalIsOpen = false;
    this.spinnerService.addToLoading();
    this.itemService.sellItems(this.selectedItem.id, this.amount, this.villageId).subscribe((result: PlayerItem[]) => {

      this.selectedItem = null;
      this.amount = 1;
      this.playerItems = result;
      this.prepareData();

      this.spinnerService.removeFromLoading();
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.spinnerService.removeFromLoading();
    });
  }
}
