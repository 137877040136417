<div class="row">
  <div class="col col-sm-4">
    <p style="margin: -3px 0 -2px; font-size: 12px;" i18n>TICK</p>
    <p style="margin: -3px 0 -2px; font-size: 12px;" i18n>NEXT</p>
  </div>
  <div class="col col-sm-8">
    <p style="margin: -3px 0 -2px; font-size: 12px;">{{tick}}</p>
    <p style="margin: -3px 0 -2px; font-size: 12px;">{{displayTimer}}</p>
  </div>
</div>
