
<div class="row" style="margin-top: 6px; margin-bottom: 6px;">
  <div class=" col col-lg-3"
       title="{{prodctionString}}: {{resData.woodProduction}}&#013;{{storageString}}: {{resData.resourceStorage}}">
    <span [innerHTML]="woodIcon"></span>
    <span [ngStyle]="{'color': resData.wood == resData.resourceStorage ? 'red' : 'black' }">{{resData.wood}}</span>
  </div>
  <div class=" col col-lg-3"
       title="{{prodctionString}}: {{resData.stoneProduction}}&#013;{{storageString}}: {{resData.resourceStorage}}">
    <span [innerHTML]="stoneIcon"></span>
    <span [ngStyle]="{'color': resData.stone == resData.resourceStorage ? 'red' : 'black' }">{{resData.stone}}</span>
  </div>
  <div class=" col col-lg-3"
       title="{{prodctionString}}: {{resData.ironProduction}}&#013;{{storageString}}: {{resData.resourceStorage}}">
    <span [innerHTML]="ironIcon"></span>
    <span [ngStyle]="{'color': resData.iron == resData.resourceStorage ? 'red' : 'black' }">{{resData.iron}}</span>
  </div>
  <div class=" col col-lg-3"
       title="{{prodctionString}}: {{resData.foodProduction}}&#013;{{storageString}}: {{resData.foodStorage}}">
    <span [innerHTML]="foodIcon"></span>
    <span [ngStyle]="{'color': resData.food == resData.foodStorage ? 'red' : 'black' }">{{resData.food}}</span>
  </div>
</div>
<!--VILLAGE MENU-->
<div class="row" style="margin-top: 6px; margin-left: 6px;">
  <button class="villageMenuButton" [innerHTML]="villageIcon" [ngClass]="{ active:url == 'game/village' }"  aria-current="page" routerLink="game/village" i18n-title title="TOOLTIP_VILLAGE"></button>
  <button class="villageMenuButton" [innerHTML]="administrationIcon" [ngClass]="{ active:url == 'game/villageAdministration'}"  aria-current="page" routerLink="game/villageAdministration" i18n-title title="TOOLTIP_VILLAGEADMINISTRATION"></button>
  <button class="villageMenuButton" [disabled]="!workshop" [innerHTML]="workshopIcon" [ngClass]="{ active:url == 'game/tech', unavailable:!workshop }"  aria-current="page" routerLink="game/tech" i18n-title title="TOOLTIP_WORKSHOP"></button>
  <button class="villageMenuButton" [disabled]="!barracks" [innerHTML]="barracksIcon" [ngClass]="{ active:url == 'game/barracks', unavailable:!barracks  }" aria-current="page" routerLink="game/barracks" i18n-title title="TOOLTIP_BARRACKS"></button>
  <button class="villageMenuButton" [disabled]="!trader" [innerHTML]="tradeIcon" [ngClass]="{ active:url == 'game/trade', unavailable:!trader  }" aria-current="page" routerLink="game/village" i18n-title title="TOOLTIP_TRADER"></button>
  <button class="villageMenuButton" [disabled]="!library" [innerHTML]="libraryIcon" [ngClass]="{ active:url == 'game/library', unavailable:!library  }" aria-current="page" routerLink="game/village" i18n-title title="TOOLTIP_LIBRARY"></button>
</div>


<style>
  .row button.active {
    background-color: #ccc !important;
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom: none !important;
    margin-bottom: 0px !important;
    padding-bottom: 10px !important;
  }
  .row button.unavailable {
    filter: grayscale(100%);
    border: lightgray solid 1px    !important;
  }
  .row button.villageMenuButton {
    margin-left: 12px;
    font-size: 32px;
    width: 62px;
    border-radius: 30px;
    padding: 0 8px 8px 8px;
    background-color: white;
    margin-bottom: 10px;
    border: black solid 2px;
  }
</style>
