import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ArmyData, BarracksData } from '../../models/barrack-models';
import { IdValue } from '../../models/pair-models';

@Injectable({
  providedIn: 'root'
})
export class BarrackService {

  baseApiUrl: string = environment.API_URL;
  constructor(private http: HttpClient) { }

  public recruitUnits(designId: number, villageId: number, amount: number): Observable<string> {
    var request = { designId: designId, villageId: villageId, amount: amount }
    return this.http.post(this.baseApiUrl + 'Village/recruitUnits', request, { responseType: 'text' });
  }

  public getBarracksData(vilageId: number): Observable<BarracksData> {
    return this.http.get<BarracksData>(this.baseApiUrl + 'Village/getBarracksData?villageId=' + vilageId);
  }

  public getArmyData(vilageId: number): Observable<ArmyData> {
    return this.http.get<ArmyData>(this.baseApiUrl + 'Village/getArmyData?villageId=' + vilageId);
  }

  public sendTroops(x: number, y: number, mapAction: string, armyId: number | null, units: IdValue[], speedPercentage: number, villageId:number): Observable<string> {
    var request = { x: x, y: y, mapAction: mapAction, armyId: armyId, units: units, speedPercentage: speedPercentage, villageId: villageId }
    return this.http.post(this.baseApiUrl + 'Map/startMapAction', request, { responseType: 'text' });
  }
}
