import { Component, OnInit } from '@angular/core';
import { SpinnerService } from '../../../services/spinner.service';
import { ToastService } from '../../../services/toast.service';
import { DataService } from '../../../services/data.service';
import { GameBasicService } from '../../../services/data/game-basic.service';
import { environment } from '../../../../environments/environment';
import { VillageMinimal } from '../../../models/box-models';
import { Router, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';


@Component({
  selector: 'app-village-box',
  templateUrl: './village-box.component.html',
  styleUrls: ['./village-box.component.css']
})
export class VillageBoxComponent implements OnInit {

  serverId: number = environment.serverId;
  villageData: VillageMinimal[];
  capitalIcon = "&#11088;";

  villageIcon = "&#127968;";
  cityIcon = "&#127979;";
  fortressIcon = "&#127984;";

  constructor(private gameBasicService: GameBasicService,
    private dataService: DataService,
    private toastService: ToastService,
    private router: Router,
    private spinnerService: SpinnerService) {

    this.villageData = [];
  }

  ngOnInit() {
    if (this.router.url != '/game/start')
      this.getVillages();


    this.dataService._broadcastSource$.subscribe(broadcast => {
      if (broadcast == "LOAD-VILLAGES")
        this.getVillages();
    })
  }

  getVillages() {
    this.spinnerService.addToLoading();
    this.gameBasicService.getVillagesMinimal().subscribe((result: VillageMinimal[]) => {
      if (result && result.length > 0) {
        this.villageData = result;
        //check service first if is empty check lokal storage ... this way other components dont need to check lokal storage too but get get id from service ????
        var selectedVillage = this.dataService.getSelectedVillageId();
        if (!selectedVillage || selectedVillage == -1) {
          var id = localStorage.getItem(this.serverId + '-SelectedVillage');
          if (id) {
            selectedVillage = +id;
            this.dataService.setSelectedVillageId(selectedVillage);
            this.dataService.sendBroadcast('CHANGED-VILLAGE');
          }            
        }          
        if (selectedVillage)
          this.markSelectedVillage(selectedVillage, false);
        else
          this.selectVillage(this.villageData[0].id, false);
      }
      this.spinnerService.removeFromLoading();
      if (!result || result.length === 0) {
        //if village list in empty => send back to staertpage => player might have been defeated
        this.router.navigate(['/game/start']);
      }
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.spinnerService.removeFromLoading();
    });
  }

  selectVillage(villageId: number, isRepeat: boolean) {
    var oldId = -1;
    var oldIdString = localStorage.getItem(this.serverId + '-SelectedVillage')
    if (oldIdString && oldIdString.length > 0)
      oldId = +oldIdString;
    if (oldId != villageId) {
      localStorage.setItem(this.serverId + '-SelectedVillage', villageId.toString())
      this.dataService.setSelectedVillageId(villageId);
      this.markSelectedVillage(villageId, isRepeat);
      this.dataService.sendBroadcast('CHANGED-VILLAGE');
    }
  }

  markSelectedVillage(villageId: number, isRepeat: boolean) {
    var doneMarking = false;
    for (var i = 0; i < this.villageData.length; i++)
      if (this.villageData[i].id === villageId) {
        doneMarking = true;
        this.villageData[i].isSelected = true;
      }        
      else
        this.villageData[i].isSelected = false;

    if (!doneMarking && !isRepeat)
      this.selectVillage(this.villageData[0].id, true);
  }

}
