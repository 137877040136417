import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PlayerScienceLevel, VillageTechResponce } from '../../models/science-models';

@Injectable({
  providedIn: 'root'
})
export class ScienceService {

  baseApiUrl: string = environment.API_URL;
  constructor(private http: HttpClient) { }

  public getScienceData(): Observable<PlayerScienceLevel[]> {
    return this.http.get<PlayerScienceLevel[]>(this.baseApiUrl + 'ScienceTech/getScienceData');
  }

  public startResearch(targetScienceTypeLevelId: number, sciencePlayerId: number| null): Observable<string> {
    var request = { targetScienceTypeLevelId: targetScienceTypeLevelId, sciencePlayerId: sciencePlayerId };
    return this.http.post(this.baseApiUrl + 'ScienceTech/startResearch', request, { responseType: 'text' });
  }

  public getTechData(villageId: number): Observable<VillageTechResponce> {
    return this.http.get<VillageTechResponce>(this.baseApiUrl + 'ScienceTech/getTechData?villageId=' + villageId);
  }

  public buyTech(villageId: number, techTypeId: number | null): Observable<string> {
    var request = { villageId: villageId, techTypeId: techTypeId }
    return this.http.post(this.baseApiUrl + 'ScienceTech/buyTech', request, { responseType: 'text' });
  }
}
