import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { environment } from '../../environments/environment';
import { LoginResponce } from '../models/single/loginResponce';
import { AuthService } from '../services/data/auth.service';
import { catchError } from "rxjs/operators";
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  lifeTime: number = environment.lifeTime;

  constructor(private authService: AuthService, private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('authToken');
    var isIgnore = false;
    if (req.url.endsWith('refresh') || req.url.endsWith('login') || req.url.endsWith('register'))
      isIgnore = true;

    if (token) {
      if (!isIgnore && checkIfTockenLifeTimeExpired(this.lifeTime)) {
        //run refresh as authToken lifetime has come to its end
        var refreshToken = localStorage.getItem('refreshToken');
        if (refreshToken) {
          this.authService.refresh(refreshToken).subscribe((data: LoginResponce) => {
            localStorage.setItem('authToken', data.authToken);
            localStorage.setItem('refreshToken', data.refreshToken);
            localStorage.setItem('authCreationTime', new Date().toString());
          });
        }
      }

      req = req.clone({
        setHeaders: { Authorization: `Bearer ${token}` } // ` NOT ' !!!!!
      });
    }

    //return next.handle(req);

    return next.handle(req)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMsg = '';
          if (error.error instanceof ErrorEvent) {
          } else {
            if (error.status == 401 || error.status == 0) {
              this.router.navigate(['main']);
            }
          }
          return throwError(error.error);
        })
      )
  }

}

function checkIfTockenLifeTimeExpired(lifeTime: number) {
  var result = false;
  var timeString = localStorage.getItem('authCreationTime');
  if (timeString) {
    var compareTime = new Date(timeString);
    compareTime.setTime(compareTime.getTime() + ((lifeTime - 0.1) * 60 * 60 * 1000));
    if (compareTime < new Date())
      result = true;
  }
  return result;
}
