import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MapResult, MapField, MapVillage, MapPlayer, MapStanding, MapStandingsResult, MapDisplay } from '../../../models/map-models';
import { DataService } from '../../../services/data.service';
import { SpinnerService } from '../../../services/spinner.service';
import { ToastService } from '../../../services/toast.service';
import { MapService } from '../../../services/data/map.service';
import { MapFieldType, Nation } from '../../../models/staticData-models';
import { TranslationService } from '../../../services/translation.service';
import { MapActionRedirect } from '../../../models/redirect-models';
import { IconService } from '../../../services/icon.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {
  villageId: number | null = null;
  zoom: number = 1; //1 = range +-4; 2 = range +-8
  x: number = 0;
  y: number = 0;
  gotoX: number = 0;
  gotoY: number = 0;
  playerId: number | null = null;
  allianceId: number | null = null;

  mapFields: MapField[] = [];
  mapVillages: MapVillage[] = [];
  mapPlayers: MapPlayer[] = [];

  standingPlayers: MapStanding[] = [];
  standingAlliances: MapStanding[] = [];

  mapFieldTypes: MapFieldType[] = [];
  nations: Nation[] = [];

  map: MapDisplay[][] = [];
  selectedMapField: MapDisplay | null = null;

  constructor(private toastService: ToastService, private translationService: TranslationService, private mapService: MapService, private spinnerService: SpinnerService,
    private dataService: DataService, private router: Router, private iconService: IconService) {

  }

  ngOnInit() {
    this.dataService._broadcastSource$.subscribe(broadcast => {
      if (broadcast == "CHANGED-VILLAGE") {
        //this.getMapData(false)

        this.villageId = this.dataService.getSelectedVillageId();
        this.prepareArray();
      }
    });
    this.getStaticData(0);
  }

  returnToStart(reason: string) {
    this.router.navigate(['game/start']);
  }

  getIconByName(iconName: string) {
    return this.iconService.getIconByName(iconName);
  }

  getTranslation(type: string, input: string) {
    if (type == "fieldType")
      return this.translationService.getFieldType(input)
    if (type == "nation")
      return this.translationService.getNationName(input)
    return "";
  }

  getNationState(fieldType: MapFieldType, nation: Nation) {
    var state = 3;
    if ((nation.flagPosition & fieldType.bigBonusFlag) > 0)
      state = 1;
    else if ((nation.flagPosition & fieldType.smallBonusFlag) > 0)
      state = 2;
    else if ((nation.flagPosition & fieldType.malusFlag) > 0)
      state = 4;
    return state;
  }

  getActionButtons(action: string): boolean {


    if (this.selectedMapField == null || this.selectedMapField.fieldType == null)
      return true;
    var fieldType = this.selectedMapField.fieldType;

    //RETURN IF BUTTON IS DISABLED
    switch (action) {
      case "defend": {        
        if (!this.selectedMapField.hasTown)
          return true;
        if (this.selectedMapField.villageId == null || this.selectedMapField.villageId == this.villageId)
          return true;
        break;
      }
      case "attack": {
        if (!this.selectedMapField.hasTown)
          return true;
        if (this.selectedMapField.playerId == null || this.selectedMapField.playerId == this.playerId)
          return true;
        break;
      }
      case "special": {
        if (this.selectedMapField.playerId != null || this.selectedMapField.playerId == this.playerId)
          return true;
        break;
      }
      case "pve": {
        if (this.selectedMapField.hasTown)
          return true;
          //TODO CHECK FOR PVE-Existence
        return true;
        break;
      }
      case "trade": {
        if (!this.selectedMapField.hasTown)
          return true;
        if (this.selectedMapField.villageId == null || this.selectedMapField.villageId == this.villageId)
          return true;
        break;
      }
      case "bpo": {
        if (this.selectedMapField.playerId == null || this.selectedMapField.playerId != this.playerId)
          return true;
        if (!this.selectedMapField.hasTown)
          return true;
        if (this.selectedMapField.villageId == null || this.selectedMapField.villageId == this.villageId)
          return true;
        break;
      }
    }

    return false;
  }

  runActionButton(action: string) {
    if (this.selectedMapField == null || this.selectedMapField.x == null || this.selectedMapField.y == null)
      return;
    var mapAction: MapActionRedirect = { mapField: this.selectedMapField, action: action, playerId: this.playerId };
    this.dataService.setMapActionRedirect(mapAction);
    switch (action) {
      case "defend": {
        this.router.navigate(['game/barracks']);
        break;
      }
      case "attack": {
        this.router.navigate(['game/barracks']);
        break;
      }
      case "special": {
        this.router.navigate(['game/barracks']);
        break;
      }
      case "pve": {
        this.router.navigate(['game/barracks']);
        break;
      }
      case "trade": {

        break;
      }
      case "bpo": {

        break;
      }
    }
  }

  createNewMapDisplay(): MapDisplay {
    var result: MapDisplay = {
      id: -1, x: null, y: null, fieldType: null,
      hasTown: null, villageId: null, villageName: null,
      playerId: null, playerName: null, allianceId: null, allianceName: null,
      classes: null, relationType: null, fieldTypeName: ""
    }
    return result;
  }

  prepareArray() {
    this.map = [];
    //prepare data
    var range = 4;
    if (this.zoom == 2)
      range = 8;
    //build empty array    
    var dummy = this.createNewMapDisplay();
    for (var i = 0; i < (range * 2) + 1; i++) {
      var row: MapDisplay[] = [];
      for (var j = 0; j < (range * 2) + 1; j++)
        row.push(dummy);
      this.map.push(row);
    }
    //fill array
    for (var i = 0; i < this.mapFields.length; i++) {
      //create object
      var mapField = this.createNewMapDisplay();
      //fill object
      mapField.id = this.mapFields[i].id;
      mapField.x = this.mapFields[i].x;
      mapField.y = this.mapFields[i].y;
      var fieldType = this.mapFieldTypes.find(ft => ft.id == this.mapFields[i].fieldType)
      if (fieldType != null) {
        mapField.fieldType = fieldType;
        mapField.classes = "ft_" + fieldType.name
      }

      mapField.id = this.mapFields[i].id;
      mapField.hasTown = this.mapFields[i].hasTown;
      mapField.villageId = this.mapFields[i].villageId;
      if (this.mapFields[i].villageId != null) {
        var village = this.mapVillages.find(v => v.id == this.mapFields[i].villageId)
        if (village && village != null) {
          if (this.mapFields[i].hasTown == true)
            mapField.villageName = village.name;
          var player = this.mapPlayers.find(p => p.id == village!.playerId)
          if (player && player != null) {
            mapField.playerId = player.id;
            mapField.playerName = player.name;
            mapField.allianceId = player.allianceId;
            mapField.allianceName = player.allianceName;
          }
        }
      }
      //place object in relation to center
      var x = range + (this.mapFields[i].x - this.x);
      var y = range - (this.mapFields[i].y - this.y);
      this.map[y][x] = mapField;
    }

    //analyze array 
    for (var y = 0; y < this.map.length; y++) {
      for (var x = 0; x < this.map[y].length; x++) {

        this.map[y][x].relationType = this.getRelationType(this.map[y][x]);

        //check top
        if (y > 0 && this.map[y][x].villageId && this.map[y][x].villageId != this.map[y - 1][x].villageId) {
          if (this.map[y][x].relationType !== null)
            this.map[y][x].classes += " bt_" + this.map[y][x].relationType;
          else
            this.map[y][x].classes += " bt_x";
        }
        //check right
        if (x < this.map.length - 1 && this.map[y][x].villageId && this.map[y][x].villageId != this.map[y][x + 1].villageId) {
          if (this.map[y][x].relationType !== null)
            this.map[y][x].classes += " br_" + this.map[y][x].relationType;
          else
            this.map[y][x].classes += " br_x";
        }
        //check bottom
        if (y < this.map.length - 1 && this.map[y][x].villageId && this.map[y][x].villageId != this.map[y + 1][x].villageId) {
          if (this.map[y][x].relationType !== null)
            this.map[y][x].classes += " bb_" + this.map[y][x].relationType;
          else
            this.map[y][x].classes += " bb_x";
        }
        //check left
        if (x > 0 && this.map[y][x].villageId && this.map[y][x].villageId != this.map[y][x - 1].villageId) {
          if (this.map[y][x].relationType !== null)
            this.map[y][x].classes += " bl_" + this.map[y][x].relationType;
          else
            this.map[y][x].classes += " bl_x";
        }
      }
    }
  }

  getRelationType(m: MapDisplay): number | null {
    if (!m || m.villageId == null) //no village
      return null;
    if (m.villageId != null && this.villageId != null && m.villageId == this.villageId)
      return 7;
    if (m.playerId != null && this.playerId != null && m.playerId == this.playerId) //  Own = 0,
      return 0;
    if (m.allianceId != null && this.allianceId != null && m.allianceId == this.allianceId)//Alliance = 4,
      return 4;

    //find standings
    var standingPlayer = this.standingPlayers.find(ps => ps.id == m.playerId);
    var standingAlliance = this.standingAlliances.find(as => as.id == m.allianceId);
    if (standingPlayer == null && standingAlliance == null)//Neutral = 3,
      return 3;

    if (standingAlliance != null)//Friend = 1,Enemy = 2,FoodFriend = 5,ArchEnemy = 6
      return standingAlliance.standing;

    if (standingPlayer != null)//Friend = 1,Enemy = 2,FoodFriend = 5,ArchEnemy = 6
      return standingPlayer.standing;

    return null;
  }

  //====== INTERACTION ======
  clickField(field: MapDisplay) {
    this.selectedMapField = field;
  }

  zoomMap(input: string) {
    if (input == "+") {
      this.zoom--;
      if (this.zoom < 1)
        this.zoom = 1;

      if (this.zoom == 1 && this.map.length == 17) {
        this.map.splice(13, 4);
        this.map.splice(0, 4);
        for (var i = 0; i < this.map.length; i++) {
          this.map[i].splice(13, 4);
          this.map[i].splice(0, 4);
        }
      }
    }
    if (input == "-") {
      if (this.zoom >= 2)
        return;
      this.zoom++;
      this.getMapData(false);
    }
  }

  moveMap(direction: string) {
    var distance = this.zoom * 3;
    if (direction == "up")
      this.y += distance;
    else if (direction == "down")
      this.y += -1 * distance;
    else if (direction == "left")
      this.x += -1 * distance;
    else if (direction == "right")
      this.x += distance;
    this.getMapData(false);
  }

  goto() {
    this.x = this.gotoX;
    this.y = this.gotoY;
    this.getMapData(false);
  }

  //====== DATA ======

  getStaticData(counter: number) {
    var version = this.dataService.getStaticDataVersion();
    if (!version || version.length == 0) {
      if (counter < 10) {
        counter++;
        setTimeout(() => {
          this.getStaticData(counter);
        }, 200);
      }
      else
        this.returnToStart("no staticData");
    }

    //clear data
    this.mapFieldTypes = [];
    this.nations = [];


    var tmpString = localStorage.getItem(version + '-mapFields');
    if (tmpString != null)
      this.mapFieldTypes = JSON.parse(tmpString);

    tmpString = localStorage.getItem(version + '-nations');
    if (tmpString != null)
      this.nations = JSON.parse(tmpString);

    if (version || version.length != 0)
      this.getMapData(true);
  }

  getMapData(onInit: boolean) {
    this.villageId = this.dataService.getSelectedVillageId();
    if (!this.villageId || this.villageId == -1) {
      if (onInit)
        return;
      else
        this.returnToStart("no villageId");
    }
    if (!this.mapFieldTypes || this.mapFieldTypes.length == 0)
      return;

    this.spinnerService.addToLoading();
    var villageId = this.villageId;
    if (this.playerId != null)
      villageId = -1;
    this.mapService.getMap(this.x, this.y, this.zoom, villageId).subscribe((result: MapResult) => {
      this.mapFields = result.mapFields;
      this.mapVillages = result.villages;
      this.mapPlayers = result.players;
      this.x = result.x;
      this.y = result.y;
      this.gotoX = result.x;
      this.gotoY = result.y;
      this.playerId = result.playerId;
      this.allianceId = result.allianceId;

      this.spinnerService.removeFromLoading();

      if (onInit)
        this.getStandings();
      else
        this.prepareArray();
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.spinnerService.removeFromLoading();
    });
  }

  getStandings() {
    this.spinnerService.addToLoading();
    this.mapService.getMapStandings().subscribe((result: MapStandingsResult) => {

      this.standingPlayers = result.standingsPlayer;
      this.standingAlliances = result.standingsAlliance;

      this.prepareArray();

      this.spinnerService.removeFromLoading();
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.spinnerService.removeFromLoading();
    });
  }

}
