import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Resources } from '../../../models/box-models';
import { IdNameDesc, IdValue } from '../../../models/pair-models';
import { ArmyData, BarracksData, DesignBarracks } from '../../../models/barrack-models';
import { DataService } from '../../../services/data.service';
import { SpinnerService } from '../../../services/spinner.service';
import { ToastService } from '../../../services/toast.service';
import { BarrackService } from '../../../services/data/barracks.service';
import { IconService } from '../../../services/icon.service';
import { MapDetailsResult, MapDisplay, MapStanding, MapStandingsResult } from '../../../models/map-models';
import { MapService } from '../../../services/data/map.service';
import { TranslationService } from '../../../services/translation.service';

@Component({
  selector: 'app-barracks',
  templateUrl: './barracks.component.html',
  styleUrls: ['./barracks.component.css']
})
export class BarracksComponent implements OnInit {

  maxDifferentTroops: number = 3;
  

  designs: DesignBarracks[] = [];
  distance: number = 0;
  speedModifier: number = 0;
  speed: number = 0;
  travelTime: number = 0;
  maxBatchSize: number = 0;
  settlerSumNeeded: number = 0;
  troopLimit: number = 0;
  troopsPresent: number = 0;
  troopsRecruiting: number = 0;
  showAllDesigns: boolean = false;

  mapField: MapDisplay | null = null;
  standingPlayers: MapStanding[] = [];
  standingAlliances: MapStanding[] = [];

  villageId: number | null = null;
  villageX: number | null = null;
  villageY: number | null = null;
  playerId: number | null = null;
  relation: number | null = null;
  allianceId: number | null = null;
  resData: Resources | null = null;
  selectedTab: string = "Recruit";

  x: number = 0;
  y: number = 0;
  xInput: number | null = null;
  yInput: number | null = null;
  actionType: string | null = null;
  actionSubtype: string | null = null;

  stepCoordinatesCollapsed = false;
  stepTypeCollapsed = false;
  stepTroopsCollapsed = false;
  stepTypeActive = false;
  stepTroopsActive = false;
  stepLauchActive = false;

  actionButtonAttackActive = false;
  actionButtonPVEActive = false;
  actionButtonSpecialActive = false;
  actionButtonDefendActive = false;

  actionButtonSpecialSettleActive = false;
  actionButtonSpecialTerraFromActive = false;
  actionButtonSpecialScoutActive = false;

  actionButtonAttackArmyActive = false;
  actionButtonAttackRaidActive = false;
  actionButtonAttackSiegeActive = false;

  isArmySelection = false;

  selectableUnits: DesignBarracks[] = [];
  armies: number[] = [];
  selectedArmyId: number| null = null;

  constructor(private toastService: ToastService, private barrackService: BarrackService, private spinnerService: SpinnerService,
    private dataService: DataService, private router: Router, private iconService: IconService, private mapService: MapService, private translationService: TranslationService) {

  }

  ngOnInit() {
    this.dataService._broadcastSource$.subscribe(broadcast => {
      if (broadcast == "CHANGED-VILLAGE") {
        this.getBarracksData(false)
      }
      else if (broadcast == "LOAD-TROOPS") {
        this.getBarracksData(false)
      }
    });
    this.getBarracksData(true);
    this.resData = this.dataService.getResourceData();



  }

  checkMapAction() {
    var mapAction = this.dataService.getMapActionRedirect();
    if (mapAction != null) {
      this.dataService.clearMapActionRedirect();
      this.changeTab('Send')
      if (mapAction.mapField.x != null && mapAction.mapField.y != null) {
        this.mapField = mapAction.mapField;
        this.x = mapAction.mapField.x;
        this.y = mapAction.mapField.y;
        this.xInput = mapAction.mapField.x;
        this.yInput = mapAction.mapField.y;
        this.playerId = mapAction.playerId;
        this.stepCoordinatesCollapsed = true;

        this.checkTypeButtons();
        this.stepTypeActive = true;
        this.setTye(mapAction.action);
      }
    }
  }

  returnToStart(reason: string) {
    this.router.navigate(['game/start']);
  }

  changeTab(tab: string) {
    //TODO GET ADDITIONAL DATA IF NEEDED
    //TODO get standings
    this.selectedTab = tab;
    if (this.selectedTab == "Send") {
      this.getStandings();      
      //GET AVAILABLE TROOPS
      this.getArmyData();
    }
    else if (this.selectedTab == "Army") {
      //get existing armies
      //get max armie count etc
      //GET AVAILABLE TROOPS
      this.getArmyData();
    }
  }

  AmountChanged(design: DesignBarracks) {
    if (design == null)
      return;
    if (design.amount < 1)
      design.amount = 1;
    else if (design.amount > this.maxBatchSize)
      design.amount = this.maxBatchSize;
  }

  AmountSendChanged(units: DesignBarracks) {
    if (units == null || units.amountSend == null)
      return;
    var max = this.getTroopsAvailable(units.troopsPresent, units.troopsUnavailable)
    if (units.amountSend < 0)
      units.amountSend = 0;

    else if (units.amountSend > max)
      units.amountSend = max;
  }

  //====== SEND ======
  setCoordinates() {
    if (this.x == this.xInput && this.y == this.yInput)
      return;
    if (this.xInput == null || this.yInput == null)
      return;



    this.stepCoordinatesCollapsed = true;

    this.getFielDetails();

    //RESET OTHER SETTINGS
    this.stepTypeCollapsed = false;
    this.stepTypeActive = true;
    this.stepTroopsActive = false;
    this.stepLauchActive = false
    this.actionType = null;
    this.actionSubtype = null;
    //TODO reset troop seetings
    //TODO remove verification
  }

  checkTypeButtons() {
    //TODO set action-buttons
    this.actionButtonAttackActive = false;
    this.actionButtonPVEActive = false;
    this.actionButtonSpecialActive = false;
    this.actionButtonDefendActive = false;

    if (this.mapField == null || this.playerId == null || this.villageId == null)
      return;

    this.actionButtonAttackActive = true;
    this.actionButtonPVEActive = true;
    this.actionButtonSpecialActive = true;
    this.actionButtonDefendActive = true;

    //attack
    if (this.mapField.villageId == null || this.mapField.playerId == this.playerId)
      this.actionButtonAttackActive = false;

    //defend
    if (this.mapField.villageId == null || this.mapField.villageId == this.villageId)
      this.actionButtonDefendActive = false;

    //pve
    if (this.mapField.villageId != null)
      this.actionButtonPVEActive = false;

    //special


  }
  //---------------------------------------
  setTye(type: string) {
    if (this.actionType != type) {
      this.actionSubtype = null;
      //TODO reset troop settings
      //TODO remove verification
      this.stepTroopsActive = false;
      this.stepLauchActive = false
    }

    this.actionType = type;
    this.checkSubTypeButtons();

  }

  checkSubTypeButtons() {
    if (this.mapField == null || this.playerId == null || this.villageId == null)
      return;
    switch (this.actionType) {
      case "attack": {
        this.actionButtonAttackArmyActive = false;
        this.actionButtonAttackRaidActive = true;
        this.actionButtonAttackSiegeActive = false;
        break;
      }
      case "defend": {
        //todo later
        break;
      }
      case "pve": {
        //todo later
        break;
      }
      case "special": {

        this.actionButtonSpecialSettleActive = true;
        this.actionButtonSpecialScoutActive = true;
        this.actionButtonSpecialTerraFromActive = true;
        if (this.mapField.villageId != null)
          this.actionButtonSpecialSettleActive = false;
        //if (true)
        //  this.actionButtonSpecialScoutActive = false;
        //if (true)
        //  this.actionButtonSpecialTerraFromActive = false;
        break;
      }
    }
  }
  //---------------------------------------
  setSubType(subType: string) {
    if (this.actionSubtype != subType) {
      //TODO reset troop settings
      //TODO remove verification
      this.stepLauchActive = false
    }
    this.actionSubtype = subType;
    this.stepTypeCollapsed = true;
    this.stepTroopsActive = true;
    this.stepTroopsCollapsed = false;
    this.stepLauchActive = false;

    this.selectableUnits = [];
    this.isArmySelection = false;


    //Settle = 1,TerraForm = 2,Scout = 3
    if (this.actionType == "special") {
      if (subType == "settle") {
        this.getDesignsByAbility(1)
      }
      else if (subType == "terraForm") {
        this.getDesignsByAbility(2)
      }
      else if (subType == "scout") {
        this.getDesignsByAbility(3)
      }
    }
    else if (this.actionType == "attack" && subType == "raid") {
      this.getDesignsByAbility(null)
    }
    else if (this.actionType == "attack" || this.actionType == "defend" || this.actionType == "pve") {
      this.isArmySelection = true;
    }



    //units
    //find suiteable units/designs
    //exh actrion could have seperate restric

    //or army




  }
  //---------------------------------------
  checkSend() {
    if (this.villageX == null || this.villageY == null)
      return;
    //split last card/methods???
    //attack, raid, special, defence

    //calcl distance
    this.distance = Math.sqrt(Math.pow((this.x - this.villageX), 2) + Math.pow((this.y - this.villageY), 2));

    const factor = 10 ** 2;
    this.distance = Math.round(this.distance * factor) / factor;


    //get speed (speed = how many ticks does a troop need to move one field... bonus will decrease this time)    
    this.speed = 0;
    this.travelTime = 0;
    if (this.isArmySelection == false) {
      for (var i = 0; i < this.selectableUnits.length; i++)
        if (this.speed == 0 || this.selectableUnits[i].speed > this.speed)
          this.speed = this.selectableUnits[i].speed;
    }
    else {
      //TODO ARMY
      //speed = this.selectedArmy.speed
    }

    //apply bonus
    if (this.speedModifier > 0)
    this.speed = this.speed * this.speedModifier;
    this.travelTime = Math.ceil(this.speed * this.distance);
    
    this.stepTroopsCollapsed = true;
    this.stepLauchActive = true;
  }


  //====== CHECKS ======

  getDifferentTroopsCount(): number {
    var result = 0;
    for (var i = 0; i < this.selectableUnits.length; i++)
      if (this.selectableUnits[i].amountSend != null && this.selectableUnits[i].amountSend! > 0)
        result++;
    return result;
  }

  getSettleSum(): number {
    var result = 0;
    for (var i = 0; i < this.selectableUnits.length; i++)
      if (this.selectableUnits[i].amountSend != null)
        result += this.selectableUnits[i].amountSend!;
    return result;
  }

  //====== HELPER ======

  getIconById(iconId: number) {
    return this.iconService.getIconById(iconId);
  }
  getIconByName(iconName: string) {
    return this.iconService.getIconByName(iconName);
  }

  getTranslation(type: string, input: string) {
    if (type == "fieldType")
      return this.translationService.getFieldType(input)
   else if (type == "actionType")
      return this.translationService.getActionTypeTranslations(input)
    return "";
  }

  getDesignsByAbility(ability: number| null) {
    for (var i = 0; i < this.designs.length; i++) {
      this.designs[i].amountSend = 0;
      if (ability == null || this.designs[i].ability == ability)
        this.selectableUnits.push(this.designs[i]);
    }
  }

  getTroopsAvailable(troopsPresent: number | null, troopsUnavailable: number | null): number {
    var result = 0;
    if (troopsPresent != null) {
      result = troopsPresent;
      if (troopsUnavailable != null)
        result -= troopsUnavailable;
    }
    return result;
  }

  getRelationType(m: MapDisplay): number | null {
    if (!m || m.villageId == null) //no village
      return null;
    if (m.villageId != null && this.villageId != null && m.villageId == this.villageId)
      return 7;
    if (m.playerId != null && this.playerId != null && m.playerId == this.playerId) //  Own = 0,
      return 0;
    if (m.allianceId != null && this.allianceId != null && m.allianceId == this.allianceId)//Alliance = 4,
      return 4;

    //find standings
    var standingPlayer = this.standingPlayers.find(ps => ps.id == m.playerId);
    var standingAlliance = this.standingAlliances.find(as => as.id == m.allianceId);
    if (standingPlayer == null && standingAlliance == null)//Neutral = 3,
      return 3;

    if (standingAlliance != null)//Friend = 1,Enemy = 2,FoodFriend = 5,ArchEnemy = 6
      return standingAlliance.standing;

    if (standingPlayer != null)//Friend = 1,Enemy = 2,FoodFriend = 5,ArchEnemy = 6
      return standingPlayer.standing;

    return null;
  }

  //====== DATA ======
  getBarracksData(onInit: boolean) {
    this.villageId = this.dataService.getSelectedVillageId();
    if (!this.villageId || this.villageId == -1) {
      if (onInit)
        return;
      else
        this.returnToStart("no villageId");
    }
    this.troopsPresent = 0;
    this.spinnerService.addToLoading();
    this.barrackService.getBarracksData(this.villageId).subscribe((result: BarracksData) => {
      this.designs = result.designsBarracks;
      this.designs = this.designs.sort((a, b) => {
        if (a.isAvailable > b.isAvailable)
          return -1;
        else if (a.isAvailable < b.isAvailable)
          return 1;
        else if (a.isAvailable == b.isAvailable) {
          if (a.name > b.name)
            return -1;
          else if (a.name < b.name)
            return 1;
        }
        return 0;
      });
      for (var i = 0; i < this.designs.length; i++) {
        this.designs[i].amount = 1;
        this.troopsPresent += this.designs[i].troopsPresent * this.designs[i].size;
      }

      this.includeEvents();

      this.maxBatchSize = result.maxBatchSize;
      this.settlerSumNeeded = result.settlerSumNeeded;
      this.troopLimit = result.troopLimit;
      this.villageX = result.x;
      this.villageY = result.y;

      this.checkMapAction();

      this.spinnerService.removeFromLoading();
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.spinnerService.removeFromLoading();
    });
  }

  recruit(design: DesignBarracks) {
    if (!this.villageId)
      return;

    if (this.troopLimit <= (this.troopsPresent + this.troopsRecruiting)) {
      this.toastService.showWarningToast($localize`COMMON_WARNING`, $localize`BARACKS_TROOP_LIMIT_EXEEDED`);
      return;
    }


    this.spinnerService.addToLoading();

    this.barrackService.recruitUnits(design.designId, this.villageId, design.amount).subscribe((result: string) => {
      this.toastService.showSuccessToast($localize`COMMON_SUCCESS`, $localize`RECRUIT_SUCCESS`);

      design.troopsRecruiting += design.amount;
      this.troopsRecruiting += design.amount;
      design.amount = 1;

      this.dataService.sendBroadcast('LOAD-RESOURCES');
      this.dataService.sendBroadcast('LOAD-EVENTS');

      this.spinnerService.removeFromLoading();
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.spinnerService.removeFromLoading();
    });
  }

  includeEvents() {
    var events = this.dataService.getEventData();
    if (events == null)
      return;
    for (var i = 0; i < this.designs.length; i++)
      this.designs[i].troopsRecruiting = 0;
    this.troopsRecruiting = 0;

    for (var i = 0; i < events.length; i++) {
      if (events[i].type == 7 && events[i].data != null && events[i].data.length > 1) //6 == recruiting
      {
        var splitted = events[i].data.split("|", 2);
        var designId = Number(splitted[0]);
        var amount = Number(splitted[1]);
        var design = this.designs.find(d => d.designId == designId);
        if (design != null) {
          design.troopsRecruiting += amount;
          this.troopsRecruiting += amount;
        }
      }
    }
  }

  getStandings() {
    this.spinnerService.addToLoading();
    this.mapService.getMapStandings().subscribe((result: MapStandingsResult) => {

      this.standingPlayers = result.standingsPlayer;
      this.standingAlliances = result.standingsAlliance;


      this.spinnerService.removeFromLoading();
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.spinnerService.removeFromLoading();
    });
  }

  getArmyData() {
    if (this.villageId == null)
      return;
    this.spinnerService.addToLoading();
    this.barrackService.getArmyData(this.villageId).subscribe((result: ArmyData) => {

      this.speedModifier = result.speedModifier;
      if (this.designs && this.designs.length > 0) {
        for (var i = 0; i < this.designs.length; i++) {
          var unavailable = result.unavailableTroops.find(uat => uat.id == this.designs[i].designId);
          if (unavailable != null)
            this.designs[i].troopsUnavailable = unavailable.value;
          else
            this.designs[i].troopsUnavailable = 0;
        }
      }
      //TODO Armies

      this.spinnerService.removeFromLoading();
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.spinnerService.removeFromLoading();
    });
  }

  getFielDetails() {
    if (this.xInput == null || this.yInput == null)
      return;
    this.spinnerService.addToLoading();
    this.mapService.getMapDetails(this.xInput, this.yInput).subscribe((result: MapDetailsResult) => {

      this.mapField = result.mapField;
      this.playerId = result.playerId;
      this.allianceId = result.allianceId;
      this.x = this.xInput!;
      this.y = this.yInput!;
      //  Own = 0,Alliance = 4,Neutral = 3,Friend = 1,Enemy = 2,FoodFriend = 5,ArchEnemy = 6
      this.relation = this.getRelationType(this.mapField);
      this.checkTypeButtons();


      this.spinnerService.removeFromLoading();
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.spinnerService.removeFromLoading();
    });
  }

  send() {
    //TODO checks

    this.spinnerService.addToLoading();

    var mapAction = this.actionType + "-" + this.actionSubtype

    var unitsToSend: IdValue[] = []    
    if (!this.isArmySelection) {
    for (var i = 0; i < this.selectableUnits.length; i++)
      if (this.selectableUnits[i].amountSend != null)
          unitsToSend.push({ id: this.selectableUnits[i].designId, value: this.selectableUnits[i].amountSend! });
    }

    this.barrackService.sendTroops(this.x, this.y, mapAction, this.selectedArmyId, unitsToSend,100, this.villageId!).subscribe((result: string) => {
      this.toastService.showSuccessToast($localize`COMMON_SUCCESS`, $localize`RECRUIT_SUCCESS`);

      //TODO reset everythingv
      this.setSubType(this.actionSubtype!);

      this.dataService.sendBroadcast('LOAD-EVENTS');

      this.spinnerService.removeFromLoading();
    }, error => {
      this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.spinnerService.removeFromLoading();
    });
  }
}
