<div *ngIf="playerExists">
  <div class="row">
    <div class="col-sm-6">
      <h4><span i18n>WELCOME_BACK</span><span> {{playerName}}</span></h4>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6">
      <ul class="list-group">
        <li *ngFor="let stat of statistics" class="list-group-item d-flex justify-content-between align-items-center" style="padding: 3px 6px;">
          <span>{{stat.name}}</span>
          <span>{{stat.value}}</span>
        </li>
      </ul>
    </div>
  </div>
</div>

<div *ngIf="!playerExists">
  <div class="row" style="padding: 12px 0">
    <div class="col-sm-6">
      <div class="card">
        <div class="card-header" i18n>
          NAME
        </div>
        <div class="card-body">
          <input [(ngModel)]="startup_JoinRequest.playerName" placeholder="Name" style="width: 100%" />
        </div>
      </div>
    </div>
  </div>
  <div class="row" style="padding: 12px 0">
    <div class="col-sm-6">
      <div class="card">
        <div class="card-header" i18n>
          NATION
        </div>
        <div class="card-body">
          <select class="form-select"
                  [(ngModel)]="startup_JoinRequest.selectedNationId"
                  (ngModelChange)="nationChanged()">
            <option *ngFor="let nation of selectData.nations" [value]="nation.id">
              {{nation.nameDisplay}}
            </option>
          </select>
          <div style="font-size: 12px; padding: 6px 0;">
            {{nationDescription}}
          </div>
          <ul class="list-group" *ngIf="nationEffects && nationEffects.length > 0 && startup_JoinRequest.selectedNationId && startup_JoinRequest.selectedNationId != -1">
            <li *ngFor="let effect of nationEffects" class="list-group-item d-flex justify-content-between align-items-center" style="padding: 3px 6px;">
              {{effect.Name}}
              <span class="badge bg-primary rounded-pill">{{effect.Value}}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="card">
        <div class="card-header" i18n>
          TRIBE
        </div>
        <div class="card-body">
          <select class="form-select"
                  [(ngModel)]="startup_JoinRequest.selectedTribeId"
                  (ngModelChange)="tribeChanged()">
            <option *ngFor="let tribe of tribeOptions" [value]="tribe.id">
              {{tribe.nameDisplay}}
            </option>
          </select>
          <div style="font-size: 12px; padding: 6px 0;">
            {{tribeDescription}}
          </div>
          <ul class="list-group" *ngIf="tribeEffects && tribeEffects.length > 0 && startup_JoinRequest.selectedTribeId && startup_JoinRequest.selectedTribeId != -1">
            <li *ngFor="let effect of tribeEffects" class="list-group-item d-flex justify-content-between align-items-center" style="padding: 3px 6px;">
              {{effect.Name}}
              <span class="badge bg-primary rounded-pill">{{effect.Value}}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="row" style="padding: 12px 0">
    <div class="col-sm-6">
      <div class="card">
        <div class="card-header" i18n>
          LOCATION
        </div>
        <div class="card-body">
          <select class="form-select"
                  [(ngModel)]="startup_JoinRequest.selectedDirection">
            <option *ngFor="let direction of selectData.directions" [value]="direction.id">
              {{direction.name}} ({{direction.fieldCount}})
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="card">
        <div class="card-header" i18n>
          MAP
        </div>
        <div class="card-body">
          MINIMAP
        </div>
      </div>
    </div>
  </div>
  <div class="row" style="padding: 12px 0">
    <div class="col-sm-12 text-center">
      <button class="btn btn-primary" (click)="joinServer(startup_JoinRequest)" i18n>JOIN_SERVER</button>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal" id="rewardsModal" data-bs-backdrop="static" tabindex="-1" aria-labelledby="rewardsModal" aria-hidden="true"
     [ngStyle]="{'display': modalIsOpen ? 'block' : 'none' }">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="fieldActionModalLabel" i18n>DAILY_LOGIN_REWARDS</h1>
        <button type="button" class="btn-close" (click)="closeModal()" aria-label="Close"></button>
      </div>
      <div class="modal-body" style="padding: 0;">
        <div class="row" style="padding: 12px;">
          <span i18n>REWARD_MESSAGE</span><br />
          <span>{{modalItemList}}</span>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeModal()" i18n>COMMON_CLOSE</button>
      </div>
    </div>
  </div>
</div>
