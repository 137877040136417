import { ChangeDetectorRef, Component, OnInit, } from '@angular/core';
import { LoginResponce } from './models/single/loginResponce';
import { User } from './models/single/user';
import { AuthService } from './services/data/auth.service';
import { DataService } from './services/data.service';
import { ToastService } from './services/toast.service';
import { Router, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { SpinnerService } from './services/spinner.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'DZO_UI';
  loggedIn = false;
  isGame = false;
  url = "";
  user = new User();

  overviewIcon = "&#128081;";// "&#128681;";
  mapIcon = "&#128506;";
  allianceIcon = "&#128737;";
  scienceIcon = "&#9879;";
  rankingIcon = "&#129351;";
  designIcon = "&#129527;";
  inventoryIcon = "&#129510;";

  showSpinner$ = this.spinnerService.loadingAction$;

  constructor(private authService: AuthService,
    private dataService: DataService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private toastService: ToastService,
    private spinnerService: SpinnerService) { }

  ngOnInit() {
    console.log('OnInit');
    this.getMe();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        //spinner?
      }
      if (event instanceof NavigationEnd) {
        this.isGame = event.url.includes('/game');
        this.url = event.url.substring(1);
      }
      if (event instanceof NavigationError) {
        console.log(event.error);
      }
    });

    ////EXAMPLE SENDING TO SUBJECT (BROADCAST)
    ////this.dataService.sendRouteMessage('/game');

    ////EXAMPLE RECIVING FROM SUBJECT (BROADCAST)
    //this.dataService._routeMessageSource$.subscribe(route => {
    //    this.isGame = route.includes('/game');
    //  console.log('new route: ' + route);
    //  this.cd.detectChanges;
    //});

  }

  logout() {
    this.authService.logout().subscribe((data: string) => {
      this.spinnerService.addToLoading();
      localStorage.removeItem('authToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('authCreationTime');
      console.log('Lout success: ' + data);
      this.loggedIn = false;
      this.router.navigate(['main']);
      this.toastService.showSuccessToast($localize`COMMON_SUCCESS`, $localize`LOGOUT_SUCCESS`);
      this.spinnerService.removeFromLoading();
    }, error => {
      if (error == "UserNotFound")
        this.toastService.showWarningToast($localize`COMMON_ERROR`, $localize`COMMON_USERNOTFOUND`);
      else
        this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.spinnerService.removeFromLoading();
    });
  }

  login(user: User) {
    this.spinnerService.addToLoading();
    user.email = user.username;
    this.authService.login(user).subscribe((data: LoginResponce) => {
      localStorage.setItem('authToken', data.authToken);
      localStorage.setItem('refreshToken', data.refreshToken);
      localStorage.setItem('authCreationTime', new Date().toString());
      console.log('Login success: ' + data);
      this.loggedIn = true;
      this.router.navigate(['home']);
      this.spinnerService.removeFromLoading();
      this.toastService.showSuccessToast($localize`COMMON_SUCCESS`, $localize`LOGIN_SUCCESS`);
    }, error => {
      if (error == "LoginFailed")
        this.toastService.showWarningToast($localize`COMMON_ERROR`, $localize`LOGIN_FAILED`);
      else if (error == "NOTACTIVE")
        this.toastService.showWarningToast($localize`COMMON_ERROR`, $localize`LOGIN_NOTACTIVE`);
      else
        this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.spinnerService.removeFromLoading();
    });
  }

  resetPassword(user: User) {
    this.spinnerService.addToLoading();
    user.email = user.username;
    this.authService.resetPassword(user).subscribe((result: string) => {
      this.toastService.showSuccessToast($localize`COMMON_SUCCESS`, $localize`RESETPW_SUCCESS`);
      this.spinnerService.removeFromLoading();
    }, error => {
      if (error == "UserNotFound")
        this.toastService.showWarningToast($localize`COMMON_ERROR`, $localize`COMMON_USERNOTFOUND`);
      else
        this.toastService.showErrorToast($localize`COMMON_ERROR`, error);
      this.spinnerService.removeFromLoading();
    });
  }

  getMe() {
    this.authService.getMe().subscribe((id: string) => {
      this.loggedIn = true;
      //console.log('ID:' + id);
    }, error => {
      //console.log('GetMe oops: ', error);
    });
  }
}
