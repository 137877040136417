import { Injectable } from '@angular/core';
import { NameValue } from '../models/pair-models';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  constructor() { }

  public handlePlaceholder(input: string, values: NameValue[]): string {
    if (!input.includes("###"))
      return input;
    var result = input


    //TODO
    //1. option simple replace = replace placeholder by value from valuelist
    //###placeholdername### get from value list by name to get value
    //2. option a placeholder that needs translation itself
    //###§Nation§###


    return result;
  }

  public getNationName(nation: string): string {
    switch (nation) {
      case "DWARFS":
        return this.handlePlaceholder($localize`NATION_NAME_DWARFS`, []);
      case "ORKS":
        return this.handlePlaceholder($localize`NATION_NAME_ORKS`, []);
      case "HUMANS":
        return this.handlePlaceholder($localize`NATION_NAME_HUMANS`, []);
      case "ELFS":
        return this.handlePlaceholder($localize`NATION_NAME_ELFS`, []);
      case "ALBAE":
        return this.handlePlaceholder($localize`NATION_NAME_ALBAE`, []);
    }
    return nation;
  }

  public getNationDescription(nation: string): string {
    switch (nation) {
      case "DWARFS":
        return this.handlePlaceholder($localize`NATION_DESCRIPTION_DWARFS`, []);
      case "ORKS":
        return this.handlePlaceholder($localize`NATION_DESCRIPTION_ORKS`, []);
      case "HUMANS":
        return this.handlePlaceholder($localize`NATION_DESCRIPTION_HUMANS`, []);
      case "ELFS":
        return this.handlePlaceholder($localize`NATION_DESCRIPTION_ELFS`, []);
      case "ALBAE":
        return this.handlePlaceholder($localize`NATION_DESCRIPTION_ALBAE`, []);
    }
    return nation;
  }

  public getTribeName(tribe: string): string {
    switch (tribe) {
      //DWARFS
      case "FIRST":
        return this.handlePlaceholder($localize`TRIBE_NAME_FIRST`, []);
      case "SECOND":
        return this.handlePlaceholder($localize`TRIBE_NAME_SECOND`, []);
      case "THIRD":
        return this.handlePlaceholder($localize`TRIBE_NAME_THIRD`, []);
      case "FORTH":
        return this.handlePlaceholder($localize`TRIBE_NAME_FORTH`, []);
      case "FIFTH":
        return this.handlePlaceholder($localize`TRIBE_NAME_FIFTH`, []);
      //ORKS
      case "TOBORIBAR":
        return this.handlePlaceholder($localize`TRIBE_NAME_TOBORIBAR`, []);
      case "KRAGGASH":
        return this.handlePlaceholder($localize`TRIBE_NAME_KRAGGASH`, []);
      case "OARCOS":
        return this.handlePlaceholder($localize`TRIBE_NAME_OARCOS`, []);
      case "FLEX":
        return this.handlePlaceholder($localize`TRIBE_NAME_FLEX`, []);
      //HUMANS
      case "GAURAGAR":
        return this.handlePlaceholder($localize`TRIBE_NAME_GAURAGAR`, []);
      case "IDOSLAN":
        return this.handlePlaceholder($localize`TRIBE_NAME_IDOSLAN`, []);
      case "TABAIN":
        return this.handlePlaceholder($localize`TRIBE_NAME_TABAIN`, []);
      case "URGON":
        return this.handlePlaceholder($localize`TRIBE_NAME_URGON`, []);
      case "WEYURN":
        return this.handlePlaceholder($localize`TRIBE_NAME_WEYURN`, []);
      //ELFS
      case "GOLDEN_PLAIN":
        return this.handlePlaceholder($localize`TRIBE_NAME_GOLDEN_PLAIN`, []);
      case "ALANDUR":
        return this.handlePlaceholder($localize`TRIBE_NAME_ALANDUR`, []);
      case "GWANDALUR":
        return this.handlePlaceholder($localize`TRIBE_NAME_GWANDALUR`, []);
      case "LESINTEIL":
        return this.handlePlaceholder($localize`TRIBE_NAME_LESINTEIL`, []);
      //ALBAE
      case "DSON_FAIMON":
        return this.handlePlaceholder($localize`TRIBE_NAME_DSON_FAIMON`, []);
      case "DSON_BALSUR":
        return this.handlePlaceholder($localize`TRIBE_NAME_DSON_BALSUR`, []);
      case "DSON_SOMRAN":
        return this.handlePlaceholder($localize`TRIBE_NAME_DSON_SOMRAN`, []);
    }
    return tribe;
  }

  public getTribeDescription(tribe: string): string {
    switch (tribe) {
      //DWARFS
      case "FIRST":
        return this.handlePlaceholder($localize`TRIBE_DESC_FIRST`, []);
      case "SECOND":
        return this.handlePlaceholder($localize`TRIBE_DESC_SECOND`, []);
      case "THIRD":
        return this.handlePlaceholder($localize`TRIBE_DESC_THIRD`, []);
      case "FORTH":
        return this.handlePlaceholder($localize`TRIBE_DESC_FORTH`, []);
      case "FIFTH":
        return this.handlePlaceholder($localize`TRIBE_DESC_FIFTH`, []);
      //ORKS
      case "TOBORIBAR":
        return this.handlePlaceholder($localize`TRIBE_DESC_TOBORIBAR`, []);
      case "KRAGGASH":
        return this.handlePlaceholder($localize`TRIBE_DESC_KRAGGASH`, []);
      case "OARCOS":
        return this.handlePlaceholder($localize`TRIBE_DESC_OARCOS`, []);
      case "FLEX":
        return this.handlePlaceholder($localize`TRIBE_DESC_FLEX`, []);
      //HUMANS
      case "GAURAGAR":
        return this.handlePlaceholder($localize`TRIBE_DESC_GAURAGAR`, []);
      case "IDOSLAN":
        return this.handlePlaceholder($localize`TRIBE_DESC_IDOSLAN`, []);
      case "TABAIN":
        return this.handlePlaceholder($localize`TRIBE_DESC_TABAIN`, []);
      case "URGON":
        return this.handlePlaceholder($localize`TRIBE_DESC_URGON`, []);
      case "WEYURN":
        return this.handlePlaceholder($localize`TRIBE_DESC_WEYURN`, []);
      //ELFS
      case "GOLDEN_PLAIN":
        return this.handlePlaceholder($localize`TRIBE_DESC_GOLDEN_PLAIN`, []);
      case "ALANDUR":
        return this.handlePlaceholder($localize`TRIBE_DESC_ALANDUR`, []);
      case "GWANDALUR":
        return this.handlePlaceholder($localize`TRIBE_DESC_GWANDALUR`, []);
      case "LESINTEIL":
        return this.handlePlaceholder($localize`TRIBE_DESC_LESINTEIL`, []);
      //ALBAE
      case "DSON_FAIMON":
        return this.handlePlaceholder($localize`TRIBE_DESC_DSON_FAIMON`, []);
      case "DSON_BALSUR":
        return this.handlePlaceholder($localize`TRIBE_DESC_DSON_BALSUR`, []);
      case "DSON_SOMRAN":
        return this.handlePlaceholder($localize`TRIBE_DESC_DSON_SOMRAN`, []);
    }
    return tribe;
  }

  public getEffectName(effect: string): string {
    switch (effect) {
      case "STONE":
        return this.handlePlaceholder($localize`EFFECT_NAME_STONE`, []);
      case "WOOD":
        return this.handlePlaceholder($localize`EFFECT_NAME_WOOD`, []);
      case "IRON":
        return this.handlePlaceholder($localize`EFFECT_NAME_IRON`, []);
      case "FOOD":
        return this.handlePlaceholder($localize`EFFECT_NAME_FOOD`, []);
      case "STORAGE":
        return this.handlePlaceholder($localize`EFFECT_NAME_STORAGE`, []);
      case "FOODSTORAGE":
        return this.handlePlaceholder($localize`EFFECT_NAME_FOODSTORAGE`, []);
      case "STONE%":
        return this.handlePlaceholder($localize`EFFECT_NAME_STONE%`, []);
      case "WOOD%":
        return this.handlePlaceholder($localize`EFFECT_NAME_WOOD%`, []);
      case "IRON%":
        return this.handlePlaceholder($localize`EFFECT_NAME_IRON%`, []);
      case "FOOD%":
        return this.handlePlaceholder($localize`EFFECT_NAME_FOOD%`, []);
      case "STORAGE%":
        return this.handlePlaceholder($localize`EFFECT_NAME_STORAGE%`, []);
      case "FOODSTORAGE%":
        return this.handlePlaceholder($localize`EFFECT_NAME_FOODSTORAGE%`, []);
      case "STONE+":
        return this.handlePlaceholder($localize`EFFECT_NAME_STONE+`, []);
      case "WOOD+":
        return this.handlePlaceholder($localize`EFFECT_NAME_WOOD+`, []);
      case "IRON+":
        return this.handlePlaceholder($localize`EFFECT_NAME_IRON+`, []);
      case "FOOD+":
        return this.handlePlaceholder($localize`EFFECT_NAME_FOOD+`, []);
      case "STORAGE+":
        return this.handlePlaceholder($localize`EFFECT_NAME_STORAGE+`, []);
      case "FOODSTORAGE+":
        return this.handlePlaceholder($localize`EFFECT_NAME_FOODSTORAGE+`, []);
      case "CONSTRUCTION_SPEED":
        return this.handlePlaceholder($localize`EFFECT_NAME_CONSTRUCTION_SPEED`, []);
      case "RECRUITING_SPEED":
        return this.handlePlaceholder($localize`EFFECT_NAME_RECRUITING_SPEED`, []);
      case "CONSTRUCTION_SPEED%":
        return this.handlePlaceholder($localize`EFFECT_NAME_CONSTRUCTION_SPEED%`, []);
      case "RECRUITING_SPEED%":
        return this.handlePlaceholder($localize`EFFECT_NAME_RECRUITING_SPEED%`, []);
      case "CONSTRUCTION_SPEED+":
        return this.handlePlaceholder($localize`EFFECT_NAME_CONSTRUCTION_SPEED+`, []);
      case "RECRUITING_SPEED+":
        return this.handlePlaceholder($localize`EFFECT_NAME_RECRUITING_SPEED+`, []);
      //case "":
      //  return this.handlePlaceholder($localize`EFFECT_NAME_`, []);

    }
    return effect;
  }

  public getSlotType(slotType: number): string {
    switch (slotType) {
      case 0:
        return this.handlePlaceholder($localize`SLOT_TYPE_MAIN`, []);
      case 2:
        return this.handlePlaceholder($localize`SLOT_TYPE_RES`, []);
      case 4:
        return this.handlePlaceholder($localize`SLOT_TYPE_TOWN`, []);
      case 8:
        return this.handlePlaceholder($localize`SLOT_TYPE_DEF`, []);
      case 16:
        return this.handlePlaceholder($localize`SLOT_TYPE_WALL`, []);
    }
    return "SLOT_TYPE_" + slotType;
  }

  public getFieldType(fieldTypeName: string): string {
    switch (fieldTypeName) {
      case "RAGULAR":
        return this.handlePlaceholder($localize`FIELD_TYPE_RAGULAR`, []);
      case "GRAS":
        return this.handlePlaceholder($localize`FIELD_TYPE_GRAS`, []);
      case "FOREST":
        return this.handlePlaceholder($localize`FIELD_TYPE_FOREST`, []);
      case "HILL":
        return this.handlePlaceholder($localize`FIELD_TYPE_HILL`, []);
      case "MOUNTAIN":
        return this.handlePlaceholder($localize`FIELD_TYPE_MOUNTAIN`, []);
      case "LAKES":
        return this.handlePlaceholder($localize`FIELD_TYPE_LAKES`, []);
      case "GROVE":
        return this.handlePlaceholder($localize`FIELD_TYPE_GROVE`, []);
      case "GRAS_RICH":
        return this.handlePlaceholder($localize`FIELD_TYPE_GRAS_RICH`, []);
      case "FOREST_RICH":
        return this.handlePlaceholder($localize`FIELD_TYPE_FOREST_RICH`, []);
      case "HILL_RICH":
        return this.handlePlaceholder($localize`FIELD_TYPE_HILL_RICH`, []);
      case "MOUNTAIN_RICH":
        return this.handlePlaceholder($localize`FIELD_TYPE_MOUNTAIN_RICH`, []);
      case "LAKES_RICH":
        return this.handlePlaceholder($localize`FIELD_TYPE_LAKES_RICH`, []);
      case "GROVE_RICH":
        return this.handlePlaceholder($localize`FIELD_TYPE_GROVE_RICH`, []);
      case "HIGHLANDS":
        return this.handlePlaceholder($localize`FIELD_TYPE_HIGHLANDS`, []);
      case "BARREN":
        return this.handlePlaceholder($localize`FIELD_TYPE_BARREN`, []);
      case "SWAMP":
        return this.handlePlaceholder($localize`FIELD_TYPE_SWAMP`, []);
      case "DESERT":
        return this.handlePlaceholder($localize`FIELD_TYPE_DESERT`, []);
    }
    return fieldTypeName;
  }

  public getBuildingName(building: string): string {
    switch (building) {
      case "MAINBUILDING":
        return this.handlePlaceholder($localize`BUILDING_NAME_MAINBUILDING`, []);
      case "WOODCUTTER":
        return this.handlePlaceholder($localize`BUILDING_NAME_WOODCUTTER`, []);
      case "STONEPIT":
        return this.handlePlaceholder($localize`BUILDING_NAME_STONEPIT`, []);
      case "IRONMINE":
        return this.handlePlaceholder($localize`BUILDING_NAME_IRONMINE`, []);
      case "FARM":
        return this.handlePlaceholder($localize`BUILDING_NAME_FARM`, []);
    }
    return building;
  }

  public getBuildingDescription(building: string, values: NameValue[]): string {
    switch (building) {
      case "MAINBUILDING":
        return this.handlePlaceholder($localize`BUILDING_DESC_MAINBUILDING`, []);
      case "WOODCUTTER":
        return this.handlePlaceholder($localize`BUILDING_DESC_WOODCUTTER`, []);
      case "STONEPIT":
        return this.handlePlaceholder($localize`BUILDING_DESC_STONEPIT`, []);
      case "IRONMINE":
        return this.handlePlaceholder($localize`BUILDING_DESC_IRONMINE`, []);
      case "FARM":
        return this.handlePlaceholder($localize`BUILDING_DESC_FARM`, []);
    }
    return building;
  }

  public getBlockedMessages(message: string): string {
    switch (message) {
      case "":
        return this.handlePlaceholder($localize`MAINBUILDING_NOT_3`, []);
      case "":
        return this.handlePlaceholder($localize`MAINBUILDING_NOT_4`, []);
      case "":
        return this.handlePlaceholder($localize`MAINBUILDING_NOT_5`, []);
    }
    return message;
  }

  public getScienceName(science: string): string {
    switch (science) {
      case "BASIC_SCIENCE":
        return this.handlePlaceholder($localize`SCIENCE_NAME_BASIC_SCIENCE`, []);
      case "RES_STORAGE_1":
        return this.handlePlaceholder($localize`SCIENCE_NAME_RES_STORAGE_1`, []);
      case "WOOD_PROD_1":
        return this.handlePlaceholder($localize`SCIENCE_NAME_WOOD_PROD_1`, []);
      case "STONE_PROD_1":
        return this.handlePlaceholder($localize`SCIENCE_NAME_STONE_PROD_1`, []);
      case "IRON_PROD_1":
        return this.handlePlaceholder($localize`SCIENCE_NAME_IRON_PROD_1`, []);
      case "FOOD_STORAGE_1":
        return this.handlePlaceholder($localize`SCIENCE_NAME_FOOD_STORAGE_1`, []);
      case "FOOD_PROD_1":
        return this.handlePlaceholder($localize`SCIENCE_NAME_FOOD_PROD_1`, []);
      case "SIMPLE_SCIENCE":
        return this.handlePlaceholder($localize`SCIENCE_NAME_SIMPLE_SCIENCE`, []);
    }
    return science;
  }

  public getScienceDescription(science: string): string {
    switch (science) {
      case "BASIC_SCIENCE":
        return this.handlePlaceholder($localize`SCIENCE_DESCRIPTION_BASIC_SCIENCE`, []);
      case "RES_STORAGE_1":
        return this.handlePlaceholder($localize`SCIENCE_DESCRIPTION_RES_STORAGE_1`, []);
      case "WOOD_PROD_1":
        return this.handlePlaceholder($localize`SCIENCE_DESCRIPTION_WOOD_PROD_1`, []);
      case "STONE_PROD_1":
        return this.handlePlaceholder($localize`SCIENCE_DESCRIPTION_STONE_PROD_1`, []);
      case "IRON_PROD_1":
        return this.handlePlaceholder($localize`SCIENCE_DESCRIPTION_IRON_PROD_1`, []);
      case "FOOD_STORAGE_1":
        return this.handlePlaceholder($localize`SCIENCE_DESCRIPTION_FOOD_STORAGE_1`, []);
      case "FOOD_PROD_1":
        return this.handlePlaceholder($localize`SCIENCE_DESCRIPTION_FOOD_PROD_1`, []);
      case "SIMPLE_SCIENCE":
        return this.handlePlaceholder($localize`SCIENCE_DESCRIPTION_SIMPLE_SCIENCE`, []);
    }
    return science;
  }

  public getTechName(tech: string): string {
    switch (tech) {
      case "TECH_1_STORAGE":
        return this.handlePlaceholder($localize`TECH_NAME_1_STORAGE`, []);
      case "TECH_1_FOODSTORAGE":
        return this.handlePlaceholder($localize`TECH_NAME_1_FOODSTORAGE`, []);
      case "TECH_1_RESPROD":
        return this.handlePlaceholder($localize`TECH_NAME_1_RESPROD`, []);
      case "TECH_2_WOOD":
        return this.handlePlaceholder($localize`TECH_NAME_2_WOOD`, []);
      case "TECH_2_STONE":
        return this.handlePlaceholder($localize`TECH_NAME_2_STONE`, []);
      case "TECH_2_IRON":
        return this.handlePlaceholder($localize`TECH_NAME_2_IRON`, []);
      case "TECH_3_HP":
        return this.handlePlaceholder($localize`TECH_NAME_3_HP`, []);
      case "TECH_3_LOAD":
        return this.handlePlaceholder($localize`TECH_NAME_3_LOAD`, []);
      case "TECH_3_REC_SPEED":
        return this.handlePlaceholder($localize`TECH_NAME_3_REC_SPEED`, []);
    }
    return tech;
  }

  public getTechDescription(tech: string): string {
    switch (tech) {
      case "TECH_1_STORAGE":
        return this.handlePlaceholder($localize`TECH_DESCRIPTION_1_STORAGE`, []);
      case "TECH_1_FOODSTORAGE":
        return this.handlePlaceholder($localize`TECH_DESCRIPTION_1_FOODSTORAGE`, []);
      case "TECH_1_RESPROD":
        return this.handlePlaceholder($localize`TECH_DESCRIPTION_1_RESPROD`, []);
      case "TECH_2_WOOD":
        return this.handlePlaceholder($localize`TECH_DESCRIPTION_2_WOOD`, []);
      case "TECH_2_STONE":
        return this.handlePlaceholder($localize`TECH_DESCRIPTION_2_STONE`, []);
      case "TECH_2_IRON":
        return this.handlePlaceholder($localize`TECH_DESCRIPTION_2_IRON`, []);
      case "TECH_3_HP":
        return this.handlePlaceholder($localize`TECH_DESCRIPTION_3_HP`, []);
      case "TECH_3_LOAD":
        return this.handlePlaceholder($localize`TECH_DESCRIPTION_3_LOAD`, []);
      case "TECH_3_REC_SPEED":
        return this.handlePlaceholder($localize`TECH_DESCRIPTION_3_REC_SPEED`, []);
    }
    return tech;
  }

  public getVillageLevelTranslations(input: string): string {
    switch (input) {
      case "VILLAGE_LEVEL_CATEGORY_0"://Village = 0
        return this.handlePlaceholder($localize`VILLAGE_LEVEL_CATEGORY_0`, []);
      case "VILLAGE_LEVEL_CATEGORY_1"://City = 1
        return this.handlePlaceholder($localize`VILLAGE_LEVEL_CATEGORY_1`, []);
      case "VILLAGE_LEVEL_CATEGORY_2"://Fortress = 2
        return this.handlePlaceholder($localize`VILLAGE_LEVEL_CATEGORY_2`, []);
      case "BUILDING_SLOT_1"://Mainbuilding = 1
        return this.handlePlaceholder($localize`BUILDING_SLOT_1`, []);
      case "BUILDING_SLOT_2"://Resource = 2
        return this.handlePlaceholder($localize`BUILDING_SLOT_2`, []);
      case "BUILDING_SLOT_4"://Town = 4
        return this.handlePlaceholder($localize`BUILDING_SLOT_4`, []);
      case "BUILDING_SLOT_8"://Defence = 8
        return this.handlePlaceholder($localize`BUILDING_SLOT_8`, []);
      case "BUILDING_SLOT_16"://Wall = 16
        return this.handlePlaceholder($localize`BUILDING_SLOT_16`, []);
    }
    return input;
  }

  public getItemTranslations(input: string): string {
    switch (input) {
      case "COMMON_RESOURCE_BOX":
        return this.handlePlaceholder($localize`COMMON_RESOURCE_BOX`, []);
      case "COMMON_BLUEPRINT_BOX":
        return this.handlePlaceholder($localize`COMMON_BLUEPRINT_BOX`, []);
      case "":
        return this.handlePlaceholder($localize``, []);
    }
    return  input;
  }

  public getActionTypeTranslations(input: string): string {
    switch (input) {
      case "special-settle":
        return this.handlePlaceholder($localize`ACTION_TYPE_SETTLE`, []);
      case "special-scout":
        return this.handlePlaceholder($localize`ACTION_TYPE_SCOUT`, []);
      case "special-terraForm":
        return this.handlePlaceholder($localize`ACTION_TYPE_TERRAFORM`, []);
      case "attack-army":
        return this.handlePlaceholder($localize`ACTION_TYPE_ARMY`, []);
      case "attack-raid":
        return this.handlePlaceholder($localize`ACTION_TYPE_RAID`, []);
      case "attack-siege":
        return this.handlePlaceholder($localize`ACTION_TYPE_SIEGE`, []);

      //case "":
      //  return this.handlePlaceholder($localize`ACTION_TYPE_`, []);
    }
    return input;
  }

  public getReportTypeTranslations(input: string, isIscoming: boolean): string {
    switch (input) {
      case "special-settle":
        return this.handlePlaceholder($localize`REPORT_TYPE_SETTLE`, []);
      case "special-scout":
        return this.handlePlaceholder($localize`REPORT_TYPE_SCOUT`, []);
      case "special-terraForm":
        return this.handlePlaceholder($localize`REPORT_TYPE_TERRAFORM`, []);
      case "attack-army":
        return this.handlePlaceholder($localize`REPORT_TYPE_ARMY`, []);
      case "attack-raid":
        return this.handlePlaceholder($localize`REPORT_TYPE_RAID`, []);
      case "attack-siege":
        return this.handlePlaceholder($localize`REPORT_TYPE_SIEGE`, []);

      //case "":
      //  return this.handlePlaceholder($localize`REPORT_TYPE_`, []);
    }
    return input;
  }
}
