<div>
  <!--<button class="btn btn-light" (click)="openModal('message')" style="width: 50%; background-color: white;">
    <span [innerHTML]="messagesIcon" *ngIf="messagesCountNew === 0"></span>
    <span [innerHTML]="messagesNewIcon" *ngIf="messagesCountNew !== 0"></span>
    <span> {{messagesCount}} </span><span i18n>MESSAGES</span>
    <span *ngIf="messagesCountNew !== 0"> (</span>
    <span *ngIf="messagesCountNew !== 0">{{messagesCountNew}} </span>
    <span *ngIf="messagesCountNew !== 0" i18n>NEW</span>
    <span *ngIf="messagesCountNew !== 0">)</span>
  </button>-->
  <button class="btn btn-light" (click)="openModal('message')" style="width: 50%; background-color: white;" title="{{getTranslation('', 'MESSAGE')}}">
    <span [innerHTML]="messagesIcon" *ngIf="messagesCountNew === 0"></span>
    <span [innerHTML]="messagesNewIcon" *ngIf="messagesCountNew !== 0"></span>
    <span [ngStyle]="{'font-weight': messagesCountNew > 0 ? 'bold' : '' }"> {{messagesCount}} </span>
  </button>
  <button class="btn btn-light" (click)="openModal('report')" style="width: 50%; background-color: white;" title="{{getTranslation('', 'REPORT')}}">
    <span [innerHTML]="reportsIcon" *ngIf="reportCountNew === 0"></span>
    <span [innerHTML]="reportsNewIcon" *ngIf="reportCountNew > 0"></span>
    <span [ngStyle]="{'font-weight': reportCountNew > 0 ? 'bold' : '' }"> {{reportCount}} </span>
  </button>
</div>

<!-- Modal MESSAGES-->
<div class="modal" id="messagesModal" data-bs-backdrop="static" tabindex="-1" aria-labelledby="messagesModal" aria-hidden="true"
     [ngStyle]="{'display': messagesModalIsOpen ? 'block' : 'none' }">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="fieldActionModalLabel" i18n>MESSAGES</h1>
        <button type="button" class="btn-close" (click)="closeModal()" aria-label="Close"></button>
      </div>
      <div class="modal-body" style="padding: 0;">
        <div class="row" style="margin: 0;">
          <div class="col col-sm-3" style="padding:16px 0 16px 0">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active" id="outer-tab" data-bs-toggle="tab" data-bs-target="#in-tab-pane" type="button" role="tab" aria-controls="outer-tab-pane" aria-selected="true" i18n>INBOX</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="inner-tab" data-bs-toggle="tab" data-bs-target="#out-tab-pane" type="button" role="tab" aria-controls="inner-tab-pane" aria-selected="false" i18n>OUTBOX</button>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade show active " id="in-tab-pane" role="tabpanel" aria-labelledby="outer-tab" tabindex="0">
                <div class="row" style="margin: 0;">
                  <ul class="list-group" style="padding:0;">
                    <li class="list-group-item"
                        (click)="selectMessage(messageIn)"
                        *ngFor="let messageIn of messagesIn"
                        [ngClass]="{'active': messageIn == messageSelected}"
                        [ngStyle]="{'font-weight': messageIn.isUnread ? 'bold' : '' }">
                      {{messageIn.subject}}
                    </li>
                    <li class="list-group-item" *ngIf="messagesIn.length === 0" i18n>MESSAGES_NO_MESSAGES</li>
                  </ul>
                </div>
              </div>
              <div class="tab-pane fade" id="out-tab-pane" role="tabpanel" aria-labelledby="inner-tab" tabindex="1">
                <div class="row" style="margin: 0;">
                  <ul class="list-group" style="padding:0;">
                    <li class="list-group-item"
                        (click)="selectMessage(messageOut)"
                        *ngFor="let messageOut of messagesOut"
                        [ngClass]="{'active': messageOut == messageSelected}"
                        [ngStyle]="{'font-weight': messageOut.isUnread ? 'bold' : '' }">
                      {{messageOut.subject}}
                    </li>
                    <li class="list-group-item" *ngIf="messagesOut.length === 0" i18n>MESSAGES_NO_MESSAGES</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col col-sm-9" style="padding: 16px 0 16px 0; border-left: solid lightgray 1px;">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link" [ngClass]="{'active': selectedTab == 0}" id="outer-tab" (click)="setTab(0)" type="button" role="tab" aria-controls="outer-tab-pane" aria-selected="true" i18n>READ</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" [ngClass]="{'active': selectedTab == 1}" id="inner-tab" (click)="setTab(1)" type="button" role="tab" aria-controls="inner-tab-pane" aria-selected="false" i18n>WRITE</button>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <!--==== READ ====-->
              <div class="tab-pane" [ngClass]="{'active show': selectedTab == 0}" id="read-tab-pane" role="tabpanel" aria-labelledby="outer-tab" tabindex="0">
                <div class="row" style="margin: 0 16px 0 0;"
                     *ngIf="messageSelected != null">
                  <div class="row" style="margin: 0 0 0 12px;padding-top:6px;">
                    <div class="col col-sm-3">
                      <span i18n>SENDER</span><br />
                      <span>
                        {{messageSelected.senderName}}
                      </span>
                    </div>
                    <div class="col col-sm-3">
                      <span></span><br />
                      <span>
                        {{messageSelected.sendDate| date:'dd.MM.yy HH:mm'}}
                      </span>
                    </div>
                    <div class="col col-sm-3">
                      <span i18n>RECEIVER</span><br />
                      <span>
                        {{messageSelected.reseiverName}}
                      </span>
                    </div>
                    <div class="col col-sm-3">
                      <div class="row">
                        <div class="col col-sm-6">
                          <button type="button" class="btn btn-secondary" (click)="getNewMessage(messageSelected,null,true)"><span [innerHTML]="replyIcon"></span></button>
                        </div>
                        <div class="col col-sm-6">
                          <button type="button" class="btn btn-secondary" (click)="deleteMessage(messageSelected)"><span [innerHTML]="deleteIcon"></span></button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="margin: 0 0 0 12px; padding-bottom: 12px; ">
                    <span style="padding:0" i18n>SUBJECT</span>
                    <input [(ngModel)]="messageSelected.subject" style="width: 100%" [disabled]="true" />
                  </div>
                  <div class="row" style="margin: 0 0 0 12px; padding-bottom: 16px;">
                    <span style="padding:0" i18n>BODY</span>
                    <textarea [(ngModel)]="messageSelected.body" style="width: 100%" [disabled]="true"></textarea>
                  </div>
                </div>
                <div class="row" style="margin: 0 16px 0 0;"
                     *ngIf="messageSelected == null" i18n>NO_MESSAGE_SELECTED</div>
              </div>
              <!--==== WRITE ====-->
              <div class="tab-pane" [ngClass]="{'active show': selectedTab == 1}" id="write-tab-pane" role="tabpanel" aria-labelledby="inner-tab" tabindex="1">
                <div class="row" style="margin: 0 16px 0 0;"
                     *ngIf="messageNew">

                  <div class="row" style="margin: 0 0 0 12px; padding-bottom: 12px; padding-left: 0;">
                    <div class="col col-sm-6">
                      <span style="padding:0" i18n>RECEIVER</span>
                      <input [(ngModel)]="messageNew.reseiverName" style="width: 100%"
                             [disabled]="messageNew.replyToId != null" />
                    </div>
                    <div class="col col-sm-6" style="padding-top: 6px;">
                      <div class="row">
                        <div class="col col-sm-9">
                          &nbsp;
                        </div>
                        <div class="col col-sm-3">
                          <button type="button" class="btn btn-secondary" (click)="getNewMessage(null,null,false)"><span [innerHTML]="deleteIcon"></span></button>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="row" style="margin: 0 0 0 12px; padding-bottom: 12px; ">
                    <span style="padding:0" i18n>SUBJECT</span>
                    <input [(ngModel)]="messageNew.subject" style="width: 100%" />
                  </div>
                  <div class="row" style="margin: 0 0 0 12px; padding-bottom: 16px;">
                    <span style="padding:0" i18n>BODY</span>
                    <textarea [(ngModel)]="messageNew.body" style="width: 100%"></textarea>
                  </div>
                  <div class="row" style="margin: 0 0 0 12px; padding-bottom: 16px;">
                    <button type="button" class="btn btn-primary" style="width: 30%;"
                            (click)="sendMessage()" i18n>
                      SEND
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeModal()" i18n>COMMON_CLOSE</button>
      </div>
    </div>
  </div>
</div>

<!-- Modal REPORTS-->
<div class="modal" id="reportsModal" data-bs-backdrop="static" tabindex="-1" aria-labelledby="reportsModal" aria-hidden="true"
     [ngStyle]="{'display': reportsModalIsOpen ? 'block' : 'none' }">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="fieldActionModalLabel" i18n>REPORTS</h1>
        <button type="button" class="btn-close" (click)="closeModal()" aria-label="Close"></button>
      </div>
      <div class="modal-body" style="padding: 0;">
        <div class="row" style="margin: 0;">
          <div class="col col-sm-3" style="padding:16px 0 16px 0">
            <div class="row" style="margin: 0; padding-top: 40px;">
              <ul class="list-group" style="padding:0;">
                <li class="list-group-item"
                    (click)="selectReport(report)"
                    *ngFor="let report of reports"
                    [ngClass]="{'active': report == reportSelected}"
                    [ngStyle]="{'font-weight': report.isUnread ? 'bold' : '' }"
                    style="padding: 0;">
                  <div class="row">
                    <div class="col col-sm-3" style="padding-top: 5px;">
                      <span *ngIf="report.isIncoming == true" [innerHTML]="getIconByName('downIcon')" style="font-size: 24px; position: absolute;"></span>
                      <span *ngIf="report.isIncoming == false" [innerHTML]="getIconByName('upIcon')" style="font-size: 24px; position: absolute;"></span>
                      <span [innerHTML]="report.icon" style="font-size: 24px; margin-left: 6px; position: absolute; left: 12px;"></span>
                    </div>
                    <div class="col col-sm-9">
                      <span style="font-size: 15px;">{{report.subject}}</span><br />
                      <span style="font-size: 12px;">{{report.x}}/{{report.y}} - {{report.actionDate| date:'dd.MM.yy HH:mm'}}</span>
                    </div>
                  </div>
                </li>
                <li class="list-group-item" *ngIf="reports.length === 0" i18n>MESSAGES_NO_REPORTS</li>
              </ul>
            </div>
          </div>
          <div class="col col-sm-9" style="padding: 16px 0 16px 0; border-left: solid lightgray 1px;">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link" [ngClass]="{'active': selectedTab == 0}" id="outer-tab" (click)="setTab(0)" type="button" role="tab" aria-controls="outer-tab-pane" aria-selected="true" i18n>REPORT</button>
              </li>
              <li class="nav-item" role="presentation" *ngIf="reportSelected != null && reportSelected.reportFrontEnd != null && reportSelected.reportFrontEnd.detailsCombat != null">
                <button class="nav-link" [ngClass]="{'active': selectedTab == 1}" id="inner-tab" (click)="setTab(1)" type="button" role="tab" aria-controls="inner-tab-pane" aria-selected="false" i18n>REPORT_DETAILS_COMBAT</button>
              </li>
              <!--<li class="nav-item" role="presentation" *ngIf="reportSelected && reportSelected.detailsCombat != null">
                <button class="nav-link" [ngClass]="{'active': selectedTab == 1}" id="inner-tab" (click)="setTab(1)" type="button" role="tab" aria-controls="inner-tab-pane" aria-selected="false" i18n>    M O R E    G O    H E R E    </button>
              </li>-->
            </ul>
            <div class="tab-content" id="myTabContent">
              <!--==== REPORT GENERAL ====-->
              <div class="tab-pane" [ngClass]="{'active show': selectedTab == 0}" id="report-tab-pane" role="tabpanel" aria-labelledby="outer-tab" tabindex="0">
                <div class="row" style="margin: 0 16px 0 0;" *ngIf="reportSelected">

                  <div class="row" style="margin: 0 0 0 12px; padding-bottom: 12px; padding-left: 0;">
                    <div class="col col-sm-10" style="text-align: center; padding-top: 4px; font-size: 24px;">
                      <span style="">{{reportSelected.subject}}</span>
                    </div>
                    <div class="col col-sm-2" style="padding-top: 6px;">
                      <button type="button" class="btn btn-secondary" (click)="deleteReport(reportSelected)"><span [innerHTML]="deleteIcon"></span></button>
                    </div>
                    <div class="col col-sm-10" style="text-align: center; padding-top: 4px; font-size: 24px; font-size: 16px;">
                      <span *ngIf="reportSelected != null && reportSelected.reportType == 2 && reportSelected.wasSuccess && reportSelected.isIncoming" i18n>REPORT_COMBAT_SUCC_INC</span>
                      <span *ngIf="reportSelected != null && reportSelected.reportType == 2 && reportSelected.wasSuccess && !reportSelected.isIncoming" i18n>REPORT_COMBAT_SUCC_OUT</span>
                      <span *ngIf="reportSelected != null && reportSelected.reportType == 2 && !reportSelected.wasSuccess && reportSelected.isIncoming" i18n>REPORT_COMBAT_FAIL_INC</span>
                      <span *ngIf="reportSelected != null && reportSelected.reportType == 2 && !reportSelected.wasSuccess && !reportSelected.isIncoming" i18n>REPORT_COMBAT_FAIL_OUT</span>
                    </div>
                  </div>

                  <div>
                    <div class="row">
                      <div class="col col-sm-6">{{getTranslation('', 'REPORT_TITLE_ACTIONDATE')}}:</div>
                      <div class="col col-sm-4">{{reportSelected.actionDate| date:'dd.MM.yy HH:mm'}}</div>
                    </div>
                  </div>
                  <div *ngIf="reportSelected != null && reportSelected.reportFrontEnd != null">
                    <div class="row" *ngFor="let infoLine of reportSelected.reportFrontEnd!.infoLines">
                      <div class="col col-sm-6"><span>{{infoLine.name}}</span><span *ngIf="infoLine.name != ''">:</span></div>
                      <div class="col col-sm-4">{{infoLine.value}}</div>
                    </div>
                  </div>
                  <div *ngIf="reportSelected != null && reportSelected.reportFrontEnd != null && reportSelected.reportFrontEnd.outcome != null ">
                    <div class="row">
                      <div class="col col-sm-12"><span i18n>RESOURCES</span></div>
                      <div class="col col-sm-1"><span [innerHTML]="getIconByName('woodIcon')"></span></div>
                      <div class="col col-sm-2">{{reportSelected.reportFrontEnd.outcome.W}}</div>
                      <div class="col col-sm-1"><span [innerHTML]="getIconByName('stoneIcon')"></span></div>
                      <div class="col col-sm-2">{{reportSelected.reportFrontEnd.outcome.S}}</div>
                      <div class="col col-sm-1"><span [innerHTML]="getIconByName('ironIcon')"></span></div>
                      <div class="col col-sm-2">{{reportSelected.reportFrontEnd.outcome.I}}</div>
                      <div class="col col-sm-1"><span [innerHTML]="getIconByName('foodIcon')"></span></div>
                      <div class="col col-sm-2">{{reportSelected.reportFrontEnd.outcome.F}}</div>
                    </div>
                    <!--LATER - VILLAGE-->
                    <!--LATER - BP-->
                  </div>

                </div>
                <div class="row" style="margin: 0 16px 0 0;"
                     *ngIf="reportSelected == null" i18n>NO_REPORT_SELECTED</div>
              </div>
              <!--==== DETAILS COMBAT ====-->
              <div class="tab-pane" *ngIf="reportSelected != null && reportSelected.reportFrontEnd != null && reportSelected.reportFrontEnd.detailsCombat != null"
                   [ngClass]="{'active show': selectedTab == 1}" id="details-tab-pane" role="tabpanel"
                   aria-labelledby="inner-tab" tabindex="1">
                <!--ATTACKER-->
                <div class="row" style="margin: 6px 6px 0 0;">
                  <div style="width: 55px; padding-top: 15px;">
                    <span [innerHTML]="getIconByName('twoSwords')" style="font-size: 24px;"></span>
                  </div>
                  <table style="width:0;">
                    <tr style="">
                      <!--ICONS-->
                      <td *ngFor="let unit of reportSelected.reportFrontEnd.detailsCombat.A"
                          (click)="selectUnitIn(unit.Id)"
                          style="border: solid black 1px; min-width: 36px; max-width: 36px; "
                          [ngStyle]="{'background': (unit.Id == selectedCombatUnit) ? '#c1daff' : '' }"
                          title="{{unit.toolTip}}">
                        <span [innerHTML]="getIconById(unit.Icon)" style="font-size: 16px; padding-left: 6px; "></span>
                      </td>
                    </tr>
                    <tr>
                      <!--START AMOUNTS-->
                      <td *ngFor="let unit of reportSelected.reportFrontEnd.detailsCombat.A"
                          (click)="selectUnitIn(unit.Id)"
                          style="border: solid black 1px; padding: 0; font-size: 12px;"
                          [ngStyle]="{'background': (unit.Id == selectedCombatUnit) ? '#c1daff' : '' }"
                          title="{{unit.toolTip}}">
                        <span style="padding-left: 1px;">{{unit.startAmount}}</span>
                      </td>
                    </tr>
                    <tr>
                      <!--TURN AMOUNTS-->
                      <td *ngFor="let unit of reportSelected.reportFrontEnd.detailsCombat.A"
                          (click)="selectUnitIn(unit.Id)"
                          style="border: solid black 1px; padding: 0; font-size: 12px;"
                          [ngStyle]="{'background': (unit.Id == selectedCombatUnit) ? '#c1daff' : '' }"
                          title="{{unit.toolTip}}">
                        <span style="padding-left: 1px;">{{unit.turnAmount}}</span>
                      </td>
                    </tr>
                  </table>
                </div>

                <!--DEFENDER-->
                <div class="row" style="margin: 6px 6px 0 0;">
                  <div style="width: 55px; padding-top: 15px;">
                    <span [innerHTML]="getIconByName('shield')" style="font-size: 24px;"></span>
                  </div>
                  <table style="width: 0;">
                    <tr>
                      <!--ICONS-->
                      <td *ngFor="let unit of reportSelected.reportFrontEnd.detailsCombat.D"
                          (click)="selectUnitIn(unit.Id)"
                          style="border: solid black 1px; min-width: 36px; max-width: 36px; "
                          [ngStyle]="{'background': (unit.Id == selectedCombatUnit) ? '#c1daff' : '' }"
                          title="{{unit.toolTip}}">
                        <span [innerHTML]="getIconById(unit.Icon)" style="font-size: 16px; padding-left: 6px;"></span>
                      </td>
                    </tr>
                    <tr>
                      <!--START AMOUNTS-->
                      <td *ngFor="let unit of reportSelected.reportFrontEnd.detailsCombat.D"
                          (click)="selectUnitIn(unit.Id)"
                          style="border: solid black 1px; padding: 0; font-size: 12px;"
                          [ngStyle]="{'background': (unit.Id == selectedCombatUnit) ? '#c1daff' : '' }"
                          title="{{unit.toolTip}}">
                        <span style="padding-left: 1px;">{{unit.startAmount}}</span>
                      </td>
                    </tr>
                    <tr>
                      <!--TURN AMOUNTS-->
                      <td *ngFor="let unit of reportSelected.reportFrontEnd.detailsCombat.D"
                          (click)="selectUnitIn(unit.Id)"
                          style="border: solid black 1px; padding: 0; font-size: 12px;"
                          [ngStyle]="{'background': (unit.Id == selectedCombatUnit) ? '#c1daff' : '' }"
                          title="{{unit.toolTip}}">
                        <span style="padding-left: 1px;">{{unit.turnAmount}}</span>
                      </td>
                    </tr>
                  </table>

                </div>

                <!--MAP-->
                <div class="row" style="margin: 6px 6px 0 0;">

                  <div style="width: 700px; float: left;">
                    <table style="">
                      <tr *ngFor="let line of battleField; let j = index" style="height: 25px;">
                        <td *ngFor="let field of line"
                            [ngStyle]="{'background': (field == selectedCombatUnit) ? '#c1daff' : '' }"
                            [ngClass]=""
                            style="border: solid black 1px; min-width: 24px; padding: 0 0 0 1px; }"
                            (click)="selectUnitIn(field)">
                          <span [innerHTML]="getIconByUnitId(field)" style="font-size: 16px;"></span>

                          <div *ngIf="getBattleFieldActions(field,'right')" style="position: relative;">
                            <!--fight indicator right-->
                            <span [innerHTML]="getIconByName('twoSwords')" style="font-size: 10px;position: absolute; top: -20px;  left: 15px;"></span>
                          </div>
                          <div *ngIf="getBattleFieldActions(field,'bottom')" style="position: relative;">
                            <!--fight indicator bottom-->
                            <span [innerHTML]="getIconByName('twoSwords')" style="font-size: 10px;position: absolute; top: -8px;  left: 4px;"></span>
                          </div>
                          <div *ngIf="getBattleFieldActions(field,'ranged')" style="position: relative;">
                            <!--fight indicator ranged-->
                            <span [innerHTML]="getIconByName('bow')" style="font-size: 10px;position: absolute; top: -8px;  left: 4px;"></span>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>

                </div>

                <!--PAGING-->
                <div class="row" style="margin: 6px 6px 0 0;">
                  <div class="col col-sm-1">
                    &nbsp;<!--spacer-->
                  </div>
                  <div class="col col-sm-2">
                    <button type="button" class="btn btn-secondary"
                            style="margin-left: 26px; width: 26px; height: 26px;"
                            [disabled]="!selectedCombatTurn || selectedCombatTurn == null || selectedCombatTurn.I == 0"
                            (click)="goToTurn(0)">
                      <div [innerHTML]="getIconByName('first')" style="font-size: 26px; margin: -20px;"></div>
                    </button>
                  </div>
                  <div class="col col-sm-2">
                    <button type="button" class="btn btn-secondary"
                            style="margin-left: 26px; width: 26px; height: 26px;"
                            [disabled]="!selectedCombatTurn || selectedCombatTurn == null || selectedCombatTurn.I == 0"
                            (click)="goToTurn(selectedCombatTurn!.I-1)">
                      <div [innerHTML]="getIconByName('previous')" style="font-size: 26px; margin: -20px;"></div>
                    </button>
                  </div>

                  <div class="col col-sm-2" style="text-align: center;"
                        *ngIf="selectedCombatTurn != null ">
                    {{selectedCombatTurn!.I}}
                  </div>

                  <div class="col col-sm-2">
                    <button type="button" class="btn btn-secondary"
                            style="margin-left: 26px; width: 26px; height: 26px;"
                            [disabled]="!selectedCombatTurn || selectedCombatTurn == null || selectedCombatTurn.I ==reportSelected.reportFrontEnd.detailsCombat.T.length-1"
                            (click)="goToTurn(selectedCombatTurn!.I+1)">
                      <div [innerHTML]="getIconByName('next')" style="font-size: 26px; margin: -20px;"></div>
                    </button>
                  </div>
                  <div class="col col-sm-2">
                    <button type="button" class="btn btn-secondary"
                            style="margin-left: 26px; width: 26px; height: 26px;"
                            [disabled]="!selectedCombatTurn || selectedCombatTurn == null || selectedCombatTurn.I ==reportSelected.reportFrontEnd.detailsCombat.T.length-1"
                            (click)="goToTurn(reportSelected.reportFrontEnd.detailsCombat.T.length-1)">
                      <div [innerHTML]="getIconByName('last')" style="font-size: 26px; margin: -20px;"></div>
                    </button>
                  </div>
                </div>
              </div>

              <!--<div class="tab-pane" [ngClass]="{'active show': selectedTab == 1}" id="details-tab-pane" role="tabpanel" aria-labelledby="inner-tab" tabindex="1">
                <div class="row" style="margin: 0 16px 0 0;" *ngIf="reportSelected">
                  M O R E    G O    H E R E
                </div>
                <div class="row" style="margin: 0 16px 0 0;"
                     *ngIf="reportSelected == null" i18n>NO_REPORT_SELECTED</div>
              </div>-->
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeModal()" i18n >COMMON_CLOSE</button>
      </div>
    </div>
  </div>
</div>

