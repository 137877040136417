<!--<p>main works!</p>

Landing page<br />
some info about the game<br />
news<br />
events<br />
trailer-->


<h3 i18n>MAIN_NEWS</h3>

<div class="row" style="padding: 5px">
  <div class="col-sm-6" style="padding-bottom:12px;">
    <div class="card border-info" style="padding: 0;">
      <div class="card-header">INFO 23.06.2024 </div>
      <div class="card-body text-info">
        <h5 class="card-title">Test version has been updated.</h5>
        <ul class="list-group">
          <!--<li class="list-group-item" style="color:darkred; font-weight: bold">Database has been reseted</li>-->
          <!--<li class="list-group-item">NEW: ...</li>-->
          <li class="list-group-item">Improved: Report contains other involved player</li>
          <li class="list-group-item">FIX: Unlimited sending of raids</li>
          <li class="list-group-item">FIX: resources after switching village</li>
          <li class="list-group-item">FIX: Design availability in new villages</li>
          <li class="list-group-item">FIX: Busy units cant join defence</li>
          <li class="list-group-item">FIX: Science food production</li>
          <li class="list-group-item">FIX: Rectruiting batch size</li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!--TEMPLATE-->
<!--<div class="col-sm-4" style="padding-bottom:12px;">
  <div class="card border-info" style="padding: 0;">
    <div class="card-header">INFO dd.mm.2024 </div>
    <div class="card-body text-info">
      <h5 class="card-title">Test version has been updated.</h5>
      <ul class="list-group">

      </ul>
    </div>
  </div>
</div>-->
<div class="col-sm-4" style="padding-bottom:12px;">
  <div class="card border-info" style="padding: 0;">
    <div class="card-header">INFO 16.05.2024 </div>
    <div class="card-body text-info">
      <h5 class="card-title">Test version has been updated.</h5>
      <ul class="list-group">
        <li class="list-group-item" style="color:darkred; font-weight: bold">Database has been reseted</li>
        <li class="list-group-item">NEW: MAP</li>
        <li class="list-group-item">NEW: COMBAT (Raids)</li>
        <li class="list-group-item">NEW: SETTLING</li>
        <li class="list-group-item">NEW: REPORTS</li>
        <li class="list-group-item">FIX: Design Stats</li>
        <li class="list-group-item">FIX: Combining Blueprints</li>
        <li class="list-group-item">FIX: Startposition</li>
      </ul>
    </div>
  </div>
</div>
<div class="row" style="padding: 5px">
  <div class="col-sm-4" style="padding-bottom:12px;">
    <div class="card border-info" style="padding: 0;">
      <div class="card-header">INFO 05.02.2024 </div>
      <div class="card-body text-info">
        <h5 class="card-title">Test version has been updated.</h5>
        <ul class="list-group">
          <li class="list-group-item" style="color:darkred; font-weight: bold">Database has been reseted</li>
          <li class="list-group-item">NEW: Troop Design</li>
          <li class="list-group-item">NEW: Barracks</li>
          <li class="list-group-item">NEW: Quaters</li>
          <li class="list-group-item">Improved: more Blueprints</li>
          <li class="list-group-item">Improved: Inventory Part numbers</li>
          <li class="list-group-item">Improved: Inventory sorted</li>
          <li class="list-group-item">Improved: spinner</li>
          <li class="list-group-item">Improved: basic science and advances science got an effect</li>
          <li class="list-group-item">FIX: missing resource load after login</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="col-sm-4" style="padding-bottom:12px;">
    <div class="card border-info" style="padding: 0;">
      <div class="card-header">INFO 11.11.2023 </div>
      <div class="card-body text-info">
        <h5 class="card-title">Test version has been updated.</h5>
        <ul class="list-group">
          <li class="list-group-item" style="color:darkred; font-weight: bold">Database has been reseted</li>
          <li class="list-group-item">NEW: Daily Login Rewards</li>
          <li class="list-group-item">NEW: Inventory</li>
          <li class="list-group-item">Improved: Event Handling (reloads)</li>
          <li class="list-group-item">FIX: 2nd Storage/Granery</li>
          <li class="list-group-item">FIX: Display fully upgraded Buildings</li>
          <li class="list-group-item">FIX: Field Boni</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="col-sm-4" style="padding-bottom:12px;">
    <div class="card border-info" style="padding: 0;">
      <div class="card-header">INFO 01.11.2023 </div>
      <div class="card-body text-info">
        <h5 class="card-title">Test version has been updated.</h5>
        <ul class="list-group">
          <li class="list-group-item" style="color:darkred; font-weight: bold">Database has been reseted</li>
          <li class="list-group-item">NEW: Village Levels</li>
          <li class="list-group-item">FIX: Workshop enabeling Tech</li>
          <li class="list-group-item">FIX: Blocking buildings again</li>
          <li class="list-group-item">FIX: Mail Count</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="col-sm-4" style="padding-bottom:12px;">
    <div class="card border-info" style="padding: 0;">
      <div class="card-header">INFO 28.10.2023 </div>
      <div class="card-body text-info">
        <h5 class="card-title">Test version has been updated.</h5>
        <ul class="list-group">
          <li class="list-group-item" style="color:darkred; font-weight: bold">Database has been reseted</li>
          <li class="list-group-item">NEW: Messages</li>
          <li class="list-group-item">FIX: Blocked Buildings</li>
          <li class="list-group-item">FIX: Delete old Static Data</li>
          <li class="list-group-item">FIX: Resource difference due to server-timezone</li>
          <li class="list-group-item">FIX: Research/Building Level Changes</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="col-sm-4" style="padding-bottom:12px;">
    <div class="card border-info" style="padding: 0;">
      <div class="card-header">INFO 26.10.2023 </div>
      <div class="card-body text-info">
        <h5 class="card-title">Test version has been updated.</h5>
        <ul class="list-group">
          <li class="list-group-item" style="color:darkred; font-weight: bold">Database has been reseted</li>
          <li class="list-group-item">NEW: TECH</li>
          <li class="list-group-item">FIX: Mainbuilding Lvl 4</li>
          <li class="list-group-item">FIX: Research count</li>
          <li class="list-group-item">FIX: Starting Resources</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="col-sm-4" style="padding-bottom:12px;">
    <div class="card border-info" style="padding: 0;">
      <div class="card-header">INFO 30.09.2023 </div>
      <div class="card-body text-info">
        <h5 class="card-title">Test version has been updated.</h5>
        <ul class="list-group">
          <li class="list-group-item" style="color:darkred; font-weight: bold">Database has been reseted</li>
          <li class="list-group-item">NEW: SCIENCE</li>
          <li class="list-group-item">NEW: News on landing page</li>
          <li class="list-group-item">NEW: auto-refresh on field claim</li>
          <li class="list-group-item">NEW: auto-load of building events in event list</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="col-sm-4" style="padding-bottom:12px;">
    <div class="card border-info" style="padding: 0;">
      <div class="card-header">INFO 02.09.2023 </div>
      <div class="card-body text-info">
        <h5 class="card-title">Test version has been updated.</h5>
        <ul class="list-group">
          <li class="list-group-item" style="color:darkred; font-weight: bold">Database has been reseted</li>
          <li class="list-group-item">NEW: CONSTRUCTION</li>
          <li class="list-group-item">NEW: CLAIMING FIELDS</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="col-sm-4" style="padding-bottom:12px;">
    <div class="card border-danger" style="padding: 0;">
      <div class="card-header">12.08.2023</div>
      <div class="card-body text-danger">
        <h5 class="card-title">Test version release.</h5>
        <ul class="list-group">
          <li class="list-group-item" style="color:darkred; font-weight: bold">Database has been reseted</li>
          <li class="list-group-item">NEW: REGISTRATION</li>
          <li class="list-group-item">NEW: Server signup</li>
          <li class="list-group-item">NEW: Starting village</li>
        </ul>
      </div>
    </div>
  </div>
</div>
