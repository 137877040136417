import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Design, DesignMinimal, DesignStaticData, PlayerItem } from '../../models/item-models';
import { IdValue } from '../../models/pair-models';

@Injectable({
  providedIn: 'root'
})
export class ItemService {

  baseApiUrl: string = environment.API_URL;
  constructor(private http: HttpClient) { }

  //========== INVENTORY ==========

  public getPlayerItems(): Observable<PlayerItem[]> {
    return this.http.get<PlayerItem[]>(this.baseApiUrl + 'Item/getPlayerItems');
  }

  public openBox(playerItemId: number, amount: number): Observable<PlayerItem[]> {
    var request = { playerItemId: playerItemId, amount: amount, villageId: -1 };
    return this.http.post<PlayerItem[]>(this.baseApiUrl + 'Item/openBox', request);
  }

  public useResourcePacks(playerItemId: number, amount: number, villageId: number): Observable<PlayerItem[]> {
    var request = { playerItemId: playerItemId, amount: amount, villageId: villageId };
    return this.http.post<PlayerItem[]>(this.baseApiUrl + 'Item/useResourcePacks', request);
  }

  public combineParts(playerItemId: number, villageId: number): Observable<PlayerItem[]> {
    var request = { playerItemId: playerItemId, amount: -1, villageId: villageId };
    return this.http.post<PlayerItem[]>(this.baseApiUrl + 'Item/combineParts', request);
  }

  public sellItems(playerItemId: number, amount: number, villageId: number): Observable<PlayerItem[]> {
    var request = { playerItemId: playerItemId, amount: amount, villageId: villageId };
    return this.http.post<PlayerItem[]>(this.baseApiUrl + 'Item/sellItems?', request);
  }

  //========== BLUEPRINTS ==========
  //Blueprint
  public getBlueprintsData(byNation: boolean): Observable<DesignStaticData> {
    return this.http.get<DesignStaticData>(this.baseApiUrl + 'Item/getBlueprintsData?byNation=' + byNation);
  }

  public getPlayerBlueprints(villageId: number | null): Observable<IdValue[]> {
    return this.http.get<IdValue[]>(this.baseApiUrl + 'Item/getPlayerBlueprints?villageId=' + villageId);
  }

  //upgrade blueprint
  //move blueprint

  //========== DESIGNS ==========
  public getDesigns(): Observable<DesignMinimal[]> {
    return this.http.get<DesignMinimal[]>(this.baseApiUrl + 'Item/getDesigns');
  }

  public getDesign(designId: number): Observable<Design> {
    return this.http.get<Design>(this.baseApiUrl + 'Item/getDesign?designId=' + designId);
  }

  //saveDesign - design
  public saveDesign(design: Design): Observable<number> {
    return this.http.post<number>(this.baseApiUrl + 'Item/saveDesign', design);
  }
  //deleteDesign - designId
}
