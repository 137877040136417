import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { User } from '../../models/single/user';
import { LoginResponce } from '../../models/single/loginResponce';
import { RefreshRequest } from '../../models/single/refreshRequest';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  baseApiUrl: string = environment.API_URL;
  constructor(private http: HttpClient) { }

  public register(user: User): Observable<any> {
    return this.http.post<any>(this.baseApiUrl + 'Auth/register', user);
  }

  public login(user: User): Observable<LoginResponce> {
    return this.http.post<LoginResponce>(this.baseApiUrl + 'Auth/login', user);
  }

  public refresh(refreshToken: string): Observable<LoginResponce> {
    const refreshRequest: RefreshRequest = { refreshToken: refreshToken};
    return this.http.post<LoginResponce>(this.baseApiUrl + 'Auth/refresh', refreshRequest);
  }

  public logout(): Observable<string> {
    return this.http.get(this.baseApiUrl + 'Auth/logout',
      { responseType: 'text' });
  }

  public resetPassword(user: User): Observable<string> {
    return this.http.post(this.baseApiUrl + 'Auth/resetPassword', user, { responseType: 'text' } );
  }

  public activationMail(user: User): Observable<string> {
    return this.http.post(this.baseApiUrl + 'Auth/activationMail', user, { responseType: 'text' });
  }

  public getMe(): Observable<string> {
    return this.http.get(this.baseApiUrl + 'Auth',
      { responseType: 'text' });//because no JSON object is returned... just a string
  }
}
