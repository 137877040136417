<div class="col-sm-12"
     *ngFor="let techRank of techStructure"
     style="padding: 6px;">
  <div class="card border-text-dark" style="padding: 0;">
    <div class="card-header"
         (click)="openRank(techRank)"
         [ngStyle]="{'color': techRank.isActive ? 'black' : 'red', 'font-weight': techRank.isFull ? 'bold':''}">
      <Span i18n>TECH_RANK</Span><Span>: {{techRank.rank}} ({{techRank.ownedTechs}}/{{villageTechResponce!=null?villageTechResponce.techsPerRank:0}})</Span>
    </div>
    <div class="card-body text-text-dark" *ngIf="techRank.isOpen">
      <div class="row">
        <div style="width:33%" *ngFor="let tech of techRank.techs">
          <div class="card border-text-dark" style="padding: 0;"
               [ngStyle]="{'border': tech.owned ? 'solid black 2px':''}">
            <div class="card-header"
                 style="font-size: 14px;"
                 [ngStyle]="{'font-weight': tech.owned ? 'bold':''}">{{getTranslation(tech.tech!.name!, 'name')}}</div>
            <div class="card-body text-text-dark" style="font-size: 14px;">
              <Span>{{getTranslation(tech.tech!.name!, 'description')}}</Span>
              <ul class="list-group" *ngIf="tech.tech!.effectData.length > 0">
                <li *ngFor="let effect of tech.tech!.effectData" class="list-group-item d-flex justify-content-between align-items-center" style="padding: 3px 6px;">
                  {{getTranslation(effect.name, 'effectName')}}
                  <span class="badge bg-primary rounded-pill">{{effect.value}}</span>
                </li>
              </ul>


              <div class="row">
                <div class=" col col-sm-3">
                  <span [innerHTML]="woodIcon"></span><span [ngStyle]="{'color': resData != null && tech.tech!.costWood > resData.wood ? 'red' : 'black' }">{{tech.tech!.costWood}}</span>
                </div>
                <div class=" col col-sm-3">
                  <span [innerHTML]="stoneIcon"></span><span [ngStyle]="{'color': resData != null && tech.tech!.costStone > resData.stone ? 'red' : 'black' }">{{tech.tech!.costStone}}</span>
                </div>
                <div class=" col col-sm-3">
                  <span [innerHTML]="ironIcon"></span><span [ngStyle]="{'color': resData != null && tech.tech!.costIron > resData.iron ? 'red' : 'black' }">{{tech.tech!.costIron}}</span>
                </div>
                <div class=" col col-sm-3">
                  <span [innerHTML]="foodIcon"></span><span [ngStyle]="{'color': resData != null && tech.tech!.costFood > resData.food ? 'red' : 'black' }">{{tech.tech!.costFood}}</span>
                </div>
              </div>
              <div class="row"
                   *ngIf="!tech.owned && !techRank.isFull">
                <div class=" col col-sm-3" style="padding-top: 12px;">
                  &nbsp;
                </div>
                <div class="col-sm-9" style="padding: 8px 40px 0 12px;">
                  <button class="btn btn-success"
                          style="width: 100%"
                          data-bs-dismiss="modal"
                          (click)="buyTech(tech.tech!.id)"
                          [disabled]="!techRank.isActive || techRank.isFull || resData == null || tech.tech!.costWood > resData.wood || tech.tech!.costStone > resData.stone|| tech.tech!.costIron > resData.iron || tech.tech!.costFood > resData.food" i18n>
                    BUY_TECH
                  </button>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
