<ul class="nav nav-tabs" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <button class="nav-link" [ngClass]="{'active': selectedTab == 'Recruit'}" type="button" (click)="changeTab('Recruit')" i18n>
      BARRACK_RECRUITING
    </button>
  </li>
  <li class="nav-item" role="presentation">
    <button class="nav-link" [ngClass]="{'active': selectedTab == 'Army'}" type="button" (click)="changeTab('Army')" i18n>
      BARRACK_ARMY
    </button>
  </li>
  <li class="nav-item" role="presentation">
    <button class="nav-link" [ngClass]="{'active': selectedTab == 'Send'}" type="button" (click)="changeTab('Send')" i18n>
      BARRACK_SEND
    </button>
  </li>
</ul>

<div class="tab-content" id="myTabContent" style="border: 1px solid black">
  <div class="tab-pane fade show active" *ngIf="selectedTab == 'Recruit'">
    <div class="row" style="margin: 0;">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-8" style="padding:10px 0px 10px 40px">
            <span i18n>BARRACKS_TROOP_LIMIT</span><span>: {{troopLimit}}</span><br />
            <span i18n>BARRACKS_TROOP_PRESENT</span><span>: {{troopsPresent}}</span><br />
            <span i18n>BARRACKS_TROOP_RECRUITING</span><span>: {{troopsRecruiting}}</span><br />
            <span i18n>BARRACKS_TROOP_BATCHSIZE</span><span>: {{maxBatchSize}}</span><br />
            <span i18n>BARRACKS_TROOP_LIMIT_FREE</span><span>: {{troopLimit - (troopsPresent + troopsRecruiting)}}</span>
          </div>
          <div class="col-sm-4">
            <div class="form-check form-switch" style="padding: 24px 0px 14px 0px;">
              <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" [(ngModel)]="showAllDesigns" style="border-color: black;">
              <label class="form-check-label" for="flexSwitchCheckDefault" i18n>SHOW_ALL_DESIGNS</label>
            </div>
          </div>
        </div>
      </div>
      <!--TODO 2nd Tab for Amry-management-->
      <div class="col-sm-12"
           *ngFor="let design of designs"
           style="padding: 6px;">
        <div class="card border-text-dark" style="padding: 0;" *ngIf="design.isAvailable || showAllDesigns">
          <div class="card-header"
               [ngStyle]="{'color': design.isAvailable ? 'black' : 'red'}">
            <Span>{{design.name}} ({{design.troopsPresent}}</Span>
            <Span *ngIf="design.troopsRecruiting != null && design.troopsRecruiting>0"> + {{design.troopsRecruiting}}</Span>
            <span>)</span>
          </div>
          <div class="card-body text-text-dark">
            <div class="row">
              <div class="col-sm-2" style="text-align: center;">
                <span [innerHTML]="getIconById(design.icon)" style="font-size: 46px;"></span>
              </div>
              <div class="col-sm-10">
                <div class="row">
                  <div class=" col col-sm-3">
                    <span [innerHTML]="getIconByName('woodIcon')"></span><span [ngStyle]="{'color': resData != null && design.costWood * design.amount > resData.wood ? 'red' : 'black' }">{{design.costWood * design.amount}}</span>
                  </div>
                  <div class=" col col-sm-3">
                    <span [innerHTML]="getIconByName('stoneIcon')"></span><span [ngStyle]="{'color': resData != null && design.costStone * design.amount > resData.stone ? 'red' : 'black' }">{{design.costStone * design.amount}}</span>
                  </div>
                  <div class=" col col-sm-3">
                    <span [innerHTML]="getIconByName('ironIcon')"></span><span [ngStyle]="{'color': resData != null && design.costIron * design.amount > resData.iron ? 'red' : 'black' }">{{design.costIron * design.amount}}</span>
                  </div>
                  <div class=" col col-sm-3">
                    <span [innerHTML]="getIconByName('foodIcon')"></span><span [ngStyle]="{'color': resData != null && design.costFood * design.amount > resData.food ? 'red' : 'black' }">{{design.costFood * design.amount}}</span>
                  </div>
                </div>
                <div class="row" *ngIf="design.isAvailable">
                  <div class="col col-sm-3" style="padding-top: 12px;">
                    <span [innerHTML]="getIconByName('sizeIcon')"></span><span [ngStyle]="{'color': (design.size * design.amount) > (troopLimit - (troopsPresent + troopsRecruiting)) ? 'red' : 'black' }">{{design.size * design.amount}}</span>
                  </div>
                  <div class="col col-sm-3" style="padding-top: 12px;">
                    <span [innerHTML]="getIconByName('clockIcon')"></span><span>{{design.recruitingTicks * design.amount}}</span>
                  </div>
                  <div class="col col-sm-3" style="padding-top: 12px;">
                    <!--<span i18n>UNIT_AMOUNT</span><br />-->
                    <input [(ngModel)]="design.amount" type="number" id="amount" name="amount" min="1" max="{{maxBatchSize}}"
                           (ngModelChange)="AmountChanged(design)"
                           [disabled]="!design.isAvailable"
                           style="width: 90%;" />
                  </div>
                  <div class="col-sm-3" style="padding: 8px 40px 0 12px;">
                    <button class="btn btn-success"
                            style="width: 100%"
                            data-bs-dismiss="modal"
                            (click)="recruit(design)"
                            [disabled]="!design.isAvailable" i18n>
                      RECRUIT
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="tab-pane fade show active" *ngIf="selectedTab == 'Army'">
    <div class="row" style="margin: 0;">

      ARMY BUILDING COMING SOON

    </div>
  </div>
  <div class="tab-pane fade show active" *ngIf="selectedTab == 'Send'">
    <div class="row" style="margin: 0;">

      <div class="col-sm-12">
        <div class="card" style="margin: 4px 0 2px 0;">
          <div class="card-header" style="padding: 2px 0 2px 12px;"
               (click)="stepCoordinatesCollapsed = !stepCoordinatesCollapsed">
            <span [innerHTML]="getIconByName('collapsed')"
                  *ngIf="stepCoordinatesCollapsed == true" style=""></span>
            <span [innerHTML]="getIconByName('expanded')"
                  *ngIf="stepCoordinatesCollapsed == false" style=""></span>
            <span i18n>BARRACK_SEND_POSITION</span>
          </div>
          <div class="card-body" *ngIf="stepCoordinatesCollapsed == false">
            <div class="row">
              <div class="col-sm-3">
                <span>X</span>
                <input [(ngModel)]="xInput" style="width: 50%; padding: 0; margin-left: 4px;" />
              </div>
              <div class="col-sm-3">
                <span>Y</span>
                <input [(ngModel)]="yInput" style="width: 50%; padding: 0; margin-left: 4px;" />
              </div>
              <div class="col-sm-6" style="text-align:right">
                <button class="btn btn-secondary"
                        (click)="setCoordinates()"
                        style="margin-top: -4px; height: 32px; padding: 0 8px 0 8px;" i18n>
                  BARRACKS_SEND_SETCOORDINATES
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-12" *ngIf="stepTypeActive == true">
        <div class="card" style="margin: 4px 0 2px 0;">
          <div class="card-header" style="padding: 2px 0 2px 12px;"
               (click)="stepTypeCollapsed = !stepTypeCollapsed">
            <span [innerHTML]="getIconByName('collapsed')"
                  *ngIf="stepTypeCollapsed == true" style=""></span>
            <span [innerHTML]="getIconByName('expanded')"
                  *ngIf="stepTypeCollapsed == false" style=""></span>            
            <span i18n>BARRACK_SEND_TYPE</span>
          </div>
          <div class="card-body" *ngIf="stepTypeCollapsed == false">
            <div class="row">
              <div class="col col-sm-6">
                <span i18n>MAP_FIELDTYPE</span>
                <span>: {{getTranslation('fieldType', mapField!.fieldTypeName!)  }}</span>
              </div>
              <div class="col col-sm-6">
                <span i18n>COMMON_VILLAGENAME</span>
                <span>: {{mapField!.villageName==null ? '-': mapField!.villageName}}</span>
              </div>
              <div class="col col-sm-6">
                <span i18n>COMMON_PLAYERNAME</span>
                <span>: {{mapField!.playerName==null ? '-':mapField!.playerName}}</span>
              </div>
              <div class="col col-sm-6">
                <span i18n>COMMON_ALLIANCENAME</span>
                <span>: {{mapField!.allianceName==null ? '-':mapField!.allianceName}}</span>
              </div>
            </div>

            <!--==== PRIMARY TYPE ====-->
            <div class="row">
              <div class="col col-sm-3" style="text-align: center;">
                <button type="button" class="col-sm-2 btn btn-secondary" [disabled]="!actionButtonAttackActive"
                        style="width: 50px; height: 50px; "
                        [ngStyle]="{'border': (actionType == 'attack') ? 'solid 2px red' : '' }"
                        (click)="setTye('attack')">
                  <div [innerHTML]="getIconByName('attackIcon')" style="font-size: 38px; margin: -30px; }"></div>
                </button>
              </div>
              <div class="col col-sm-3" style="text-align: center;">
                <button type="button" class="col-sm-2 btn btn-secondary" [disabled]="!actionButtonPVEActive"
                        style="width: 50px; height: 50px; "
                        [ngStyle]="{'border': (actionType == 'pve') ? 'solid 2px red' : '' }"
                        (click)="setTye('pve')">
                  <div [innerHTML]="getIconByName('pveIcon')" style="font-size: 38px; margin: -30px; }"></div>
                </button>
              </div>
              <div class="col col-sm-3" style="text-align: center;">
                <button type="button" class="col-sm-2 btn btn-secondary" [disabled]="!actionButtonSpecialActive"
                        style="width: 50px; height: 50px; "
                        [ngStyle]="{'border': (actionType == 'special') ? 'solid 2px red' : '' }"
                        (click)="setTye('special')">
                  <div [innerHTML]="getIconByName('specialIcon')" style="font-size: 38px; margin: -30px; }"></div>
                </button>
              </div>
              <div class="col col-sm-3" style="text-align: center;">
                <button type="button" class="col-sm-2 btn btn-secondary" [disabled]="!actionButtonDefendActive"
                        style="width: 50px; height: 50px; "
                        [ngStyle]="{'border': (actionType == 'defend') ? 'solid 2px red' : '' }"
                        (click)="setTye('defend')">
                  <div [innerHTML]="getIconByName('defendIcon')" style="font-size: 38px; margin: -30px; }"></div>
                </button>
              </div>
            </div>


            <!--==== ATTACK ====-->
            <div class="row"
                 style="margin-top: 20px;"
                 *ngIf="actionType == 'attack'">
              <!--attack: destroy buildings? steel bpo? conquer?-->
              <div class="col col-sm-3" style="text-align: center;">
                <button type="button" class="col-sm-2 btn btn-secondary" [disabled]="!actionButtonAttackArmyActive"
                        style="width: 50px; height: 50px; "
                        [ngStyle]="{'border': (actionSubtype == 'army') ? 'solid 2px red' : '' }"
                        (click)="setSubType('army')">
                  <div [innerHTML]="getIconByName('attackArmyIcon')" style="font-size: 40px; margin: -32px; }"></div>
                </button>
              </div>
              <div class="col col-sm-3" style="text-align: center;">
                <button type="button" class="col-sm-2 btn btn-secondary" [disabled]="!actionButtonAttackRaidActive"
                        style="width: 50px; height: 50px; "
                        [ngStyle]="{'border': (actionSubtype == 'raid') ? 'solid 2px red' : '' }"
                        (click)="setSubType('raid')">
                  <div [innerHTML]="getIconByName('attackRaidIcon')" style="font-size: 40px; margin: -32px; }"></div>
                </button>
              </div>
              <div class="col col-sm-3" style="text-align: center;">
                <button type="button" class="col-sm-2 btn btn-secondary" [disabled]="!actionButtonAttackSiegeActive"
                        style="width: 50px; height: 50px; "
                        [ngStyle]="{'border': (actionSubtype == 'siege') ? 'solid 2px red' : '' }"
                        (click)="setSubType('siege')">
                  <div [innerHTML]="getIconByName('attackSiegeIcon')" style="font-size: 40px; margin: -32px; }"></div>
                </button>
              </div>
            </div>

            <!--==== PVE ====-->
            <div class="row"
                 style="margin-top: 20px;"
                 *ngIf="actionType == 'pve'">
              SECONDARY BUTTONS - pve???
              <!--settings??? durration? risk? ...???-->
            </div>

            <!--==== SPECIAL ====-->
            <div class="row"
                 style="margin-top: 20px;"
                 *ngIf="actionType == 'special'">
              <div class="col col-sm-3" style="text-align: center;">
                <button type="button" class="col-sm-2 btn btn-secondary" [disabled]="!actionButtonSpecialSettleActive"
                        style="width: 50px; height: 50px; "
                        [ngStyle]="{'border': (actionSubtype == 'settle') ? 'solid 2px red' : '' }"
                        (click)="setSubType('settle')">
                  <div [innerHTML]="getIconByName('settleIcon')" style="font-size: 40px; margin: -32px; }"></div>
                </button>
              </div>
              <div class="col col-sm-3" style="text-align: center;">
                <button type="button" class="col-sm-2 btn btn-secondary" [disabled]="!actionButtonSpecialTerraFromActive"
                        style="width: 50px; height: 50px; "
                        [ngStyle]="{'border': (actionSubtype == 'terraForm') ? 'solid 2px red' : '' }"
                        (click)="setSubType('terraForm')">
                  <div [innerHTML]="getIconByName('terraFormIcon')" style="font-size: 40px; margin: -32px; }"></div>
                </button>
              </div>
              <div class="col col-sm-3" style="text-align: center;">
                <button type="button" class="col-sm-2 btn btn-secondary" [disabled]="!actionButtonSpecialScoutActive"
                        style="width: 50px; height: 50px; "
                        [ngStyle]="{'border': (actionSubtype == 'scout') ? 'solid 2px red' : '' }"
                        (click)="setSubType('scout')">
                  <div [innerHTML]="getIconByName('scoutIcon')" style="font-size: 40px; margin: -32px; }"></div>
                </button>
              </div>
            </div>

            <!--==== DEFEND ====-->
            <div class="row"
                 style="margin-top: 20px;"
                 *ngIf="actionType == 'defend'">
              SECONDARY BUTTONS - defend???
              <!--setting??? stay durration?...vust town or entirety???-->
            </div>

          </div>
        </div>
      </div>

      <div class="col-sm-12" *ngIf="stepTroopsActive == true">
        <div class="card" style="margin: 4px 0 2px 0;">
          <div class="card-header" style="padding: 2px 0 2px 12px;"
               (click)="stepTroopsCollapsed = !stepTroopsCollapsed">
            <span [innerHTML]="getIconByName('collapsed')"
                  *ngIf="stepTroopsCollapsed == true" style=""></span>
            <span [innerHTML]="getIconByName('expanded')"
                  *ngIf="stepTroopsCollapsed == false" style=""></span>            
            <span i18n>BARRACK_SEND_TROOPS</span>
          </div>
          <div class="card-body" *ngIf="stepTroopsCollapsed == false">
            <div *ngIf="isArmySelection == false">
              <div class="row"
                   *ngFor="let units of selectableUnits"
                   style="padding: 6px;">
                <div class="col-sm-1" style="text-align: center;">
                  <span [innerHTML]="getIconById(units.icon)" style="font-size: 32px;"></span>
                </div>
                <div class="col-sm-4" style="">
                  <Span>{{units.name}}</Span><Span *ngIf="actionType == 'special'"> ({{units.abilityValue}})</Span><br />
                  <Span>{{units.troopsPresent}}</Span>
                </div>
                <div class="col-sm-4" style="text-align: center; padding-top: 8px;">
                  <input [(ngModel)]="units.amountSend" type="number" id="amountSend" name="amountSend" min="1" max="{{(units.troopsPresent! - units.troopsUnavailable!)}}"
                         (ngModelChange)="AmountSendChanged(units)"
                         style="width: 90%;" />
                </div>
              </div>

              <div class="row" *ngIf="actionSubtype == 'settle'">
                <div class="col-sm-4" style="text-align:right"
                     [ngStyle]="{'color': (getSettleSum() < settlerSumNeeded) ? 'red' : 'black' }">
                  {{getSettleSum()}} / {{settlerSumNeeded}}
                </div>
                <div class="col-sm-4" style="text-align:right"
                     [ngStyle]="{'color': (getDifferentTroopsCount() > maxDifferentTroops) ? 'red' : 'black' }">
                  {{getDifferentTroopsCount()}} / {{maxDifferentTroops}}
                </div>
                <div class="col-sm-4" style="text-align:right">
                  <button class="btn btn-secondary"
                          (click)="checkSend()"
                          [disabled]="getSettleSum() < settlerSumNeeded || getDifferentTroopsCount() > maxDifferentTroops"
                          style="margin-top: -4px; height: 32px; padding: 0 8px 0 8px;" i18n>
                    BARRACKS_SEND_NEXT
                  </button>
                </div>
              </div>

              <div class="row" *ngIf="actionType == 'attack'">
                <div class="col-sm-4" style="text-align:right"
                     [ngStyle]="{'color': (getDifferentTroopsCount() > maxDifferentTroops) ? 'red' : 'black' }">
                  {{getDifferentTroopsCount()}} / {{maxDifferentTroops}}
                </div>
                <div class="col-sm-4" style="text-align:right">
                  <button class="btn btn-secondary"
                          (click)="checkSend()"
                          [disabled]="getDifferentTroopsCount() > maxDifferentTroops"
                          style="margin-top: -4px; height: 32px; padding: 0 8px 0 8px;" i18n>
                    BARRACKS_SEND_NEXT
                  </button>
                </div>
              </div>

            </div>
            <div class="row" *ngIf="isArmySelection == true">


              ARMY SELECTION - comming soon


            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-12" *ngIf="stepLauchActive == true">
        <div class="card" style="margin: 4px 0 2px 0;">
          <div class="card-header" style="padding: 2px 0 2px 12px;" i18n>
            BARRACK_SEND_LAUNCH
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col col-sm-4">
                <span i18n>SEND_UNIT_ACTION_TYPE</span>
                <span>: {{getTranslation('actionType', this.actionType + '-' + this.actionSubtype)}}</span>
              </div>
              <div class="col col-sm-4">
                <span>X:</span>
                <span>{{xInput}}</span>
              </div>
              <div class="col col-sm-4">
                <span>Y:</span>
                <span>{{yInput}}</span>
              </div>
            </div>
            <div class="row" style="margin-top: 12px;">
              <div class="col col-sm-4">
                <span i18n>SEND_UNIT_DISTANCE</span>
                <span>: {{distance}}</span>
              </div>
              <div class="col col-sm-4">
                <span i18n>SEND_UNIT_SPEED</span>
                <span>: {{speed}}</span>
              </div>
              <div class="col col-sm-4">
                <span i18n>SEND_UNIT_TRAVEL_TIME</span>
                <span>: {{travelTime}}</span>
              </div>
            </div>
            <div class="row" style="margin-top: 16px;">
              <div class="col-sm-12" style="padding-left: 30%;">
                <button class="btn btn-secondary"
                        (click)="send()"
                        style="margin-top: -4px; height: 32px; padding: 0 8px 0 8px;" i18n>
                  BARRACKS_SEND_LAUNCH
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
